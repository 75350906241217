import React, { useEffect, useState, useContext } from "react";
import InitialSetup from "~/pages/shared-modules/dashboard-graphs/components/initial-setup/Initial-setup";
import DashboardGraphs from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { ProductContext } from "~/shared/main-layout";
import settings from "~/services/settings.json";

const Home = () => {
  const dcpAxiosService = useDcpAxiosService();
  const [mainDashboards, setMainDashboards] = useState([]);
  const { product } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);

  const getMainDashboards = async () => {
    if (product != null && product != undefined) {
      try {
        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Dashboard,
          "Platform",
          {
            params: {
              idDashboard: 0,
              isMain: true,
              applicationClientId: product.idApplicationClient,
            },
          }
        );

        if (status === 200) {
          setMainDashboards(data);
        } else {
          console.error("Error loading main dashboards");
          setMainDashboards(null);
        }
      } catch {}
      setLoading(true);
    }
  };

  useEffect(() => {
    getMainDashboards();
  }, [product]);

  return (
    <>
      {loading ? (
        mainDashboards.status && mainDashboards.data != null ? (
          <div className="home">
            <DashboardGraphs previewMode={true} isMain={true} />
          </div>
        ) : (
          <InitialSetup />
        )
      ) : (
        <> </>
      )}
    </>
  );
};

export default Home;
