import React, { useEffect } from "react";
import InitialSetup from "~/pages/shared-modules/databases/initial-setup/initial-setup";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";

const InventoryStock = () => {
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.Inventory_Estoque);
  }, [loadDatabaseProductProcess]);

  return (
    <>
      {databaseProductProcessData !== null ? (
        <DatabaseList
          id={databaseProductProcessData?.idDatabase}
          hasDetails={databaseProductProcessData?.hasDetails}
          uriDetails={databaseProductProcessData?.uriDetails}
          showIcon={false}
        />
      ) : (
        <InitialSetup
          databaseProcessType={settings.DatabaseProcessType.Inventory_Estoque}
        />
      )}
    </>
  );
};

export default InventoryStock;
