import {
  DataTableFilter,
  HeatMapModel,
  InventoryCountModel,
  InventoryListModel,
  InventoryModel,
  InventoryPrintConnectionModel,
  InventoryUpdateStatusModel,
  LocationLastUpdate,
  OpenInventory,
  RackLocationsInfoModel,
} from "~/shared/interfaces";

import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import settings from "~/services/settings.json";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

/**
 * Retrieves the list of inventories according to filter
 * @param filter - Filter object to be used to return the list.
 * @returns A Promise that resolves to the inventory list.
 */
export async function listInventories(
  filter: DataTableFilter
): Promise<InventoryListModel> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.Inventory + "/list",
      filter,
      "Inventory"
    );

    if (status === 200 && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the list of inventories according to filter
 * @param filter - Filter object to be used to return the list.
 * @returns A Promise that resolves to the inventory list.
 */
export async function listOpen(): Promise<OpenInventory[]> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/open-inventories",
      "Inventory"
    );

    if (status === 200 && data.data && Array.isArray(data.data))
      return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the inventory details
 * @param inventoryId - The ID of the inventory.
 * @returns A Promise that resolves to the inventory details.
 */
export async function getInventory(
  inventoryId: number | string
): Promise<InventoryModel> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/" + inventoryId,
      "Inventory"
    );

    if (status === 200 && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteInventory(id: number): Promise<boolean> {
  try {
    const { data, status } = await axiosService().delete(
      settings.Urls.Rest.Inventory,
      "Inventory",
      { params: { id: id } }
    );

    if (status === 200 && data.data) return data.data;
    else return false;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves inventory locations based on the provided area ID and inventory ID.
 *
 * @param areaId - The ID of the area for which to retrieve inventory locations.
 * @param inventoryId - The ID of the inventory to filter locations by.
 * @returns A Promise that resolves to the inventory locations data if successful, or null if no data is found or an error occurs.
 */
export async function getInventoryLocations(
  areaId: number | string,
  inventoryId: number | string
): Promise<any> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Inventory}/inventory-locations`,
      "Inventory",
      { params: { areaId, inventoryId } }
    );
    if (data && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getAreaLocations(areaId: number | string): Promise<any> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Inventory}/area-locations`,
      "Inventory",
      { params: { areaId } }
    );
    console.log(data);

    if (data && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getReportCountSummary(inventoryId: number): Promise<any> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/count-summary",
      "Inventory",
      { params: { inventoryId: inventoryId } }
    );

    if (data && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getRackLocationsInfoModel(
  areaId: number
): Promise<RackLocationsInfoModel[]> {
  try {
    const { status, data } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/area-history",
      "Inventory",
      { params: { areaId: areaId } }
    );

    if (status === 200 && data.data) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}

export async function getRackLocationsPickingCount(
  areaId: number,
  startDate: Date,
  endDate: Date,
  showFilterColumn: boolean
): Promise<HeatMapModel> {
  try {
    let applicationClientId: string = propertyFrommStorage(
      "authentication",
      "applicationId"
    );

    const { status, data } = await axiosService().get(
      settings.Urls.Admin.ApplicationClient +
        "/get-product-code-from-application-client-id",
      "Auth",
      {
        params: {
          applicationClientId: applicationClientId,
        },
      }
    );
    if (status !== 200) {
      throw new Error("Falha ao obter o tipo de resposta.");
    }

    let st: number | undefined;
    let response: any;

    if (data.data === "drone-inventory") {
      const result = await axiosService().get(
        settings.Urls.Rest.Inventory + "/heat-map",
        "Inventory",
        {
          params: {
            areaId: areaId,
            startDate: startDate,
            endDate: endDate,
            showFilterColumn: showFilterColumn,
            applicationClientId: applicationClientId,
          },
        }
      );
      st = result.status;
      response = result.data;
    } else if (data.data === "stage-control") {
      const result = await axiosService().get(
        settings.Urls.Rest.StageControl + "/heat-map",
        "Stage-Control",
        {
          params: {
            areaId: areaId,
            startDate: startDate,
            endDate: endDate,
            showFilterColumn: showFilterColumn,
            applicationClientId: applicationClientId,
          },
        }
      );
      st = result.status;
      response = result.data;
    }

    if (st === 200 && response?.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getLocationsLastUpdate(
  areaGroupRackId: number
): Promise<LocationLastUpdate[]> {
  try {
    const { status, data } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/rack-locations-history",
      "Inventory",
      { params: { areaGroupRackId: areaGroupRackId } }
    );

    if (status === 200 && data.data) {
      data.data.forEach((location) => {
        if (location.lastUpdate)
          location.lastUpdate = new Date(location.lastUpdate);
      });
      return data.data;
    } else return [];
  } catch (error) {
    console.error(error);
  }
}

export async function GetLocationCount(
  locationId: number
): Promise<InventoryCountModel[]> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/location-count",
      "Inventory",
      { params: { inventoryCountLocation: locationId } }
    );

    if (status === 200 && data.data) {
      return data.data;
    } else return [];
  } catch (error) {
    console.error(error);
  }
}

export async function UpdateInventoryStatus(
  model: InventoryUpdateStatusModel
): Promise<InventoryModel> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.Inventory + "/update-status",
      model,
      "Inventory"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function UploadLocationImage(form: FormData): Promise<any> {
  try {
    console.log(form);

    const { data, status } = await axiosService().post(
      settings.Urls.Rest.Inventory + "/update-location-image",
      form,
      "Inventory",
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (status === 200 && data.data) {
      return data.data;
    } else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function DeleteUnprocessedImage(id: number): Promise<any> {
  try {
    const { data, status } = await axiosService().delete(
      settings.Urls.Rest.Inventory + "/unprocessed-image-delete",
      "Inventory",
      { params: { idInventoryImage: id } }
    );

    if (status === 200 && data.data) {
      return data.data;
    } else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function GetPrinters(): Promise<InventoryPrintConnectionModel[]> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/printers",
      "Inventory"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else return [];
  } catch (error) {
    console.error(error);
  }
}

export async function DeleteInventoryLocation(
  inventoryLocationId: number | string
): Promise<boolean> {
  try {
    const { data, status } = await axiosService().delete(
      settings.Urls.Rest.Inventory + "/inventory-location",
      "Inventory",
      { params: { inventoryLocationId: inventoryLocationId } }
    );

    if (status === 200 && data.data) {
      return data.data;
    } else return;
  } catch (error) {
    console.error(error);
  }
}

export async function loadInventoryDetails(idInventory: number): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/get-inventory-details",
      "Inventory",
      { params: { inventoryId: idInventory } }
    );

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
}

export async function updateInventoryDetails(
  inventoryDetails: any
): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.Inventory + "/update-inventory-details",
      inventoryDetails,
      "Inventory"
    );

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
}
