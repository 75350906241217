import { useCallback, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";

const UseDatabaseProductProcess = () => {
  const [databaseProductProcessData, setDatabaseProductProcessData] =
    useState(null);
  const [error, setError] = useState(null);
  const dcpAxiosService = useDcpAxiosService();

  const loadDatabaseProductProcess = useCallback(
    async (databaseProccessType) => {
      var databaseProductProcessTypeDataObj;
      try {
        const { data: databaseProductProcess, status } =
          await dcpAxiosService.get(
            `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
            "Platform",
            {
              params: {
                databaseProductProcessType: databaseProccessType,
              },
            }
          );

        databaseProductProcessTypeDataObj = {
          idDatabase: databaseProductProcess?.data.idDatabase,
          hasDetails:
            databaseProductProcess?.data.uriDetails === "" ? false : true,
          uriDetails: databaseProductProcess?.data.uriDetails,
          nameDatabase: databaseProductProcess?.data.nameDatabase,
          status: status,
        };
        setDatabaseProductProcessData(databaseProductProcessTypeDataObj);
      } catch (error) {
        setError(error);
        console.error(error);
      } finally {
        return databaseProductProcessTypeDataObj;
      }
    },
    []
  );

  return { databaseProductProcessData, error, loadDatabaseProductProcess };
};

export default UseDatabaseProductProcess;
