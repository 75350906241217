import "../scss/modal-update-order-status.scss";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import LanguageProvider from "~/shared/components/language-provider";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";
import { UpdateOrderStatus } from "~/services/api/B2b/boticario/monitor-process";
import { useToastContext } from "~/context/ToastContext";
import { Button } from "~/shared/components/dcp-button";

export const ModalUpdateOrderStatus = ({
  orderId = 0,
  orderNewStatusTemp,
  isOpen,
  onClose,
  onConfirm,
  modulesAdminDeliveryFlux,
}) => {
  const { showToast } = useToastContext();
  const [orderStatus, setOrderStatus] = useState(orderNewStatusTemp);
  const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);
  const allOrderStatusOptions = [
    {
      id: 1,
      label: "dcp.b2b.monitor.order.status.integration.inconsistency",
      value: OrderMonitorStatus.inconsistentIntegration,
    },
    {
      id: 2,
      label: "dcp.b2b.monitor.order.status.created",
      value: OrderMonitorStatus.createdOrder,
    },
    {
      id: 3,
      label: "dcp.b2b.monitor.order.status.reprocess-carrier-integration",
      value: OrderMonitorStatus.reprocessCarrierIntegration,
    },
    {
      id: 4,
      label: "dcp.b2b.monitor.order.status.reprocessing-report",
      value: OrderMonitorStatus.reprocessingReport,
    },
    {
      id: 5,
      label: "dcp.b2b.monitor.order.status.pending-packing",
      value: OrderMonitorStatus.pendingPacking,
    },
    {
      id: 6,
      label: "dcp.b2b.monitor.order.status.carrier-definition",
      value: OrderMonitorStatus.carrierDefinition,
    },
    {
      id: 7,
      label: "dcp.b2b.monitor.order.status.carrier-error",
      value: OrderMonitorStatus.carrierError,
    },
    {
      id: 8,
      label: "dcp.b2b.monitor.order.status.pending-integration-carrier",
      value: OrderMonitorStatus.pendingIntegrationCarrier,
    },
    {
      id: 9,
      label: "dcp.b2b.monitor.order.status.error-carrier-integration",
      value: OrderMonitorStatus.errorCarrierIntegration,
    },
    {
      id: 10,
      label: "dcp.b2b.monitor.order.status.pending-report",
      value: OrderMonitorStatus.pendingReport,
    },
    {
      id: 11,
      label: "dcp.b2b.monitor.order.status.error-report",
      value: OrderMonitorStatus.errorReport,
    },
    {
      id: 12,
      label: "dcp.b2b.monitor.order.status.pending-print",
      value: OrderMonitorStatus.pendingPrint,
    },
    {
      id: 13,
      label: "dcp.b2b.monitor.order.status.pendent-shipping",
      value: OrderMonitorStatus.pendingShipping,
    },
    {
      id: 14,
      label: "dcp.b2b.monitor.order.status.finished",
      value: OrderMonitorStatus.finished,
    },
  ];

  async function handleUpdateOrderStatus(newStatusValue) {
    try {
      const { data, status } = await UpdateOrderStatus(orderId, newStatusValue);
      if (data && status) {
        showToast({
          severity: "success",
          message: (
            <LanguageProvider
              id={
                "dcp.b2b.dcp.b2b.monitor.order.details.dialog.reprocess.order.process"
              }
            />
          ),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
      onConfirm();
    }
  }

  const getStatusObject = (statusValue) => {
    return (
      allOrderStatusOptions.find((status) => status.value === statusValue) ||
      null
    );
  };

  const getAvailableOptions = () => {
    if (modulesAdminDeliveryFlux) {
      return allOrderStatusOptions;
    }
    const allowedIds = [2, 4, 14];
    return allOrderStatusOptions.filter((status) =>
      allowedIds.includes(status.id)
    );
  };

  const handleStatusChange = (e) => {
    const newValue = e.value;
    setOrderStatus(newValue);
  };

  useEffect(() => {
    if (orderNewStatusTemp) {
      setOrderStatus(orderNewStatusTemp);
    }
    setStatusDropdownOptions(getAvailableOptions());
  }, [modulesAdminDeliveryFlux, orderNewStatusTemp, isOpen]);

  return (
    <>
      <Dialog
        header={
          <LanguageProvider
            id={"dcp.b2b.monitor.order.status.update.dialog.title"}
          />
        }
        visible={isOpen}
        className="modal-update-order-status"
        onHide={() => {
          onClose();
          setStatusDropdownOptions(null);
        }}
        footer={
          <div className="footer">
            <Button
              label={LanguageProvider({ id: "gen.cancel" })}
              className="p-button p-button-text p-button-plain"
              onClick={() => {
                onClose();
              }}
            />
            <Button
              label={LanguageProvider({ id: "gen.confirm" })}
              onClick={() => handleUpdateOrderStatus(orderStatus)}
            />
          </div>
        }
      >
        <div className="update-status-dialog-container">
          <Dropdown
            className="status-dropdown"
            value={orderStatus}
            options={statusDropdownOptions}
            optionLabel="label"
            optionValue="value"
            itemTemplate={(option) => <LanguageProvider id={option.label} />}
            valueTemplate={() => {
              const currentStatus = getStatusObject(orderStatus);
              return (
                currentStatus && <LanguageProvider id={currentStatus.label} />
              );
            }}
            onChange={handleStatusChange}
          />
        </div>
      </Dialog>
    </>
  );
};
