import { colors } from "./colors";

const ThemeProvider = (theme) => {
  const colorPallet = {
    blue100: colors.blue100,
    blue400: colors.blue400,

    blueGray25: colors.blueGray25,
    blueGray50: colors.blueGray50,
    blueGray100: colors.blueGray100,
    blueGray200: colors.blueGray200,
    blueGray300: colors.blueGray300,
    blueGray400: colors.blueGray400,
    blueGray500: colors.blueGray500,
    blueGray600: colors.blueGray600,
    blueGray700: colors.blueGray700,
    blueGray800: colors.blueGray800,
    blueGray900: colors.blueGray900,

    brightGreen50: colors.brightGreen50,
    brightGreen600: colors.brightGreen600,

    dark50: colors.dark50,
    dark100: colors.dark100,
    dark200: colors.dark200,
    dark300: colors.dark300,
    dark400: colors.dark400,
    dark500: colors.dark500,
    dark600: colors.dark600,
    dark700: colors.dark700,
    dark800: colors.dark800,
    dark900: colors.dark900,

    error50: colors.error50,

    indigo25: colors.indigo25,
    indigo50: colors.indigo50,
    indigo100: colors.indigo100,
    indigo200: colors.indigo200,
    indigo300: colors.indigo300,
    indigo400: colors.indigo400,
    indigo500: colors.indigo500,
    indigo600: colors.indigo600,
    indigo700: colors.indigo700,
    indigo800: colors.indigo800,
    indigo900: colors.indigo900,

    primary25: colors.primary25,
    primary50: colors.primary50,
    primary100: colors.primary100,
    primary200: colors.primary200,
    primary300: colors.primary300,
    primary400: colors.primary400,
    primary500: colors.primary500,
    primary600: colors.primary600,
    primary700: colors.primary700,
    primary800: colors.primary800,
    primary900: colors.primary900,

    white: colors.white,
    gray05: colors.gray05,
    gray25: colors.gray25,
    gray50: colors.gray50,
    gray60: colors.gray60,
    gray100: colors.gray100,
    gray200: colors.gray200,
    gray300: colors.gray300,
    gray350: colors.gray350,
    gray400: colors.gray400,
    gray500: colors.gray500,
    gray600: colors.gray600,
    gray700: colors.gray700,
    gray800: colors.gray800,
    gray900: colors.gray900,

    green50: colors.green50,
    green100: colors.green100,
    green500: colors.green500,
    green300: colors.green300,
    green600: colors.green600,
    green700: colors.green700,
    green800: colors.green800,

    pink50: colors.pink50,
    pink600: colors.pink600,

    pinkBlue50: colors.pinkBlue50,
    pinkBlue100: colors.pinkBlue100,
    pinkBlue600: colors.pinkBlue600,

    purple25: colors.purple25,
    purple50: colors.purple50,
    purple100: colors.purple100,
    purple200: colors.purple200,
    purple300: colors.purple300,
    purple400: colors.purple400,
    purple500: colors.purple500,
    purple600: colors.purple600,
    purple700: colors.purple700,
    purple800: colors.purple800,
    purple900: colors.purple900,

    rose100: colors.ros100,
    rose500: colors.ros500,
    rose600: colors.ros600,

    orange25: colors.orange25,
    orange50: colors.orange50,
    orange100: colors.orange100,
    orange200: colors.orange200,
    orange300: colors.orange300,
    orange400: colors.orange400,
    orange500: colors.orange500,
    orange600: colors.orange600,
    orange700: colors.orange700,
    orange800: colors.orange800,
    orange900: colors.orange900,

    sunset100: colors.sunset100,
    sunset500: colors.sunset500,
    sunset600: colors.sunset600,

    yellow50: colors.yellow50,
    yellow100: colors.yellow100,
    yellow400: colors.yellow400,
    yellow600: colors.yellow600,
    yellow700: colors.yellow700,
  };

  const lightTheme = {
    systemBackground: colorPallet.blueGray50,
    systemForeground: colorPallet.white,
    systemContentBorderColor: colorPallet.white,

    // Login
    sideFormBackgroundColor: colorPallet.gray05,
    formBackgroundColor: colorPallet.white,
    loginBackgroundColor: colorPallet.white,

    primary: colorPallet.primary500,
    primaryHover: colorPallet.primary700,

    textPrimary: colorPallet.gray700,
    textSecondary: colorPallet.gray600,
    textTertiary: colorPallet.blueGray400,
    textAlternative: colorPallet.dark900,

    // Navbar
    iconNavBarDefaultColor: colorPallet.blueGray400,
    iconNavBarActiveColor: colorPallet.gray200,
    iconHotKeyBg: colorPallet.white,
    iconHotKeyLabelColor: colorPallet.dark700,
    iconHotKeyBorderColor: colorPallet.gray400,

    // SideMenu
    sideMenuLabelColor: colorPallet.blueGray500,

    //Painel
    painelBorderColor: colorPallet.gray300,
    painelBgColorHover: colorPallet.blueGray25,
    painelLabelColor: colorPallet.blueGray500,

    //Button
    primaryButtonBackgroundColor: colorPallet.primary25,
    primaryButtonFontColor: colorPallet.white,
    primaryButtonFontColorHover: colorPallet.white,
    secondaryButtonFontColor: colorPallet.primary500,
    dangerButtonBackgroundColor: colorPallet.error50,

    //Input
    inputAddOnBackgroundColor: colorPallet.blueGray100,
    inputAddOnTextColor: colorPallet.gray500,
    inputBackgroundColorWhiteOnLightTheme: colorPallet.white,
    inputBackgroundColor: colorPallet.indigo50,
    inputBackgroundColorFocus: colorPallet.white,
    inputBackgroundColorHover: colorPallet.white,
    inputBorderColor: colorPallet.indigo25,
    inputBorderColorFocus: colorPallet.primary500,
    inputBorderColorHover: colorPallet.primary500,
    inputFontColor: "#292F4C",
    inputFontColorHover: "#292F4C",
    inputFontColorFocus: "#292F4C",
    inputPlaceholderColor: colorPallet.blueGray300,

    //Font & Size
    defaultFontSize: "14px",
    defaultFontWeight: "500",

    //Icons
    iconDefaultSize: "20px",
    iconDefaultColor: colorPallet.blueGray500,
    iconActiveColor: colorPallet.gray300,

    //Shadows
    defaultBoxShadow: "0px -2px 20px #4662ac19",
    sideMenuSizeIconShadow: "0px 1px 3px #10182819, 0px 1px 2px #101828f",

    //Border
    defaultBorder: `1px solid ${colorPallet.gray100}`,
    accordeonBorderColor: colorPallet.gray200,
    printIdentificationTemplateBackgroundColor: colorPallet.gray300,

    // Inventory Area Count
    inventoryFloorAreaBackgroundColor: colorPallet.gray50,
    inventoryAreaBackgroundColor: colorPallet.gray25,
    inventoryAreaColumnHeaderBackgroundColor: colorPallet.blueGray50,

    // Rack (Layout)
    rackExampleBackgroundColor: colorPallet.gray50,
    rackShelvesLabelColor: colorPallet.primary600,
    rackStructureColor: colorPallet.gray300,
    rackInternStructureColor: colorPallet.gray300,
    rackStructureDetailsColor: colorPallet.gray400,
    rackInternSideMenuButtonBackgroundColor: colorPallet.gray60,

    // Dashboards
    dashboardMainSeparatorColor: colorPallet.blueGray100,
    dashboardEmptyCardBorderColor: colorPallet.gray300,
    dashboardStrokesColor: colorPallet.gray100,
    dashboardDataColor: colorPallet.gray500,

    // Dashboard Icons
    dashboardWMSIconColor: colorPallet.rose500,
    dashboardMESIconColor: colorPallet.yellow400,
    dashboardSequenceIconColor: colorPallet.green500,
    dashboardWarehouseIconColor: colorPallet.blue400,
    dashboardAMCIconColor: colorPallet.indigo400,

    dashboardProductUserIconBackgroundColor: colorPallet.primary50,
    dashboardProductUserIconLabelColor: colorPallet.primary600,

    // Dashboard Card
    dashboardInventoryCardBackground: colorPallet.orange100,
    dashboardWmsCardBackground: colorPallet.rose100,
    dashboardMesCardBackground: colorPallet.yellow100,
    dashboardSequenceCardBackground: colorPallet.green100,
    dashboardWarehouseCardBackground: colorPallet.blue100,
    dashboardAMCCardBackground: colorPallet.indigo100,
    dashboardWmsCoreCevaCardBackground: colorPallet.rose100,
    dashboardPrintCardBackground: colorPallet.yellow100,

    dashboardProductBackgroundColor: colorPallet.indigo50,
    dashboardBlankCardBackgroundColor: colorPallet.blueGray100,

    // Side menu
    dashboardSideMenuIconBackgroundColor: colorPallet.orange100,
    databaseSideMenuIconBackgroundColor: colorPallet.indigo50,

    // Datalake Modal
    databaseCardBackgroundColor: colorPallet.indigo50,
    databaseCardIconColor: colorPallet.primary400,

    //Picking
    itemListBackground: colorPallet.gray25,

    // Report
    reportBackIconColor: colorPallet.primary600,
    reportToggleIconColor: colorPallet.gray500,
    reportColumnsEditBackgroundColor: colorPallet.gray25,
    reportColumnHeaderBackgroundColor: colorPallet.blueGray50,
    reportEditBodyBackgroundColor: colorPallet.white,
    reportColumnIconColor: colorPallet.blueGray400,
    reportDataSourceColumnBackgroundColorOnHover: colorPallet.indigo25,
    reportDataSourceColumnBorderColorOnHover: colorPallet.indigo400,
    reportDataSourceIconColor: colorPallet.blueGray400,
    reportAccessTagBackgroundColor: colorPallet.blueGray200,

    // Database
    databaseFieldsBackgroundColor: colorPallet.blueGray50,
    databaseFieldsBackgroundColorOnHover: colorPallet.indigo100,
    databaseFieldsBorderColorOnHover: colorPallet.indigo400,
    databaseUploadSpaceColorOnHover: colorPallet.indigo300,

    //Table
    tableHeaderBackground: colorPallet.gray50,
    tableHeaderBackgroundHover: colorPallet.gray100,
    tableHeaderTitle: colorPallet.primary600,
    tableBodyBackground: colorPallet.white,
    tableAvatarLabelBackground: colorPallet.primary50,
    tableAvatarLabelColor: colorPallet.primary300,

    tableStatusIconActiveColor: colorPallet.green300,
    tableStatusLabelActive: colorPallet.green600,
    tableStatusLabelBackgroundActive: colorPallet.green50,
    tableStatusIconInactiveColor: colorPallet.blueGray300,
    tableStatusLabelInactive: colorPallet.blueGray500,
    tableStatusLabelBackgroundInactive: colorPallet.blueGray50,

    tableActionButtonIconColor: "#667085",
    tableActionButtonBackgroundColor: "transparent",
    tableActionButtonHoverIconColor: colors.primary500,
    tableActionButtonHoverBackgroundColor: "#F8F9FC",
    tableRowHoverBackgroundColor: "unset",
    tableTrBorderColor: colorPallet.gray200,

    tableProductWmsBgColor: colorPallet.orange50,
    tableProductPlatformBgColor: colorPallet.pinkBlue100,
    tableProductSequenceBgColor: colorPallet.brightGreen50,
    tableProductInventoryBgColor: colorPallet.pink50,
    tableProductMesBgColor: colorPallet.pinkBlue50,
    tableProductWarehouseBgColor: colorPallet.orange50,
    tableProductAreaMovementControlBgColor: colorPallet.pink50,

    tableProductWmsLabelColor: colorPallet.orange600,
    tableProductPlatformLabelColor: colorPallet.pinkBlue600,
    tableProductSequenceLabelColor: colorPallet.brightGreen600,
    tableProductInventoryLabelColor: colorPallet.pink600,
    tableProductMesLabelColor: colorPallet.pinkBlue600,
    tableProductWarehouseLabelColor: colorPallet.orange600,
    tableProductAreaMovementControlLabelColor: colorPallet.pink600,
    tableProductCountBg: colorPallet.gray100,
    tableProductCountLabel: colorPallet.gray700,

    tableIconColorCountExists: colorPallet.gray700,
    tableIconColorCountDoesNotExists: colorPallet.gray300,
    tableIconColor: colorPallet.gray500,
    tableIconHoverColor: colorPallet.dark50,

    /**Misc colors */
    shadowBlue: "#8189A3",
    primary50: colorPallet.primary50,
    gray100: colorPallet.gray100,
    gray200: colorPallet.gray200,
    gray400: colorPallet.gray400,
    gray500: colorPallet.gray500,
    gray900: colorPallet.gray900,
    blueGray50: "#EAECF5",
    blueGray100: colorPallet.gray100,
    blueGray200: "#D5D9EB",
    blueGray300: colorPallet.blueGray300,
    ...colorPallet,
  };

  const darkTheme = {
    systemBackground: colorPallet.dark700,
    systemForeground: colorPallet.dark800,
    loginBackgroundColor: colorPallet.dark700,

    // Login
    sideFormBackgroundColor: colorPallet.dark500,
    formBackgroundColor: colorPallet.dark700,

    // Navbar
    iconNavBarDefaultColor: colorPallet.gray400,
    iconNavBarActiveColor: colorPallet.dark600,
    iconHotKeyBg: colorPallet.dark500,
    iconHotKeyLabelColor: colorPallet.gray400,
    iconHotKeyBorderColor: colorPallet.dark700,

    // SideMenu
    sideMenuLabelColor: colorPallet.gray400,

    // painel
    painelBorderColor: colorPallet.dark800,
    painelBgColorHover: colorPallet.dark600,
    painelLabelColor: colorPallet.blueGray500,

    primary: colorPallet.primary500,
    primaryHover: colorPallet.primary700,

    textPrimary: colorPallet.gray50,
    textSecondary: colorPallet.dark50,
    textTertiary: colorPallet.gray400,
    textAlternative: colorPallet.white,

    //Button
    primaryButtonBackgroundColor: colorPallet.dark700,
    dangerButtonBackgroundColor: colorPallet.dark700,

    // Input
    inputAddOnBackgroundColor: colorPallet.blueGray100,
    inputAddOnTextColor: colorPallet.gray500,
    inputBackgroundColorWhiteOnLightTheme: colorPallet.dark600,
    inputBackgroundColor: colorPallet.dark600,
    inputBackgroundColorFocus: colorPallet.dark600,
    inputBackgroundColorHover: colorPallet.dark600,
    inputBorderColor: colorPallet.dark600,

    // Border
    printIdentificationTemplateBackgroundColor: colorPallet.gray500,
    defaultBorder: `1px solid ${colorPallet.gray800}`,
    accordeonBorderColor: colorPallet.gray800,

    // Inventory Area Count
    inventoryFloorAreaBackgroundColor: colorPallet.dark600,
    inventoryAreaBackgroundColor: colorPallet.dark900,
    inventoryAreaColumnHeaderBackgroundColor: colorPallet.dark500,

    // Rack (Layout)
    rackExampleBackgroundColor: colorPallet.dark600,
    rackShelvesLabelColor: colorPallet.primary200,
    rackStructureColor: colorPallet.dark700,
    rackInternStructureColor: colorPallet.primary50,
    rackStructureDetailsColor: colorPallet.primary200,
    rackInternSideMenuButtonBackgroundColor: colorPallet.gray500,

    // Dashboards
    dashboardMainSeparatorColor: colorPallet.dark500,
    dashboardEmptyCardBorderColor: colorPallet.dark100,
    dashboardStrokesColor: colorPallet.dark400,
    dashboardDataColor: colorPallet.dark50,

    // Dashboard Icons
    dashboardWMSIconColor: colorPallet.rose500,
    dashboardMESIconColor: colorPallet.yellow400,
    dashboardSequenceIconColor: colorPallet.green500,
    dashboardWarehouseIconColor: colorPallet.indigo400,
    dashboardAMCIconColor: colorPallet.sunset500,

    // Dashboard Card
    dashboardInventoryCardBackground: colorPallet.orange500,
    dashboardWmsCardBackground: colorPallet.rose500,
    dashboardMesCardBackground: colorPallet.yellow600,
    dashboardSequenceCardBackground: colorPallet.green500,
    dashboardWarehouseCardBackground: colorPallet.indigo500,
    dashboardAMCCardBackground: colorPallet.sunset500,
    dashboardProductUserIconBackgroundColor: colorPallet.dark600,
    dashboardProductUserIconLabelColor: colorPallet.primary200,
    dashboardWmsCoreCevaCardBackground: colorPallet.rose600,
    dashboardPrintCardBackground: colorPallet.yellow600,

    dashboardProductBackgroundColor: colorPallet.blueGray700,
    dashboardBlankCardBackgroundColor: colorPallet.dark500,
    datalakeCardBackgroundColor: colorPallet.dark600,
    datalakeCardIconColor: colorPallet.dark800,

    // Side menu
    dashboardSideMenuIconBackgroundColor: colorPallet.orange800,
    databaseSideMenuIconBackgroundColor: colorPallet.dark500,

    // Datalake modal
    databaseCardBackgroundColor: colorPallet.indigo900,
    databaseCardIconColor: colorPallet.primary300,

    //Picking
    itemListBackground: colorPallet.dark700,

    // Report
    reportBackIconColor: colorPallet.primary300,
    reportToggleIconColor: colorPallet.gray50,
    reportColumnsEditBackgroundColor: colorPallet.dark900,
    reportColumnHeaderBackgroundColor: colorPallet.dark500,
    reportEditBodyBackgroundColor: colorPallet.dark800,
    reportColumnIconColor: colorPallet.blueGray300,
    reportDataSourceColumnBackgroundColorOnHover: colorPallet.indigo900,
    reportDataSourceColumnBorderColorOnHover: colorPallet.indigo400,
    reportDataSourceIconColor: colorPallet.blueGray300,
    reportAccessTagBackgroundColor: colorPallet.blueGray600,

    // Database
    databaseFieldsBackgroundColor: colorPallet.dark600,
    databaseFieldsBackgroundColorOnHover: colorPallet.indigo900,
    databaseFieldsBorderColorOnHover: colorPallet.indigo400,
    databaseUploadSpaceColorOnHover: colorPallet.indigo400,

    //Table
    tableHeaderBackground: colorPallet.dark600,
    tableHeaderBackgroundHover: colorPallet.dark700,
    tableHeaderTitle: colorPallet.primary300,
    tableAvatarLabelBackground: colorPallet.dark500,
    tableAvatarLabel: colorPallet.primary300,
    tableAvatarLabelInactive: colorPallet.gray100,
    tableAvatarLabelBackgroundInactive: colorPallet.gray400,
    tableBodyBackground: colorPallet.dark800,

    tableStatusIconActiveColor: colorPallet.green300,
    tableStatusLabelActive: colorPallet.white,
    tableStatusLabelBackgroundActive: colorPallet.green800,
    tableStatusIconInactiveColor: colorPallet.blueGray200,
    tableStatusLabelInactive: colorPallet.blueGray200,
    tableStatusLabelBackgroundInactive: colorPallet.blueGray800,

    tableActionButtonIconColor: "#2c2f35",
    tableActionButtonBackgroundColor: "transparent",
    tableActionButtonHoverIconColor: colors.primary500,
    tableActionButtonHoverBackgroundColor: "#F8F9FC",
    tableRowHoverBackgroundColor: "unset",
    tableTrBorderColor: colorPallet.dark500,

    tableProductWmsBgColor: colorPallet.orange600,
    tableProductPlatformBgColor: colorPallet.pinkBlue600,
    tableProductSequenceBgColor: colorPallet.brightGreen600,
    tableProductInventoryBgColor: colorPallet.yellow700,
    tableProductMesBgColor: colorPallet.pink100,
    tableProductWarehouseBgColor: colorPallet.orange700,
    tableProductAreaMovementControlBgColor: colorPallet.pinkBlue600,

    tableProductWmsLabelColor: colorPallet.white,
    tableProductPlatformLabelColor: colorPallet.white,
    tableProductSequenceLabelColor: colorPallet.white,
    tableProductInventoryLabelColor: colorPallet.white,
    tableProductMesLabelColor: colorPallet.white,
    tableProductWarehouseLabelColor: colorPallet.white,
    tableProductAreaMovementControlLabelColor: colorPallet.white,
    tableProductCountBg: colorPallet.dark600,
    tableProductCountLabel: colorPallet.white,

    tableIconColorCountExists: colorPallet.gray300,
    tableIconColorCountDoesNotExists: colorPallet.gray700,
    tableIconColor: colorPallet.white,
    tableIconHoverColor: colorPallet.gray500,

    //Icons
    iconDefaultSize: "20px",
    iconDefaultColor: colorPallet.blueGray500,
    iconActiveColor: colorPallet.dark600,
    ...colorPallet,
  };

  switch (theme) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    default:
      return lightTheme;
  }
};

export default ThemeProvider;
