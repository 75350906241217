import "./scss/print.scss";
import LanguageProvider from "~/shared/components/language-provider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import { Button } from "~/shared/components/dcp-button";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { useToastContext } from "~/context/ToastContext";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { classNames } from "primereact/utils";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import axios from "axios";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { listTemplates } from "~/services/api/platform/template";
import Icon from "~/shared/components/icons";
import { ThemeContext, UserContext } from "~/app";
import { getDatabaseFields, login, saveDatabaseItem } from "~/services/api";
import {
  GetDevices,
  GetPrinters,
  GetProviders,
  GetZplSerialPrintCode,
} from "~/services/api/print/label";
import {
  DeviceConnectionModel,
  LabelPrinterConnectionModel,
  ProviderConnectionModel,
} from "~/shared/interfaces";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";

const PrintAction = () => {
  const dcpAxiosService = useDcpAxiosService();
  const { currentLanguage } = useLanguageContext();
  const currentTheme = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );
  const { user } = useContext(UserContext);

  const [printers, setPrinters] = useState<LabelPrinterConnectionModel[]>([]);
  const [providers, setProviders] = useState<ProviderConnectionModel[]>([]);
  const [devices, setDevices] = useState<DeviceConnectionModel[]>([]);
  const [loadingDropdowns, setLoadingDropdowns] = useState(true);

  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [displayClearDataDialog, setDisplayClearDataDialog] = useState(false);
  const [urlPrintValue, setUrlPrintValue] = useState("");

  const [admUsername, setAdmUsername] = useState("");
  const [admPassword, setAdmPassword] = useState("");

  const [isFormFilled, setIsFormFilled] = useState(true);
  const [loadingSavePrintForm, setLoadingSavePrintForm] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const reprintAuthorized = useRef(false);
  const serialInputRef = useRef(null);

  const printsPlaceholder: string = LanguageProviderWithoutContext({
    id: "gen.message.printer.select.dropdown",
    currentLanguage,
  });
  const templatesPlaceholder: string = LanguageProviderWithoutContext({
    id: "gen.message.template.select.dropdown",
    currentLanguage,
  });
  const serialNumberPlaceholder: string = LanguageProviderWithoutContext({
    id: "gen.message.serial.number.select.dropdown",
    currentLanguage,
  });
  const devicesPlaceholder: string = LanguageProviderWithoutContext({
    id: "gen.message.devices.select.dropdown",
    currentLanguage,
  });
  const operatorPlaceholder: string = LanguageProviderWithoutContext({
    id: "gen.message.operator.select.dropdown",
    currentLanguage,
  });

  const inputRefs = useRef([]);

  interface FormValues {
    printer: LabelPrinterConnectionModel;
    device: DeviceConnectionModel;
    operator: ProviderConnectionModel;
    template: {
      id: string;
      name: string;
    };
    reprint: boolean;
    serial: string;
  }

  const validatePrintFieldsForm = (values: FormValues) => {
    const errors = {} as {
      printer?: string;
      template?: string;
      serial?: string;
      devices?: string;
      operator?: string;
    };
    if (values.printer === null) {
      errors.printer = LanguageProviderWithoutContext({
        id: "gen.message.print.document.prints.required",
        currentLanguage,
      });
    }
    if (values.template.id === "" || values.template.id === null) {
      errors.template = LanguageProviderWithoutContext({
        id: "gen.message.print.template.required",
        currentLanguage,
      });
    }
    if (values.serial === "") {
      errors.serial = LanguageProviderWithoutContext({
        id: "gen.message.serial.number.required",
        currentLanguage,
      });
    }
    if (values.device.code === "") {
      errors.devices = LanguageProviderWithoutContext({
        id: "gen.message.device.required",
        currentLanguage,
      });
    }
    if (values.operator.code === "") {
      errors.operator = LanguageProviderWithoutContext({
        id: "gen.message.operator.required",
        currentLanguage,
      });
    }
    return errors;
  };

  const loadPrintDataFields = async (dabataseId) => {
    try {
      const databaseFieldsResponse = await getDatabaseFields(dabataseId);
      if (Array.isArray(databaseFieldsResponse)) {
        let fields = {};
        databaseFieldsResponse.forEach((field) => {
          fields[field.keyName] = "";
        });
        const updatedObject = { ...fields };
        for (const key in fields) {
          if (Object.hasOwnProperty.call(fields, key)) {
            let field = key.split("_")[1];
            if (field === "dispositivo") {
              field = "devices";
            }
            if (field === "operadora") {
              field = "operator";
            }
            if (formikPrinter.values[field] !== undefined) {
              if (formikPrinter.values[field].id) {
                updatedObject[key] =
                  formikPrinter.values[field].label.toString();
              } else {
                updatedObject[key] = formikPrinter.values[field];
              }
            }
          }
        }
        return updatedObject;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formikPrinter = useFormik({
    initialValues: {
      printer: {
        name: "",
        code: "",
        ip: "",
        port: "",
      },
      reprint: false,
      template: {
        id: "",
        name: "",
      },
      device: {
        code: "",
      },
      operator: {
        code: "",
        color: "",
      },
      serial: "",
    },
    enableReinitialize: true,
    validate: validatePrintFieldsForm,
    onSubmit: async (values) => {
      console.log(values);

      // submit
      if (loadingSavePrintForm) return;
      try {
        setLoadingSavePrintForm(true);

        // Request print
        var zplPrintCode = await GetZplSerialPrintCode({
          templateId: Number(values?.template?.id),
          serial: values.serial,
          provider: values.operator.code,
          device: values.device.code,
          reprint: reprintAuthorized.current,
        });

        if (
          zplPrintCode.message === "The serial number was already printed." &&
          !reprintAuthorized.current
        ) {
          setLoginDialogOpen(true);
          return;
        }

        reprintAuthorized.current = false;

        if (zplPrintCode.data != null) {
          if (zplPrintCode.data.length > 0) {
            const documentZplSerial = [
              {
                type: "txt",
                encoded: true,
                payload: zplPrintCode.data,
              },
            ];

            const printData = {
              printer: {
                ...values.printer,
                port: values.printer.port.toString(),
              },
              documents: documentZplSerial,
            };

            // Send to local printer api
            var printerResponse = null;
            try {
              var cleanAxios = axios.create();
              printerResponse = await cleanAxios.post(
                urlPrintValue + "/api/printer/print",
                printData
              );
            } catch {
              showToast({
                severity: "error",
                message: LanguageProviderWithoutContext({
                  id: "gen.message.print.printer.connection.failed",
                  currentLanguage,
                }),
              });
            }

            if (printerResponse.status === 200) {
              showToast({
                severity: "success",
                message: LanguageProviderWithoutContext({
                  id: "gen.message.print.document.saved",
                  currentLanguage,
                }),
              });
            }
          } else {
            showToast({
              severity: "error",
              message: LanguageProviderWithoutContext({
                id: "gen.message.print.document.serial.error",
                currentLanguage,
              }),
            });
          }
        } else {
          showToast({
            severity: "error",
            message: LanguageProviderWithoutContext({
              id: "gen.message.print.document.serial.error",
              currentLanguage,
            }),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSavePrintForm(false);
      }

      values.serial = "";
    },
  });

  async function loginAdm() {
    setLoginLoading(true);
    const userModel = {
      userName: `${user.domain}/${admUsername}`,
      password: admPassword,
      clientType: "web",
      grantType: "password",
      refreshToken: null,
      application: 1,
      externalAppId: "",
    };

    const response = await login(userModel);

    if (response === null) {
      showToast({
        severity: "error",
        message: <LanguageProvider id="login.failure" />,
      });
      clearForm();
      return;
    }

    var hasRole = response.userClienteRoles
      .filter((role) => role.applicationId === 15)
      .filter(
        (role) => role.roleName === "lider" || role.roleName === "administrator"
      );

    if (hasRole.length === 0) {
      showToast({
        severity: "error",
        message: <LanguageProvider id="product.permission.no.access" />,
      });
      clearForm();
      return;
    }

    reprintAuthorized.current = true;
    await formikPrinter.submitForm();
    clearForm();

    showToast({
      severity: "success",
      message: <LanguageProvider id={"label.reprint.success"} />,
    });
    try {
    } catch (error) {
      console.error(error);
      clearForm();
      showToast({
        severity: "error",
        message: <LanguageProvider id={"login.error"} />,
      });
    }
    setLoginLoading(false);
    clearForm();
  }

  const IsFormValid = (name) => {
    const fieldNameParts = name.split(".");
    const fieldError = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.errors
    );
    const fieldTouched = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.touched
    );
    return !!(fieldError && fieldTouched);
  };
  const GetFormError = ({ name }) => {
    const fieldError = name
      .split(".")
      .reduce((acc, key) => acc?.[key], formikPrinter.errors);

    const fieldTouched = name
      .split(".")
      .reduce((acc, key) => acc?.[key], formikPrinter.touched);

    return fieldError && fieldTouched ? (
      <small className="p-error">{fieldError}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleKeyDown = async (e, index) => {
    if (loginDialogOpen) return;

    if ((e.key === "Tab" || e.key === "Enter") && !e.shiftKey) {
      e.preventDefault();
      inputRefs.current[0]?.focus();
    }

    if (
      Object.keys(formikPrinter.errors).length === 0 &&
      (e.key === "Tab" || e.key === "Enter")
    ) {
      await formikPrinter.handleSubmit();
    }
  };

  const clearForm = () => {
    formikPrinter.setValues(
      {
        printer: formikPrinter.values.printer,
        template: formikPrinter.values.template,
        device: formikPrinter.values.device,
        operator: formikPrinter.values.operator,
        serial: "",
        reprint: false,
      },
      false
    );
    setLoginLoading(false);
    setLoginDialogOpen(false);
    setAdmPassword("");
    setAdmUsername("");
    if (serialInputRef.current) {
      serialInputRef.current.focus();
    }
  };

  const handleClearData = () => {
    if (formikPrinter.values) {
      clearForm();
    }
    setDisplayClearDataDialog(false);
    setIsFormFilled(false);
  };

  const handleTemplateChange = (e) => {
    if (e.value === null) return;

    const selectedOptionName = templatesOptions.find(
      (item) => item.id === e.value
    );
    formikPrinter.setFieldValue("template", {
      id: selectedOptionName.id,
      label: selectedOptionName.name,
    });
  };

  function getSettingValueByName(data, groupName, subgroupName, propertyName) {
    const value = data
      .find((group) => group.name === groupName)
      ?.subgroups.find((subgroup) => subgroup.name === subgroupName)
      ?.settings.find((setting) => setting.propertyName === propertyName).value;
    return value;
  }

  useEffect(() => {
    async function getDropdownOptions() {
      try {
        setLoadingDropdowns(true);
        setPrinters(await GetPrinters());
        setProviders(await GetProviders());
        setDevices(await GetDevices());
        setLoadingDropdowns(false);
      } catch (error) {
        console.error(error);
        setLoadingDropdowns(false);
      }
    }
    async function loadTemplateDropdownFields() {
      try {
        const response = await listTemplates();

        const templateOptions = response.data.data.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        if (response?.data?.data?.length > 0) {
          setTemplatesOptions(templateOptions);
        } else {
          showToast({
            severity: "error",
            message: LanguageProviderWithoutContext({
              id: response.data.message,
              currentLanguage,
            }),
          });
        }
      } catch (error) {
        console.error(error);
        showToast({
          severity: "error",
          message: <LanguageProvider id="unhandled.error" />,
        });
      }
    }
    async function getPrinterUrl() {
      const settingsResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Settings + "/list-settings",
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
          },
        }
      );
      const driverPrintUrlValueInSettings = getSettingValueByName(
        settingsResponse.data.data,
        "print",
        "product-print-fields-options",
        "fields-print-driver"
      );
      setUrlPrintValue(driverPrintUrlValueInSettings);
    }
    getDropdownOptions();
    loadTemplateDropdownFields();
    getPrinterUrl();
  }, []);

  return (
    <>
      <div
        className={`print-action-document-main-container ${
          fullScreen ? "fullscreen" : ""
        }`}
      >
        <div className="backdrop">
          <div className="print-header-wrapper">
            <div className="container">
              <div className="header">
                <div className="title-wrapper">
                  <span className="header-message">
                    <span className="title">
                      <LanguageProvider id={"product.print.menu.print"} />
                    </span>
                  </span>
                </div>
                <div
                  className="icon"
                  onClick={() => setFullScreen(!fullScreen)}
                >
                  <Icon
                    icon={fullScreen ? "minimize-01" : "maximize-01"}
                    size={18}
                    color={currentTheme.iconDefaultColor}
                  />
                </div>
              </div>
              <div className="container-tables">
                <div className="tableLeft">
                  <div className="entry-settings-side-fields">
                    <Form className="entry-settings-form">
                      <FormRow>
                        <InputContainer
                          className="print-registers-dropdown"
                          label="Impressoras"
                        >
                          <Dropdown
                            id="printer"
                            name="printer"
                            className={classNames({
                              "p-invalid": IsFormValid("printer"),
                            })}
                            placeholder={printsPlaceholder}
                            options={printers}
                            value={formikPrinter.values.printer}
                            optionLabel="name"
                            onChange={(e) => {
                              formikPrinter.setFieldValue("printer", e.value);
                            }}
                            loading={loadingDropdowns}
                          />
                          <GetFormError name={"printer"} />
                        </InputContainer>
                      </FormRow>
                      <FormRow>
                        <InputContainer
                          className="print-registers-dropdown"
                          label="Template"
                        >
                          <Dropdown
                            id="template"
                            name="template"
                            className={classNames({
                              "p-invalid": IsFormValid("template"),
                            })}
                            placeholder={templatesPlaceholder}
                            options={templatesOptions}
                            value={formikPrinter.values.template.id}
                            optionLabel="name"
                            optionValue="id"
                            onChange={(e) => {
                              handleTemplateChange(e);
                            }}
                            loading={loadingDropdowns}
                          />
                          <GetFormError name={"template"} />
                        </InputContainer>
                      </FormRow>
                      <div className="side-menu-title">
                        <div className="tableLeft-title">
                          <LanguageProvider id={"dcp.platform.print.label"} />
                        </div>
                      </div>

                      <FormRow>
                        <InputContainer
                          className="print-devices-dropdown"
                          label={
                            <LanguageProvider id={"devices.drones.devices"} />
                          }
                        >
                          <Dropdown
                            id="devices"
                            name="devices"
                            className={classNames({
                              "p-invalid": IsFormValid("devices"),
                            })}
                            placeholder={devicesPlaceholder}
                            options={devices}
                            value={formikPrinter.values.device}
                            optionLabel="code"
                            onChange={(e) => {
                              formikPrinter.setFieldValue("device", e.value);
                            }}
                            loading={loadingDropdowns}
                          />
                          <GetFormError name={"devices"} />
                        </InputContainer>
                      </FormRow>
                      <FormRow>
                        <InputContainer
                          className="print-operator-dropdown"
                          label={
                            <LanguageProvider id={"devices.drones.operator"} />
                          }
                        >
                          <Dropdown
                            id="operator"
                            name="operator"
                            className={classNames({
                              "p-invalid": IsFormValid("operator"),
                            })}
                            placeholder={operatorPlaceholder}
                            options={providers}
                            value={formikPrinter.values.operator}
                            optionLabel="code"
                            onChange={(e) => {
                              formikPrinter.setFieldValue("operator", e.value);
                            }}
                            loading={loadingDropdowns}
                          />
                          <GetFormError name={"operator"} />
                          <div
                            className="color-bar"
                            style={{
                              background: formikPrinter.values.operator.color,
                            }}
                          ></div>
                        </InputContainer>
                      </FormRow>
                      <FormRow>
                        <InputContainer
                          label={
                            <LanguageProvider id={"devices.drones.serial"} />
                          }
                        >
                          <InputText
                            id={"serial"}
                            name={"serial"}
                            placeholder={serialNumberPlaceholder}
                            value={formikPrinter.values.serial}
                            onChange={formikPrinter.handleChange}
                            className={classNames({
                              "p-invalid": IsFormValid("serial"),
                            })}
                            ref={serialInputRef}
                            onKeyDown={(e) => handleKeyDown(e, 0)}
                            onBlur={() => formikPrinter.handleSubmit()}
                          />
                          <GetFormError name={"serial"} />
                        </InputContainer>
                      </FormRow>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="entry-footer">
                <div className="entry-manage-data-buttons">
                  <Button
                    id="clearData"
                    className="p-button p-button-text-plain clear-data"
                    onClick={() => setDisplayClearDataDialog(true)}
                    disabled={isFormFilled}
                  >
                    <LanguageProvider
                      id={"dcp.platform.warehouse.clean.data"}
                    />
                  </Button>
                  <Button
                    type="submit"
                    appearance="primary"
                    loading={loadingSavePrintForm}
                    onClick={() => formikPrinter.handleSubmit()}
                  >
                    <LanguageProvider id={"dcp.print.print.button"} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmation
        isOpen={displayClearDataDialog}
        isDelete={true}
        modalTitle={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
        }
        bodyMessage={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data.message"} />
        }
        onConfirm={handleClearData}
        onCancel={() => setDisplayClearDataDialog(false)}
      />
      <Dialog
        visible={loginDialogOpen}
        onHide={clearForm}
        className="wms-login-dialog"
        header={
          <span className="dcp.platform.wmscore.header">
            <LanguageProvider id="print.adm.login" />
          </span>
        }
      >
        <Form onSubmit={() => {}}>
          <FormRow>
            <InputContainer label={<LanguageProvider id="login.user" />}>
              <InputText
                value={admUsername}
                onChange={(e) => setAdmUsername(e.target.value)}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.password" />}>
              <Password
                className="wms-password"
                value={admPassword}
                onChange={(e) => setAdmPassword(e.target.value)}
                toggleMask
                feedback={false}
              />
            </InputContainer>
          </FormRow>
          <FormRow className="buttom-container">
            <Button
              type="button"
              className="p-button p-button-danger p-button-text"
              label={LanguageProvider({ id: "gen.cancel.button" })}
              onClick={clearForm}
            />
            <Button
              type="button"
              loading={loginLoading}
              label={LanguageProvider({ id: "login" })}
              onClick={() => loginAdm()}
            />
          </FormRow>
        </Form>
      </Dialog>
    </>
  );
};

export default PrintAction;
