import "../scss/printB2b.scss";
import { InputText } from "primereact/inputtext";
import React, { useContext, useRef, useState } from "react";
import { ThemeContext } from "~/app";
import { useToastContext } from "~/context/ToastContext";
import { GetOrderProcess } from "~/services/api/B2b/boticario/monitor-process";
import { Button } from "~/shared/components/dcp-button";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import PageHeader from "~/shared/components/page-header/page-header";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";

export default function OrderConsult() {
  const { currentTheme } = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const orderIdRef = useRef(null);

  const [carrier, setCarrier] = useState<string>("");
  const [status, setStatus] = useState<any>(null);
  const [orderId, setOrderId] = useState<string>("");
  const [currentOrderId, setCurrentOrderId] = useState<string>("");
  const [printBlocked, setPrintBlocked] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);

  function resetFields() {
    setOrderId("");
    setStatus(null);
    setCarrier("");
    setPrintBlocked(true);
  }

  function headerActions() {
    return (
      <div className="icon" onClick={() => setFullScreen(!fullScreen)}>
        <Icon
          icon={fullScreen ? "minimize-01" : "maximize-01"}
          size={18}
          color={currentTheme.iconDefaultColor}
        />
      </div>
    );
  }

  function renderStatusColumn(status) {
    switch (status) {
      case OrderMonitorStatus.inconsistentIntegration:
        return (
          <span className="processing-status-inconsistent-integration">
            <LanguageProvider
              id={"dcp.b2b.monitor.order.status.integration.inconsistency"}
            />
          </span>
        );
      case OrderMonitorStatus.createdOrder:
        return (
          <span className="processing-status-created">
            <LanguageProvider id={"dcp.b2b.monitor.order.status.created"} />
          </span>
        );
      case OrderMonitorStatus.finished:
        return (
          <span className="processing-status-finished">
            <LanguageProvider id={"dcp.b2b.monitor.order.status.finished"} />
          </span>
        );
      case OrderMonitorStatus.pendingShipping:
        return (
          <span className="processing-status-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pendent-shipping" />
          </span>
        );
      case OrderMonitorStatus.carrierDefinition:
        return (
          <span className="processing-status-carrier-definition">
            <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-definition" />
          </span>
        );
      case OrderMonitorStatus.pendingIntegrationCarrier:
        return (
          <span className="processing-status-loaded">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-integration-carrier" />
          </span>
        );
      case OrderMonitorStatus.pendingPacking:
        return (
          <span className="processing-status-packing">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-packing" />
          </span>
        );
      case OrderMonitorStatus.carrierError:
        return (
          <span className="processing-status-carrier-error">
            <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-error" />
          </span>
        );
      case OrderMonitorStatus.errorCarrierIntegration:
        return (
          <span className="processing-status-carrier-integration">
            <LanguageProvider id="dcp.b2b.monitor.order.status.error-carrier-integration" />
          </span>
        );
      case OrderMonitorStatus.reprocessCarrierIntegration:
        return (
          <span className="processing-status-reprocess-carrier-integration">
            <LanguageProvider id="dcp.b2b.monitor.order.status.reprocess-carrier-integration" />
          </span>
        );
      case OrderMonitorStatus.pendingReport:
        return (
          <span className="processing-status-report-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-report" />
          </span>
        );
      case OrderMonitorStatus.errorReport:
        return (
          <span className="processing-status-error-report">
            <LanguageProvider id="dcp.b2b.monitor.order.status.error-report" />
          </span>
        );
      case OrderMonitorStatus.reprocessingReport:
        return (
          <span className="processing-status-reprocessing-report">
            <LanguageProvider id="dcp.b2b.monitor.order.status.reprocessing-report" />
          </span>
        );
      case OrderMonitorStatus.pendingPrint:
        return (
          <span className="processing-status-print-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-print" />
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  }

  async function onKeyDownOrderId(e) {
    try {
      if (e.code !== "Tab" && e.code !== "Enter") return;
      e.preventDefault();
      if (orderId.length === 0) return;

      const { data, status } = await GetOrderProcess(0, orderId);
      if (status && data !== null) {
        setStatus(data.status);
        setCarrier(data.carrier);
        setCurrentOrderId(data.orderId);
        setOrderId("");
      } else {
        resetFields();
        showToast({
          severity: "error",
          message: <LanguageProvider id={"b2b.print.order-not-found"} />,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPrintBlocked(false);
    }
  }

  return (
    <div className={`print-order-container ${fullScreen ? "fullscreen" : ""}`}>
      <div className="backdrop">
        <PageHeader
          title={
            <LanguageProvider id="dcp.side.menu.b2b.use.points.order.consult" />
          }
          titleTemplate={undefined}
          recordsCount={undefined}
          onReturn={undefined}
          actions={headerActions}
        ></PageHeader>
        <div className="content-container">
          <div className="fields-container">
            <Form>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="print.b2b.field.order.id" />}
                >
                  <InputText
                    ref={orderIdRef}
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    onKeyDown={onKeyDownOrderId}
                  />
                </InputContainer>
              </FormRow>
              <h3>
                <LanguageProvider id="print.b2b.search.result" />:
              </h3>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="dcp.b2b.monitor.order.list.column.order" />
                  }
                >
                  <InputText value={currentOrderId} disabled={true}></InputText>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="dcp.b2b.monitor.order.list.column.carrier" />
                  }
                >
                  <InputText
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                    ref={orderIdRef}
                    disabled={true}
                    autoFocus
                  ></InputText>
                </InputContainer>
              </FormRow>
              <FormRow>
                {" "}
                {/* Todo*/}
                <InputContainer>
                  <div className="order-status">
                    {renderStatusColumn(status)}
                  </div>
                </InputContainer>
              </FormRow>
            </Form>
          </div>
        </div>
        <div className="footer-container">
          <div>
            <Button
              disabled={printBlocked}
              appearance="secondary"
              onClick={resetFields}
            >
              <LanguageProvider id="locale.clear" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
