import settings from "~/services/settings.json";
import { Navigate, Outlet } from "react-router-dom";
import React, { useLayoutEffect, useState } from "react";
import LoadingIndicator from "./dcp-loading-indicator";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

const DcpSettingsParamsProtectedRoute = ({ requiredParams }) => {
  const [isMatchingParams, setIsMatchingParams] = useState(false);
  const [loading, setLoading] = useState(true);
  const dcpAxiosService = useDcpAxiosService();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  async function loadSettingsParameters() {
    setLoading(true);
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/list-settings`,
        "Platform",
        {
          params: { applicationClientId },
        }
      );
      const settingsParameters = data.data
        .filter((setting) => setting.name === "modules_admin")
        .flatMap((setting) => setting.subgroups)
        .flatMap((subgroup) => subgroup.settings)
        .map(({ propertyName, value }) => ({ propertyName, value }));

      if (status === 200 && settingsParameters.length > 0) {
        setIsMatchingParams(
          settingsParameters.some(
            (param) =>
              requiredParams.includes(param.propertyName) &&
              param.value === "true"
          )
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useLayoutEffect(() => {
    loadSettingsParameters();
  }, []);

  if (loading) return <LoadingIndicator />;

  if (isMatchingParams) {
    return <Outlet />;
  } else {
    return <Navigate to={"/denied-access"} replace />;
  }
};

export default DcpSettingsParamsProtectedRoute;
