import "./scss/inventory-create.scss";

import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import React, { useContext, useEffect, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { ReactComponent as DroneSVG } from "~/theme/custom-icons/drone-color-variation.svg";
import ExcelUploadDialog from "~/shared/components/excel-upload-dialog/excel-upload-dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { ReactComponent as ManualReadSVG } from "~/theme/custom-icons/manual-read.svg";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { UserContext } from "~/app";
import { getUsers } from "~/services/api";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useLanguageContext } from "~/context/LanguageContext";
import { useToastContext } from "~/context/ToastContext";

export function InventoryCreate({ isOpen, onHide, onSave }) {
  const { currentLanguage } = useLanguageContext();
  const axiosService = useDcpAxiosService();
  const { user: authenticatedUser } = useContext(UserContext);

  const [displayBlockFileUploadDialog, setDisplayBlockFileUploadDialog] =
    useState(false);

  // Data
  const [drones, setDrones] = useState([]);
  const [areas, setAreas] = useState([]);
  const [skuOptionList, setSkuOptionList] = useState([]);
  const [locationOptionList, setLocationOptionList] = useState([]);
  const [users, setUsers] = useState([]);

  // loading indicators
  const [loadingDrones, setLoadingDrones] = useState(false);
  const [skuOptionLoading, setSkuOptionLoading] = useState(false);
  const [locationOptionLoading, setLocationOptionLoading] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);

  // Other
  const { showToast } = useToastContext();
  const dcpAxiosService = useDcpAxiosService();

  const generateCode = () => {
    const timeCode = new Date().toLocaleString().replace(/\D/g, "");
    return timeCode;
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      code: generateCode(),
      estimatedLocations: 0,
      name: "",
      description: "",
      programmedTo: new Date(),
      drone: true,
      manual: false,
      selectedDrones: [],
      selectedUsers: [],
      selectedAreas: [],
      readType: 1,
      restrictionType: 3,
      codes: [],
    },
    onSubmit: saveForm,
    validate: validateFields,
  });

  async function saveForm(values) {
    try {
      let codes = [];
      if (values.restrictionType === 2) {
        codes = values.codes.map((x) => x.code);
      } else if (values.restrictionType === 1) {
        codes = values.codes.map((x) => x.name);
      }
      const { status, data } = await axiosService.post(
        settings.Urls.Rest.Inventory + "/create",
        {
          ...values,
          selectedUsers: values.selectedUsers.map((u) => u.userId),
          selectedAreas: values.selectedAreas.map((a) => a.id),
          codes: codes,
        },
        "Inventory"
      );
      if (status === 200 && data.status) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "inventory.save.success",
            currentLanguage,
          }),
        });
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: "inventory.save.error",
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      resetForm();
      onHide();
      onSave();
    }
  }
  function validateFields(values) {
    try {
      const errors = {};

      if (values.name.length < 1)
        errors["name"] = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      if (values.selectedAreas.length < 1) {
        errors["selectedAreas"] = LanguageProviderWithoutContext({
          id: "inventory.area.error",
          currentLanguage,
        });
      }
      if (values.estimatedLocations < 1) {
        errors["estimatedLocations"] = LanguageProviderWithoutContext({
          id: "inventory.estimated.locations.error",
          currentLanguage,
        });
      }
      if (!values.manual && !values.drone)
        errors["type"] = LanguageProviderWithoutContext({
          id: "inventory.type.error",
          currentLanguage,
        });
      if (values.manual && values.selectedUsers.length < 1)
        errors["selectedUsers"] = LanguageProviderWithoutContext({
          id: "inventory.no.responsible.error",
          currentLanguage,
        });

      return errors;
    } catch (error) {
      console.error(error);
    }
  }
  function resetForm() {
    formik.resetForm({
      values: { ...formik.initialValues, code: generateCode() },
    });
  }
  const isFormFieldInvalid = (name) => {
    return !(formik.touched[name] && formik.errors[name]);
  };
  const getFormErrorMessage = (name: string) => {
    const error = formik.errors[name];
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{typeof error === "string" && error}</small>
    ) : (
      <small className="p-error" />
    );
  };

  async function loadDrones() {
    setLoadingDrones(true);
    try {
      const { data, status } = await axiosService.post(
        settings.Urls.Rest.Equipment + "/list/drone",
        {
          first: 0,
          rows: 999999999,
          page: 0,
          sortField: null,
          sortOrder: 1, // 1 or -1
          filters: [],
        },
        "Inventory"
      );
      if (status === 200) {
        return data.data.equipments;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDrones(false);
    }
    return [];
  }
  async function loadSkus() {
    setSkuOptionLoading(true);
    try {
      const { data, status } = await axiosService.get(
        `${settings.Urls.Rest.Inventory}/list-sku-mobile`,
        "Inventory"
      );
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSkuOptionLoading(false);
    }
    return [];
  }
  async function loadAreas() {
    setLoadingAreas(true);
    try {
      const { status, data } = await axiosService.get(
        `${settings.Urls.Rest.AreaMap}/list-areas`,
        "Platform"
      );

      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAreas(false);
    }
    return [];
  }
  async function loadLocations(areaIds, e = null) {
    setLocationOptionLoading(true);
    try {
      const { status, data } = await dcpAxiosService.post(
        `${settings.Urls.Rest.Inventory}/filter-list-area-locations`,
        {
          areaIds: areaIds,
          query: e,
        },
        "Inventory"
      );
      if (status === 200 && data.data) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocationOptionLoading(false);
    }
    return [];
  }

  function handleLocationsChange(e) {
    try {
      formik.setFieldValue("codes", e.value);
      formik.setFieldValue(
        "estimatedLocations",
        e.value.length > 0 ? e.value.length : 0
      );
    } catch (error) {
      console.error(error);
    }
  }
  function handleResponsibleUserChange(e) {
    try {
      formik.setFieldValue("selectedUsers", e.value);
    } catch (error) {
      console.error(error);
    }
  }
  function handleDroneChange(e) {
    try {
      formik.setFieldValue(
        "selectedDrones",
        e.value.map((d) => d.id)
      );
    } catch (error) {
      console.error(error);
    }
  }
  async function handleInventoryAreaChange(e) {
    try {
      formik.setFieldValue("selectedAreas", e.value);
      const locations = await loadLocations(e.value.map((x) => x.id));
      setLocationOptionList(locations);
    } catch (error) {
      console.error(error);
    }
  }

  function cancelForm() {
    resetForm();
    onHide();
  }

  useEffect(() => {
    async function initialize() {
      const [skus, areas, drones, users] = await Promise.all([
        loadSkus(),
        loadAreas(),
        loadDrones(),
        getUsers(),
      ]);
      const currentUser = users.data.find(
        (u) => u.userId === authenticatedUser.userId
      );
      setUsers(users.data);
      setSkuOptionList(skus);
      setAreas(areas);
      setDrones(drones);
      setSkuOptionList(skus);
      const selectedAreas = areas.length > 1 ? [areas[0]] : [];
      if (selectedAreas.length > 0) {
        const locations = await loadLocations(selectedAreas.map((x) => x.id));
        setLocationOptionList(locations);
      }
      formik.setFieldValue("selectedUsers", [currentUser]);
      formik.setFieldValue("selectedDrones", drones ?? []);
      formik.setFieldValue("selectedAreas", selectedAreas);
    }
    if (isOpen) {
      initialize();
    }
  }, [isOpen]);

  return (
    <Dialog
      className={"create-inventory-dialog"}
      appendTo={"self"}
      visible={isOpen}
      onHide={cancelForm}
      closeOnEscape={false}
      draggable={false}
    >
      <div className="main-container">
        <div className="content">
          <div className="inventory-options">
            <h2 className="title">
              <LanguageProvider id="inventory.create.new" />
            </h2>
            <p className="description">
              <LanguageProvider id="inventory.create.description.1" />
            </p>
            <p className="description">
              <LanguageProvider id="inventory.create.description.2" />
            </p>
            <p className="description">
              <LanguageProvider id="inventory.create.description.3" />
            </p>
            <Form className="inputs">
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.code" />}
                  required
                >
                  <InputText value={formik.values.code} disabled={true} />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.name" />}
                  required
                >
                  <InputText
                    invalid={isFormFieldInvalid("name")}
                    value={formik.values.name}
                    placeholder={LanguageProvider({
                      id: "inventory.name.placeholder",
                    })}
                    onChange={(e) =>
                      formik.setFieldValue("name", e.target.value)
                    }
                  />
                  {getFormErrorMessage("name")}
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="areas" />}
                  required
                >
                  <MultiSelect
                    className="areas-selection"
                    options={areas}
                    value={formik?.values.selectedAreas}
                    onChange={handleInventoryAreaChange}
                    display="chip"
                    optionLabel="name"
                    filter
                    selectAll
                    maxSelectedLabels={3}
                    loading={loadingAreas}
                  />
                  {getFormErrorMessage("selectedAreas")}
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="inventory.predicted.locations" />
                  }
                  required
                >
                  <InputNumber
                    value={formik.values.estimatedLocations}
                    placeholder={LanguageProvider({
                      id: "inventory.predicted.locations.placeholder",
                    })}
                    onChange={(e) => {
                      formik.setFieldValue("estimatedLocations", e.value);
                    }}
                    min={0}
                  />
                  {getFormErrorMessage("estimatedLocations")}
                </InputContainer>
                <InputContainer
                  label={<LanguageProvider id="inventory.programmed.to" />}
                  required
                >
                  <Calendar
                    value={formik.values.programmedTo}
                    onChange={(e) =>
                      formik.setFieldValue("programmedTo", e.value)
                    }
                  />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.description" />}
                >
                  <InputTextarea
                    className="text-area-description"
                    rows={5}
                    autoResize
                    value={formik.values.description}
                    placeholder={LanguageProvider({
                      id: "inventory.description.placeholder",
                    })}
                    onChange={(e) =>
                      formik.setFieldValue("description", e.target.value)
                    }
                  />
                </InputContainer>
              </FormRow>
            </Form>
          </div>
          <div className="equipment-type">
            <div className="read-type-wrapper">
              <p className="label type">
                <LanguageProvider id="inventory.type" />
              </p>
              <div className="read-type">
                <div className="radio">
                  <RadioButton
                    value={1}
                    checked={formik.values.readType === 1}
                    onChange={(e) => formik.setFieldValue("readType", e.value)}
                  />
                  <span className="label">
                    <LanguageProvider id="inventory.read.type.sku" />
                  </span>
                </div>
                {/* <div className="radio">
                  <RadioButton
                    value={2}
                    checked={formik.values.readType === 2}
                    onChange={(e) => formik.setFieldValue("readType", e.value)}
                  />
                  <span className="label">
                    <LanguageProvider id="inventory.read.type.serial" />
                  </span>
                </div> */}
              </div>
            </div>

            <div className="type-container">
              <p className="label type">
                <LanguageProvider id="inventory.type" />
              </p>
              <div
                className={`type-card ${formik.values.drone ? "active" : null}`}
              >
                <div className="icon">
                  <DroneSVG />
                </div>
                <div className="card-content">
                  <h3 className="title">
                    <LanguageProvider id="inventory.type.drone" />
                  </h3>
                  <p className="description">
                    <LanguageProvider id="inventory.create.drone.description" />
                  </p>
                </div>
                <div className="check-container">
                  <Checkbox
                    checked={formik.values.drone}
                    onChange={async (e) => {
                      formik.setFieldValue("drone", e.checked);
                      if (e.checked) {
                        const drones = await loadDrones();
                        formik.setFieldValue("selectedDrones", drones);
                      } else {
                        formik.setFieldValue("selectedDrones", []);
                      }
                    }}
                  />
                </div>
              </div>
              {getFormErrorMessage("type")}
            </div>
            <div className="selection">
              <p className="label">
                <LanguageProvider id="devices.drones" />
              </p>
              <MultiSelect
                className="drones-selection"
                options={drones}
                value={formik?.values?.selectedDrones}
                loading={loadingDrones}
                onChange={handleDroneChange}
                maxSelectedLabels={2}
                display="chip"
                optionLabel="name"
                filter
                selectAll
                disabled={!formik.values.drone}
              />
            </div>
            <div className="type-container">
              <div
                className={`type-card ${
                  formik.values.manual ? "active" : null
                }`}
              >
                <div className="icon">
                  <ManualReadSVG />
                </div>
                <div className="card-content">
                  <h3 className="title">
                    <LanguageProvider id="inventory.type.manual" />
                  </h3>
                  <p className="description">
                    <LanguageProvider id="inventory.create.drone.description" />
                  </p>
                </div>
                <div className="check-container">
                  <Checkbox
                    checked={formik.values.manual}
                    onChange={(e) => formik.setFieldValue("manual", e.checked)}
                  />
                </div>
              </div>
              {getFormErrorMessage("type")}
            </div>
            <div className="selection">
              <p className="label">
                <LanguageProvider id="gen.responsibles" />
              </p>
              <MultiSelect
                className="users-selection"
                value={formik?.values.selectedUsers}
                options={users}
                disabled={!formik.values.manual}
                onChange={handleResponsibleUserChange}
                display="chip"
                optionLabel="name"
                filter
                selectAll
                maxSelectedLabels={3}
              />
              {getFormErrorMessage("selectedUsers")}
            </div>
            <div className="inventory-type">
              <div className="read-type-wrapper">
                <p className="label type">
                  <LanguageProvider id="inventory.block.type" />
                </p>
                <div className="read-type">
                  <div className="radio">
                    <RadioButton
                      value={2}
                      checked={formik.values.restrictionType === 2}
                      onChange={(e) => {
                        formik.setFieldValue("restrictionType", e.value);
                        formik.setFieldValue("codes", []);
                        formik.setFieldValue("estimatedLocations", 0);
                      }}
                    />
                    <span className="label">
                      <LanguageProvider id="inventory.read.type.sku" />
                    </span>
                  </div>
                  <div className="radio">
                    <RadioButton
                      value={1}
                      checked={formik.values.restrictionType === 1}
                      onChange={(e) => {
                        formik.setFieldValue("restrictionType", e.value);
                        formik.setFieldValue("codes", []);
                      }}
                    />
                    <span className="label">
                      <LanguageProvider id="inventory.read.type.location" />
                    </span>
                  </div>
                  <div className="radio">
                    <RadioButton
                      value={3}
                      checked={formik.values.restrictionType === 3}
                      onChange={(e) => {
                        formik.setFieldValue("restrictionType", e.value);
                        formik.setFieldValue("codes", []);
                        formik.setFieldValue("estimatedLocations", 0);
                      }}
                    />
                    <span className="label">
                      <LanguageProvider id="inventory.read.type.no.block" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="selection">
                {formik.values.restrictionType === 2 && (
                  <MultiSelect
                    className="sku-selection"
                    options={skuOptionList}
                    value={formik?.values?.codes}
                    onChange={(e) => formik.setFieldValue("codes", e.value)}
                    loading={skuOptionLoading}
                    optionLabel="pattern"
                    display="chip"
                    filter
                    disabled={formik.values.restrictionType === 3}
                    virtualScrollerOptions={{
                      itemSize: 38,
                    }}
                  />
                )}
                {formik.values.restrictionType === 1 && (
                  <MultiSelect
                    className="location-selection"
                    display="chip"
                    filter
                    options={locationOptionList}
                    value={formik?.values?.codes}
                    optionLabel="name"
                    onChange={handleLocationsChange}
                    loading={locationOptionLoading}
                    disabled={
                      formik.values.restrictionType === 3 ||
                      formik.values.selectedAreas.length <= 0
                    }
                    virtualScrollerOptions={{
                      itemSize: 38,
                    }}
                  />
                )}
                {formik.values.restrictionType === 3 && (
                  <MultiSelect className="location-selection" disabled />
                )}
              </div>
              <Button
                label={LanguageProvider({ id: "gen.file.upload" })}
                icon={"pi pi-upload"}
                iconPos="right"
                disabled={formik.values.restrictionType === 3}
                onClick={() => {
                  setDisplayBlockFileUploadDialog(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button appearance="secondary" onClick={cancelForm}>
            <LanguageProvider id="gen.cancel.button" />
          </Button>
          <Button
            disabled={Object.keys(formik.errors).length > 0}
            onClick={() => formik.handleSubmit()}
          >
            <LanguageProvider id="gen.create.button" />
          </Button>
        </div>
      </div>
      <ExcelUploadDialog
        className="inventory-cretion-block-file-upload-dialog"
        visible={displayBlockFileUploadDialog}
        requiredFields={
          formik.values.restrictionType === 1
            ? [
                {
                  field: "location",
                  label: <LanguageProvider id={"gen.location"} />,
                },
              ]
            : [{ field: "sku", label: <LanguageProvider id={"gen.sku"} /> }]
        }
        header={<LanguageProvider id={"gen.file.upload"} />}
        onHide={() => {
          setDisplayBlockFileUploadDialog(false);
        }}
        onDataConfirm={(data) => {
          let finalFormData = [];
          if (formik.values.restrictionType === 1) {
            data.forEach((element) => {
              const info = locationOptionList.find(
                (x) => x.name === element.location
              );
              if (info) {
                finalFormData.push(info);
              }
            });
          } else {
            data.forEach((element) => {
              const info = skuOptionList.find((x) => x.code === element.sku);
              if (info) {
                finalFormData.push(info);
              }
            });
          }
          const uniqueData = Array.from(
            new Map(
              finalFormData.map((item) => [item.name || item.pattern, item])
            ).values()
          );

          if (formik.values.restrictionType === 1) {
            formik.setFieldValue("estimatedLocations", 0);
          }

          formik.setFieldValue("codes", uniqueData);

          if (uniqueData.length != data.length) {
            showToast({
              severity: "info",
              message: LanguageProviderWithoutContext({
                id: "inventory.creation.unable.to.parse.some.data",
                currentLanguage,
              }),
            });
          }
          formik.setFieldValue("estimatedLocations", uniqueData?.length);
          formik.validateField("estimatedLocations");
        }}
      />
    </Dialog>
  );
}
