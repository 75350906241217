import "./WmsCoreCevaOperatorProductivityChart.scss";
import React, { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import fakedata from "../../fake-data/document-print/document-print-operator-productivity-chart.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Skeleton } from "primereact/skeleton";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { propertyFrommStorage, writeStorage, getFromStorage } from "~/services/storage/storage-access";
import { Calendar } from "primereact/calendar";
import Icon from "~/shared/components/icons";

export default function WmsCoreCevaOperatorProductivityChart(configs) {
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([0]);
  const [settingsHourData, setSettingsHourData] = useState({
    goalHourValueSuccess: 0,
    goalHourValueWarning: 0,
  });
  const wmsCoreProductivityChartData = propertyFrommStorage("dashboardCharts", "wmsCoreProductivityChartData");
  const [date, setDate] = useState(null);

  const renderCell = (value) => {
    let cellClass = "";
    if (value > 0) {
      if (value >= settingsHourData.goalHourValueSuccess) cellClass = "dot-green";
      else if (value >= settingsHourData.goalHourValueWarning)
        cellClass = "dot-yellow";
      else if (value < settingsHourData.goalHourValueWarning && value > 0)
        cellClass = "dot-red";
    }

    return (
      <div className={`dash-content-cel ${cellClass}`}>
        <span className="dot">{value > 0 ? value : " "}</span>
      </div>
    );
  };

  const renderAverageCell = (value) => {
    let cellClass = "";
    if (value >= settingsHourData.goalHourValueSuccess) cellClass = "dot-green";
    else if (value >= settingsHourData.goalHourValueWarning)
      cellClass = "dot-yellow";
    else if (value < settingsHourData.goalHourValueWarning)
      cellClass = "dot-red";

    return (
      <div className={`dash-content-cel ${cellClass}`}>
        <span className="dot">{value >= 0 ? value : " "}</span>
      </div>
    );
  };

  const calculateTotalProduction = (data) => {
    return data.reduce((sum, collaborator) => sum + (collaborator?.stats?.total || 0), 0);
  };
  
  const calculateAverageProduction = (data) => {
    const total = data.reduce((sum, collaborator) => sum + (collaborator?.stats?.average || 0), 0);
    return Math.round(total / data.length);
  };

  const setWMSCoreDateTimeOnLocalStorage = (value) => {
    var existingData = getFromStorage("dashboardCharts");
    if (existingData) {
      if (wmsCoreProductivityChartData) {
        existingData.wmsCoreProductivityChartData.printDateTime = value;
      }
      else {
        existingData.wmsCoreProductivityChartData = {
          printDateTime: value
        };
      }
    }
    else {
      existingData = {
        wmsCoreProductivityChartData : {
          printDateTime: value
        }
      };
    }

    writeStorage("dashboardCharts", existingData);
    setDate(value);
  };

  const clearCalendarDate = () => {
      var existingData = getFromStorage("dashboardCharts");
      if (existingData) {
        if (wmsCoreProductivityChartData) {
          existingData.wmsCoreProductivityChartData.printDateTime = "";
        }
        else {
          existingData.wmsCoreProductivityChartData = {
            printDateTime: ""
          };
        }
      }
      else {
        existingData = {
          wmsCoreProductivityChartData : {
            printDateTime: ""
          }
        };
      }
  
      writeStorage("dashboardCharts", existingData);
      setDate("");
    };

  useEffect(() => {
    if (wmsCoreProductivityChartData?.printDateTime && !date) {
      setDate(new Date(wmsCoreProductivityChartData.printDateTime));
    }
  }, [wmsCoreProductivityChartData, date]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const goalHourValue = configs.find(
          (config) => config.defaultConfig.name === "hour-goal"
        ).value;
        const goalHourValueSuccess = parseInt(
          goalHourValue?.split(",")[0].replace("[", "")
        ); //
        const goalHourValueWarning = parseInt(goalHourValue?.split(",")[1]);
        setSettingsHourData({
          goalHourValueSuccess: goalHourValueSuccess,
          goalHourValueWarning: goalHourValueWarning,
        });

        const params = wmsCoreProductivityChartData ? {
          params: {
            dataEvento: date
          }
        } : {};

        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.DashboardItem + "/list-operator-productivity-data",
          "CustomApps",
          params
        );
        
        if (status !== 200 || !data.data) return;
        setChartData(data.data);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (useMockData) {
      setChartData(fakedata);
      setSettingsHourData({
        goalHourValueSuccess: 38,
        goalHourValueWarning: 20,
      });
    } else {
      loadData();
    }
  }, [useMockData, configs, dcpAxiosService, date]);

  return !loading ? (
    <>
      <div className="serial-number-dashboard">

        <div className="collaborator-calendar-wrapper">
          <div className="collaborator-calendar-label">
            <LanguageProvider
              id={"dashboard.item.type.operator-productivity-date"}
            />
          </div>
          <Calendar
            onChange={(e) => setWMSCoreDateTimeOnLocalStorage(e.value)}
            value={date}
            selectionMode="single"
            readOnlyInput
          />
          <button className="p-button-plain" onClick={clearCalendarDate}>
            <Icon
              icon={"Broom"}
              className="btn-icon-broom"
            ></Icon>
          </button>
        </div>

        <div className="content-wrapper">
          <div className="collaborator-wrapper">
            <div className="collaborator-header">
              <div className="column-collaborator">
                <LanguageProvider
                  id={"dashboard.item.type.operator-productivity-collaborator"}
                />
              </div>
            </div>

            <div className="collaborator-content">
              {Array.isArray(chartData) &&
                chartData?.length > 0 &&
                chartData?.map((collaborator, index) => (
                  <div
                    key={`collaborator-${index}`}
                    className={`collaborator-row ${index % 2 === 0 ? "" : "dash-content-row-selected"
                      }`}
                  >
                    {collaborator?.user_name}
                  </div>
                ))}
            </div>
          </div>

          <div className="hours-wrapper">
            <div className="hours-header">
              {[...Array(24).keys()].map((hour) => (
                <div key={`hour-${hour}`} className="column-time">
                  {hour.toString().padStart(2, "0")}
                </div>
              ))}
            </div>

            <div className="hours-content">
              {Array.isArray(chartData) &&
                chartData?.length > 0 &&
                chartData.map((collaborator, index) => (
                  <div
                    key={`hour-row-${index}`}
                    className={`hour-row ${index % 2 === 0 ? "" : "dash-content-row-selected"
                      }`}
                  >
                    {Object.keys(collaborator)
                      .filter((key) => key.startsWith("t"))
                      .map((key, indexKey) => (
                        <div key={indexKey}>{renderCell(collaborator[key])}</div>
                      ))}
                  </div>
                ))}
            </div>
          </div>

          <div className="table-right-data-content">
            <div className="average-production-wrapper">
              <div className="column-average-prod">
                <LanguageProvider
                  id={
                    "dashboard.item.type.operator-productivity-average-production"
                  }
                />
              </div>
              <div className="collaborator-average-content">
                {Array.isArray(chartData) && chartData?.length > 0 && (
                  <>
                    {chartData?.map((collaborator, index) => (
                      <div
                        key={`collaborator-${index}`}
                        className={`collaborator-average-row ${index % 2 === 0 ? "" : "dash-content-row-selected"}`}
                      >
                        {renderAverageCell(collaborator?.stats?.average || 0)}
                      </div>
                    ))}
                    <div className="collaborator-average-footer">
                      <div className="collaborator-average-footer-divider" />
                      <div className="collaborator-average-total">{calculateAverageProduction(chartData)}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="total-production-wrapper">
              <div className="column-total-prod">
                <LanguageProvider
                  id={
                    "dashboard.item.type.operator-productivity-total-production"
                  }
                />
              </div>
              <div className="collaborator-total-content">
                {Array.isArray(chartData) && chartData?.length > 0 && (
                  <>
                    {chartData?.map((collaborator, index) => (
                      <div
                        key={`collaborator-${index}`}
                        className={`collaborator-row ${index % 2 === 0 ? "" : "dash-content-row-selected"}`}
                      >
                        {collaborator?.stats?.total}
                      </div>
                    ))}
                    <div className="collaborator-total-footer">
                      <div className="collaborator-total-footer-divider" />
                      <div className="collaborator-total-total">{calculateTotalProduction(chartData)}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="production-wrapper">
              <div className="column-prod">
                <LanguageProvider
                  id={"dashboard.item.type.operator-productivity-production"}
                />
              </div>
              <div className="collaborator-content">
                {Array.isArray(chartData) && chartData.length > 0 && (
                  <>
                    {chartData.map((collaborator, index) => (
                      <div
                        key={`collaborator-${index}`}
                        className={`collaborator-row ${index % 2 === 0 ? "" : "dash-content-row-selected"}`}
                      >
                        {(collaborator?.stats?.percentage || 0)}%
                      </div>
                    ))}
                    <div className="colaborator-production-divider"></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="chart-load">
      <Skeleton height="33%"></Skeleton>
      <Skeleton height="100%"></Skeleton>
      <Skeleton height="66%"></Skeleton>
    </div>
  );
}