import "../scss/order-monitor-details-dialog.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "~/shared/components/dcp-button";
import { TabPanel, TabView } from "primereact/tabview";
import Icon from "~/shared/components/icons";
import {
  DeleteOrderProcessImages,
  EditOrderCarrier,
  GetCarriers,
  GetOrderDocuments,
  GetOrderProcess,
  ListOrderProcessImages,
  ListOrderProcessStatusHistory,
  ReintegrateOrderCarrier,
  ReprocessReport,
  DownloadOrderDocument,
  UpdateFieldOrder,
  UpdateOrderStatus,
} from "~/services/api/B2b/boticario/monitor-process";
import { Skeleton } from "primereact/skeleton";
import { Menu } from "primereact/menu";
import { DcpCustomZoomImage } from "~/shared/components/dcp-zoom-image";
import emptyInbox from "~/theme/media/assets/emptyInbox.svg";
import { ThemeContext } from "~/app";
import { Dropdown } from "primereact/dropdown";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";
import { useToastContext } from "~/context/ToastContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";
import { OrderMonitorTimeline } from "./order-monitor-timeline";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { loadSettingsModulesAdmin } from "~/services/api/settings/settings";
import SettingsParameters from "~/shared/enums/b2b/SettingsParameters";
import { ModalUpdateOrderStatus } from "./modal-update-order-status";
import { OrderStatusField } from "./order-status-field";

const ModalOrderMonitorDetails = ({ orderId, isOpen, onClose }) => {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const { showToast } = useToastContext();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );
  const menuRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingSettingsFlux, setLoadingSettingsFlux] = useState(true);
  const [loadingCarrier, setLoadingCarrier] = useState(false);
  const [modalConfirmationVisible, setModalConfirmationVisible] =
    useState(false);
  const [modalUpdateStatusVisible, setModalUpdateStatusVisible] =
    useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [modulesAdminDeliveryFlux, setModulesAdminDeliveryFlux] = useState();
  const [modalBodyMessage, setModalBodyMessage] = useState("");
  const [orderProcessStatusHistoryData, setOrderProcessStatusHistoryData] =
    useState([]);
  const [orderDocuments, setOrderDocuments] = useState([]);
  const [orderProcessData, setOrderProcessData] = useState();
  const [orderImages, setOrderImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [orderImageCount, setOrderImageCount] = useState(0);
  const [carriers, setCarriers] = useState([]);
  const [tempSelectedCarrier, setTempSelectedCarrier] = useState(0);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  // Update fields
  const [city, setCity] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [deliveryDocType, setDeliveryDocType] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [qtyItems, setQtyItems] = useState("");

  const getOrderProcess = async () => {
    if (!orderId) return;

    setLoading(true);
    try {
      const { data, status } = await GetOrderProcess(orderId);
      if (status) {
        setOrderProcessData(data);
        setCity(data?.city);
        setCustomerId(data?.customerId);
        setCityCode(data?.cityCode);
        setDeliveryDocType(data?.deliveryDocumentType);
        setTotalCost(data?.totalCost);
        setQtyItems(data?.volume);

        getCarriers(data?.idDatabaseCarrier);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  async function getCarriers(selectedOrderCarrier) {
    try {
      setLoadingCarrier(true);
      const { data, status } = await GetCarriers();
      if (status) {
        setCarriers(data);
        const selectedCarrier = data.find(
          (carrier) => carrier?.id_database_item === selectedOrderCarrier
        );
        setSelectedCarrier(selectedCarrier);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCarrier(false);
    }
  }

  async function editOrderCarrier(orderId, carrier) {
    try {
      const carrierModel = {
        idDatabaseItem: carrier?.id_database_item,
        code: carrier?.code,
        orderId: orderId,
      };
      const { data, status } = await EditOrderCarrier(carrierModel);

      if (status) {
        await getOrderProcess();
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "dcp.b2b.monitor.order.details.dialog.carrier.updated",
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setModalConfirmationVisible(false);
    }
  }

  async function listOrderStatusHIstory(orderId) {
    try {
      const { data, status } = await ListOrderProcessStatusHistory(orderId);
      if (status) {
        setOrderProcessStatusHistoryData(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getOrderDocuments() {
    try {
      const { data, status } = await GetOrderDocuments(orderId);
      if (status) {
        setOrderDocuments(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function checkStatusToDisable(orderProcessStatus) {
    const monitorStatus = orderProcessStatus;

    switch (monitorStatus) {
      case OrderMonitorStatus.carrierError:
        return false;
      default:
        return true;
    }
  }

  function handleChangeCarrier(e) {
    handleConfirmCarrierChange();
    const newCarrier = carriers.find(
      (carrier) => carrier?.id_database_item === e.value
    );
    setTempSelectedCarrier(newCarrier);
  }

  async function handleReprocessReport(orderId) {
    try {
      setLoading(true);
      const { data, status } = await ReprocessReport(orderId);
      if (status) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "dcp.b2b.dcp.b2b.monitor.order.details.dialog.reprocess.order.process",
            currentLanguage,
          }),
        });
        getOrderProcess();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleReprocessCarrierIntegration(orderId) {
    try {
      setLoading(true);
      const { data, status } = await ReintegrateOrderCarrier(orderId);
      if (status) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "dcp.b2b.dcp.b2b.monitor.order.details.dialog.reprocess.order.process",
            currentLanguage,
          }),
        });
        getOrderProcess();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function onKeyDownUpdateFieldOrder(e, field, value) {
    try {
      if (e.code !== "Tab" && e.code !== "Enter") return;
      e.preventDefault();
      const { data, status } = await UpdateFieldOrder(orderId, field, value);
      if (data && status) {
        showToast({
          severity: "success",
          message: (
            <>
              <LanguageProvider id={"gen.field.update"} />
            </>
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleConfirmDelete() {
    setIsDelete(true);
    setModalConfirmationVisible(true);
    setModalTitle(LanguageProviderWithoutContext({ id: "image.delete.title" }));
    setModalBodyMessage(
      LanguageProviderWithoutContext({ id: "image.delete.message" })
    );
  }

  function handleConfirmCarrierChange() {
    setIsDelete(false);
    setModalConfirmationVisible(true);
    setModalTitle(
      LanguageProviderWithoutContext({
        id: "dcp.b2b.dcp.b2b.monitor.order.details.dialog.carrier.change.confirm.title",
      })
    );
    setModalBodyMessage(
      LanguageProviderWithoutContext({
        id: "dcp.b2b.dcp.b2b.monitor.order.details.dialog.carrier.change.confirm.message",
      })
    );
  }

  function handleConfirmResponse() {
    if (isDelete) {
      deleteImage();
    } else {
      editOrderCarrier(orderId, tempSelectedCarrier);
    }
  }

  const menuTemplate = (item, options, label, icon, color) => {
    return (
      <div
        onClick={(e) => options.onClick(e)}
        className={options.className}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          padding: "12px 16px",
        }}
      >
        <Icon icon={icon} color={color} size={16} />
        <p
          style={{ margin: 0, color, fontSize: "14px" }}
          className="menu-title"
        >
          <LanguageProvider id={label} />
        </p>
      </div>
    );
  };

  const menuItems = [
    {
      template: (item, options) => {
        return (
          <p
            style={{ padding: "12px 16px", margin: 0, fontWeight: 600 }}
            className="menu-title"
          >
            <LanguageProvider id="gen.menu" />
          </p>
        );
      },
    },
    { separator: true },
    {
      template: (item, options) =>
        menuTemplate(
          item,
          options,
          "gen.download",
          "download-01",
          currentTheme.textPrimary
        ),
      command: downloadSelectedImage,
    },
    {
      template: (item, options) =>
        menuTemplate(
          item,
          options,
          "gen.exclude.button",
          "trash-02",
          "#F9485B"
        ),
      command: (e) => {
        handleConfirmDelete();
      },
    },
  ];

  function downloadSelectedImage() {
    let a = document.createElement("a");
    a.href = selectedImage?.path;
    a.setAttribute("download", selectedImage?.id);
    a.click();
  }

  async function downloadOrderDocument(rowData) {
    try {
      const { data, status, message } = await DownloadOrderDocument(
        rowData?.id
      );
      if (status) {
        const base64Data = data;
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${rowData?.documentName}.${rowData?.extension}`
        );
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);

        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "dcp.b2b.monitor.order.details.dialog.carrier.download.success",
            currentLanguage,
          }),
        });
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadImages() {
    try {
      const { data, status } = await ListOrderProcessImages(orderId);
      if (status) {
        setOrderImageCount(data.length);
        setOrderImages(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteImage() {
    try {
      setLoading(true);
      const { data, status } = await DeleteOrderProcessImages(
        selectedImage?.id
      );
      if (status) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "image.deleted",
            currentLanguage,
          }),
        });
        await loadImages();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setModalConfirmationVisible(false);
    }
  }

  async function loadSettingsParametersFluxLabel() {
    setLoadingSettingsFlux(true);
    try {
      const settingsModulesAdminResponse = await loadSettingsModulesAdmin(
        applicationClientId,
        [SettingsParameters.FLUX_DELIVERY_MANAGEMENT]
      );
      setModulesAdminDeliveryFlux(settingsModulesAdminResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSettingsFlux(false);
    }
  }

  useEffect(() => {
    loadSettingsParametersFluxLabel();
    getOrderProcess();
    listOrderStatusHIstory(orderId);
  }, [orderId]);

  useEffect(() => {
    if (activeIndex === 1) {
      loadImages();
    } else if (activeIndex === 2) {
      getOrderDocuments();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(0);
      setOrderImages([]);
      setOrderProcessData(null);
      setCarriers([]);
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        className="order-monitor-details"
        header={
          <LanguageProvider id="dcp.b2b.monitor.order.details.dialog.title" />
        }
        headerClassName="order-monitor-details-header"
        visible={isOpen}
        style={{ width: "50vw" }}
        onHide={onClose}
        footer={
          <div className="footer">
            <Button label="OK" onClick={onClose} />
          </div>
        }
      >
        <TabView
          className="tab-view-container"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel
            header={
              <div className="tab-header">
                <Icon icon="file-06" />
                <span className="tab-panel-title">
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.details.dialog.label.order"}
                  />
                </span>
              </div>
            }
          >
            <div className="order-tab-container">
              <div className="order-left-side-wrapper">
                <div className="info-grid">
                  {!loadingSettingsFlux && !loading ? (
                    <>
                      <div className="info-grid-item">
                        <label className="o">
                          <LanguageProvider
                            id={
                              "dcp.b2b.monitor.order.details.dialog.label.order"
                            }
                          />
                        </label>
                        <span>{orderProcessData?.orderId}</span>
                      </div>
                      <div className="info-grid-item">
                        <label htmlFor="updateDate">
                          <LanguageProvider
                            id={
                              "dcp.b2b.monitor.order.details.dialog.label.update"
                            }
                          />
                        </label>
                        <span itemType="date">
                          {new Date(orderProcessData?.lastUpdated)
                            .toLocaleString()
                            .replace(",", "")}{" "}
                        </span>
                      </div>
                      <div className="info-grid-item">
                        <label htmlFor="status">
                          <LanguageProvider
                            id={
                              "dcp.b2b.monitor.order.details.dialog.label.status"
                            }
                          />
                        </label>
                        <div className="status-wrapper">
                          <OrderStatusField
                            orderStatus={orderProcessData?.status}
                          />
                          <Button
                            className="p-button-outlined edit-status-button"
                            icon={
                              <Icon
                                icon="edit-05"
                                className="icon-status-button"
                              />
                            }
                            tooltip={LanguageProvider({
                              id: "gen.edit.button",
                            })}
                            type="button"
                            onClick={() => {
                              setModalUpdateStatusVisible(true);
                            }}
                          />
                        </div>
                      </div>
                      {modulesAdminDeliveryFlux && (
                        <>
                          <div className="info-grid-item">
                            <label htmlFor="city">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.city"
                                }
                              />
                            </label>
                            <div className="city-field-wrapper">
                              <InputText
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                onKeyDown={(e) =>
                                  onKeyDownUpdateFieldOrder(e, "city", city)
                                }
                                disabled={false}
                              />
                            </div>
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="carrier">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.carrier"
                                }
                              />
                            </label>
                            <Dropdown
                              key={selectedCarrier?.id_database_item}
                              className="carrier-dropdown"
                              value={
                                selectedCarrier &&
                                selectedCarrier?.id_database_item
                              }
                              onChange={handleChangeCarrier}
                              loading={loadingCarrier}
                              options={carriers}
                              optionValue="id_database_item"
                              optionLabel="name"
                              disabled={checkStatusToDisable(
                                orderProcessData?.status
                              )}
                              placeholder={
                                loadingCarrier || carriers?.length > 0 ? (
                                  ""
                                ) : (
                                  <LanguageProvider
                                    id={
                                      "dcp.b2b.order.monitor.detail.dialog.carrier.not.found"
                                    }
                                  />
                                )
                              }
                            />
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="trackNumber">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.track.number"
                                }
                              />
                            </label>
                            <div className="track-number-field-wrapper">
                              <a href={orderProcessData?.url} target="_blank">
                                {orderProcessData?.trackNumber ? (
                                  <span
                                    className="track-number-link"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    {orderProcessData?.trackNumber}
                                  </span>
                                ) : (
                                  <span>
                                    <LanguageProvider
                                      id={
                                        "dcp.b2b.monitor.order.details.dialog.label.track.number.empty"
                                      }
                                    />
                                  </span>
                                )}
                              </a>
                            </div>
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="customerId">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.customer.id"
                                }
                              />
                            </label>
                            <div className="customer-id-field-wrapper">
                              <span className="customer-id-link">
                                <InputText
                                  value={customerId}
                                  onChange={(e) =>
                                    setCustomerId(e.target.value)
                                  }
                                  onKeyDown={(e) =>
                                    onKeyDownUpdateFieldOrder(
                                      e,
                                      "customerId",
                                      customerId
                                    )
                                  }
                                  disabled={false}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="cityCode">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.city.code"
                                }
                              />
                            </label>
                            <div className="city-code-field-wrapper">
                              <span className="city-code-link">
                                <InputText
                                  value={cityCode}
                                  onChange={(e) => setCityCode(e.target.value)}
                                  onKeyDown={(e) =>
                                    onKeyDownUpdateFieldOrder(
                                      e,
                                      "cityCode",
                                      cityCode
                                    )
                                  }
                                  disabled={false}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="deliveryDocumentType">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.delivery.document.type"
                                }
                              />
                            </label>
                            <div className="delivery-document-type-field-wrapper">
                              <span className="delivery-document-type-link">
                                <InputText
                                  value={deliveryDocType}
                                  onChange={(e) =>
                                    setDeliveryDocType(e.target.value)
                                  }
                                  onKeyDown={(e) =>
                                    onKeyDownUpdateFieldOrder(
                                      e,
                                      "deliveryDocumentType",
                                      deliveryDocType
                                    )
                                  }
                                  disabled={false}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="totalCost">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.total.cost"
                                }
                              />
                            </label>
                            <div className="total-cost-field-wrapper">
                              <span className="total-cost-type-link">
                                <InputText
                                  value={totalCost}
                                  onChange={(e) => setTotalCost(e.target.value)}
                                  onKeyDown={(e) =>
                                    onKeyDownUpdateFieldOrder(
                                      e,
                                      "totalCost",
                                      totalCost
                                    )
                                  }
                                  disabled={false}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="info-grid-item">
                            <label htmlFor="qtyItens">
                              <LanguageProvider
                                id={
                                  "dcp.b2b.monitor.order.details.dialog.label.volume"
                                }
                              />
                            </label>
                            <div className="qty-items-field-wrapper">
                              <span className="qty-items-type-link">
                                <InputText
                                  value={qtyItems}
                                  onChange={(e) => setQtyItems(e.target.value)}
                                  onKeyDown={(e) =>
                                    onKeyDownUpdateFieldOrder(
                                      e,
                                      "qtyItems",
                                      qtyItems
                                    )
                                  }
                                  disabled={false}
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="skeleton row">
                      <div className="skeleton-info-grid-item">
                        {Array.from({ length: 6 }).map((_, index) => (
                          <div className="skeleton-item-wrapper" key={index}>
                            <Skeleton
                              height="14px"
                              width="100px"
                              style={{
                                marginBottom: "4px",
                                backgroundColor: "var(--textPrimary)",
                              }}
                            />
                            <Skeleton
                              height="16px"
                              width="200px"
                              style={{
                                backgroundColor: "var(--textPrimary)",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {!loading ? (
                  <div className="log-process-wrapper">
                    <label>Log</label>
                    <div className="span-logs">
                      {orderProcessData?.logProcess}
                    </div>
                  </div>
                ) : (
                  <div className="skeleton row">
                    <div className="skeleton-log-process-wrapper">
                      <Skeleton
                        height="14px"
                        width="100px"
                        style={{
                          marginBottom: "4px",
                        }}
                      />
                      <Skeleton
                        height="100%"
                        style={{
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="timeline-container">
                <OrderMonitorTimeline
                  orderStatusHistoryData={orderProcessStatusHistoryData}
                  modulesAdminParamIsLabel={modulesAdminDeliveryFlux}
                />
              </div>
            </div>
          </TabPanel>
          {!loadingSettingsFlux && modulesAdminDeliveryFlux && (
            <TabPanel
              header={
                <div className="tab-header">
                  <Icon icon="camera-02" />
                  <span className="tab-panel-title">POD</span>
                </div>
              }
            >
              <div className="monitor-pictures-view">
                {loading ? (
                  <div className="loading">
                    <div className="skeleton row">
                      {Array.from({ length: orderImageCount }).map(
                        (_, index) => (
                          <Skeleton
                            key={index}
                            height="170px"
                            width="160px"
                          ></Skeleton>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="images-container">
                      {orderImages?.length > 0 &&
                        orderImages.map((image, index) => {
                          return (
                            <div
                              key={"order-picture" + index}
                              className="order-wrapper"
                            >
                              <Menu
                                model={menuItems}
                                popup
                                ref={menuRef}
                                className="image-menu"
                              />
                              <div className="images row">
                                <div
                                  key={"image-" + index}
                                  className="image-wrapper"
                                >
                                  <div className="image-header">
                                    <div className={"filename-wrapper"}>
                                      <Icon
                                        icon="image-01"
                                        size={20}
                                        className={"img-icon"}
                                      />
                                    </div>
                                    <div
                                      className="icon"
                                      onClick={(e) => {
                                        setSelectedImage(image);
                                        menuRef.current.toggle(e);
                                      }}
                                    >
                                      <Icon
                                        icon="dots-vertical"
                                        size={20}
                                        color="#98A2B3"
                                      ></Icon>
                                    </div>
                                  </div>
                                  <DcpCustomZoomImage
                                    src={image.path}
                                    alt="Image"
                                    className="images-preview-thumbnail"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {orderImages?.length === 0 && (
                      <div className="no-images">
                        <img src={emptyInbox} width={300} height={250} />
                        <span>
                          <LanguageProvider id="gen.no.image" />
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </TabPanel>
          )}
          {!loadingSettingsFlux && modulesAdminDeliveryFlux && (
            <TabPanel
              header={
                <div className="tab-header">
                  <Icon icon="paperclip" />
                  <span className="tab-panel-title">
                    <LanguageProvider id="gen.documents" />
                  </span>
                </div>
              }
            >
              <div className="monitor-documents-view">
                <DataTable
                  value={orderDocuments}
                  removableSort
                  rows={10}
                  loading={loading}
                >
                  <Column
                    header={<LanguageProvider id={"gen.document.name"} />}
                    field="documentName"
                    filter
                    filterPlaceholder={LanguageProvider({
                      id: `${LanguageProvider({
                        id: "gen.filter.by",
                      })} ${LanguageProvider({
                        id: "gen.document.name",
                      })}`,
                    })}
                    sortable
                  />

                  <Column
                    header={<LanguageProvider id={"gen.generated.date"} />}
                    filter
                    body={(rowData) => (
                      <span>
                        {rowData.generatedDate
                          ? new Date(rowData.generatedDate).toLocaleString()
                          : ""}
                      </span>
                    )}
                    sortable
                  />

                  <Column
                    header={<LanguageProvider id={"gen.print.date"} />}
                    filter
                    body={(rowData) => (
                      <span>
                        {rowData.printDate &&
                        new Date(rowData.printDate).toLocaleString() !==
                          "01/01/1, 00:00:00"
                          ? new Date(rowData.printDate).toLocaleString()
                          : ""}
                      </span>
                    )}
                    sortable
                  />

                  <Column
                    header={<LanguageProvider id={"gen.print.user"} />}
                    field="printUser"
                    filter
                    filterPlaceholder={LanguageProvider({
                      id: `${LanguageProvider({
                        id: "gen.filter.by",
                      })} ${LanguageProvider({ id: "gen.print.user" })}`,
                    })}
                    sortable
                  />

                  <Column
                    headerStyle={{ width: "10%", minWidth: "100px" }}
                    body={(rowData) => {
                      return (
                        <div className="column-wrapper">
                          <div className="column-actions">
                            <div
                              className="icon-wrapper"
                              onClick={() => {
                                downloadOrderDocument(rowData);
                              }}
                            >
                              <Icon
                                icon="file-download-02"
                                size={20}
                                color="#667085"
                                className="icon-row"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </DataTable>
              </div>
            </TabPanel>
          )}
          {orderProcessData?.status === OrderMonitorStatus.errorReport && (
            <TabPanel
              headerClassName="tab-report-track-number-header"
              headerTemplate={
                <Button
                  onClick={() => handleReprocessReport(orderId)}
                  label={
                    <LanguageProvider id="dcp.b2b.monitor.order.details.dialog.label.button.track.number" />
                  }
                  loading={loading}
                />
              }
            ></TabPanel>
          )}
          {orderProcessData?.status ===
            OrderMonitorStatus.errorCarrierIntegration && (
            <TabPanel
              headerClassName="tab-report-track-number-header"
              headerTemplate={
                <Button
                  onClick={() => handleReprocessCarrierIntegration(orderId)}
                  label={
                    <LanguageProvider id="dcp.b2b.monitor.order.details.dialog.label.button.reprocess.carrier.reintegration" />
                  }
                  loading={loading}
                />
              }
            ></TabPanel>
          )}
        </TabView>
        <ModalConfirmation
          isOpen={modalConfirmationVisible}
          onCancel={() => setModalConfirmationVisible(false)}
          onConfirm={handleConfirmResponse}
          modalTitle={modalTitle}
          bodyMessage={modalBodyMessage}
          isDelete={isDelete}
          loading={loading}
        />

        <ModalUpdateOrderStatus
          orderId={orderId}
          orderNewStatusTemp={orderProcessData?.status}
          isOpen={modalUpdateStatusVisible}
          onClose={() => setModalUpdateStatusVisible(false)}
          onConfirm={getOrderProcess}
          modulesAdminDeliveryFlux={modulesAdminDeliveryFlux}
        />
      </Dialog>
    </>
  );
};

export default ModalOrderMonitorDetails;
