enum OrderMonitorStatus {
  inconsistentIntegration = -1,
  createdOrder = 0,
  carrierDefinition = 10,
  carrierError = 11,
  pendingPacking = 20,
  pendingIntegrationCarrier = 30,
  errorCarrierIntegration = 31,
  reprocessCarrierIntegration = 32,
  pendingReport = 40,
  errorReport = 41,
  reprocessingReport = 42,
  pendingPrint = 50,
  pendingShipping = 60,
  finished = 100,
}

export default OrderMonitorStatus;
