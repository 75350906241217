import "./audit-count-dialog.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThemeContext } from "~/app";
import AreaAuditCountAddSkuDialog from "./area-audit-count-add-sku-dialog";
import { InventoryContext } from "../inventory-details";
import { GetLocationCount } from "~/services/api";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useToastContext } from "~/context/ToastContext";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import imgEmptyForm from "~/theme/media/assets/emptyInbox.svg";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import HomeProduct from "~/shared/components/home-product";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { Button } from "~/shared/components/dcp-button";
import { DcpInlineZoomImage } from "~/shared/components/dcp-zoom-image-inline";

export function AuditCountDialog({
  isOpen,
  onClose,
  area,
  locations,
  selectedLocation,
}) {
  const { showToast } = useToastContext();
  const { currentLanguage } = useLanguageContext();
  const { currentTheme } = useContext(ThemeContext);
  const dcpAxiosService = useDcpAxiosService();
  const { inventory } = useContext(InventoryContext);
  const [blockAuditActions, setBlockAuditActions] = useState(false);
  const [locationImageLoading, setLocationImageLoading] = useState(false);
  const [isEditRead, setIsEditRead] = useState(false);
  const [refreshReadList, setRefreshReadList] = useState(false);
  const [InventoryCountId, setInventoryCountId] = useState(0);
  const [skuReadIdDelete, setSkuReadIdDelete] = useState<number>(0);
  const [skuReadToEdit, setSkuReadToEdit] = useState();
  const [isSkuTableLoading, setIsSkuTableLoading] = useState(false);
  const [addAuditSkuDialogVisible, setAddAuditSkuDialogVisible] =
    useState(false);
  const locationsFormattedRaw =
    locations?.map((location) => ({
      label: location.location.name,
      value: location.location.id,
    })) || [];
  const locationsFormatted =
    locationsFormattedRaw.length > 0 &&
    locationsFormattedRaw.sort((a, b) => a.label.localeCompare(b.label));
  const [counts, setCounts] = useState([]);
  var initialLocationCounts = [];
  const [clientAdditionalInfoProperties, setClientAdditionalInfoProperties] =
    useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [userSelected, setUserSelected] = useState("");
  const [areaSelected, setAreaSelected] = useState();
  const [locationSelected, setLocationSelected] = useState();
  const [locationStatusSelected, setLocationStatusSelected] = useState();
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);
  const [inventoryCountLocationSelected, setInventoryCountLocationSelected] =
    useState();
  const [inventoryCreatedAt, setInventoryCreatedAt] = useState();
  const [inventoryCode, setInventoryCode] = useState();
  const [locationSelectedLabel, setLocationSelectedLabel] = useState();
  const [locationSelectedPalletLabel, setLocationSelectedPalletLabel] =
    useState();
  const [imageLocationURL, setImageLocationURL] = useState("");
  const [locationImageName, setLocationImageName] = useState("");
  const [imageLocationContentValue, setImageLocationContentValue] =
    useState("");
  const [openConfirmationDeleteDialog, setOpenConfirmationDeleteDialog] =
    useState(false);
  const zoomRef = useRef<any>(null);

  const CloseModal = () => {
    if (onClose) {
      onClose();
    }
  };
  const handleDeleteSkuRead = async () => {
    setOpenConfirmationDeleteDialog(false);
    try {
      const { data: skuReadDataResponse } = await dcpAxiosService.delete(
        `${settings.Urls.Rest.Inventory}/sku-read-delete?id=${skuReadIdDelete}`,
        "Inventory"
      );
      if (skuReadDataResponse.status) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "gen.message.inventory.sku.read.deleted",
            currentLanguage,
          }),
        });
        setRefreshReadList(!refreshReadList);
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: "gen.error",
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshReadList(!refreshReadList);
    }
  };
  const getUserLocationImage = async () => {
    try {
      setLocationImageLoading(true);
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Inventory}/location-images`,
        "Inventory",
        {
          params: {
            idInvetoryLocation:
              inventoryCountLocationSelected ?? locations[0].id,
          },
        }
      );
      if (status === 200 && data.data) {
        setImageLocationURL(
          data.data[0].images.length <= 0 ? "" : data.data[0].images[0].url
        );
        setLocationImageName(
          data.data[0].images.length <= 0
            ? ""
            : data.data[0].images[0].inventoryImage.originalFileName
        );
        setImageLocationContentValue(
          data.data[0].images.length <= 0
            ? ""
            : data.data[0].images[0].inventoryImage.content
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocationImageLoading(false);
    }
  };
  const getUserAdditionalInfo = async (inventoryLocationUserResultIds) => {
    if (
      inventoryLocationUserResultIds &&
      inventoryLocationUserResultIds.length > 0
    ) {
      try {
        const allAdditionalInfo = [];
        for (const id of inventoryLocationUserResultIds) {
          const { data, status } = await dcpAxiosService.get(
            `${settings.Urls.Rest.Inventory}/get-location-additional-info`,
            "Inventory",
            {
              params: { idLocatioUserResult: id },
            }
          );
          if (status === 200 && data.data) {
            allAdditionalInfo.push(...data.data);
          }
        }
        setClientAdditionalInfoProperties(allAdditionalInfo);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handlePreviousLocation = async () => {
    if (
      locationsFormatted !== null &&
      locationsFormatted.length > 0 &&
      !isSkuTableLoading
    ) {
      const currentIndex = locationsFormatted.findIndex(
        (location) => location.value === locationSelected
      );
      if (currentIndex > 0) {
        const previousLocation = locationsFormatted[currentIndex - 1];
        const inventoryCountLocationSelected = locations.find(
          (location) => location.location.id === previousLocation.value
        );
        setInventoryCountLocationSelected(inventoryCountLocationSelected.id);
        setLocationSelected(previousLocation.value);
        setLocationStatusSelected(
          inventoryCountLocationSelected.locationStatus
        );
        setLocationSelectedLabel(previousLocation.label);
        setLocationSelectedPalletLabel(
          inventoryCountLocationSelected.palletLabel
        );
        setSelectedLocationIndex(currentIndex - 1);
      }
    }
  };
  const handleNextLocation = () => {
    if (
      locationsFormatted !== null &&
      locationsFormatted.length > 0 &&
      !isSkuTableLoading
    ) {
      const currentIndex = locationsFormatted.findIndex(
        (location) => location.value === locationSelected
      );
      if (currentIndex < locationsFormatted.length - 1) {
        const nextLocation = locationsFormatted[currentIndex + 1];
        const inventoryCountLocationSelected = locations.find(
          (location) => location.location.id === nextLocation.value
        );
        setInventoryCountLocationSelected(inventoryCountLocationSelected.id);
        setLocationSelected(nextLocation.value);
        setLocationStatusSelected(
          inventoryCountLocationSelected.locationStatus
        );
        setLocationSelectedLabel(nextLocation.label);
        setLocationSelectedPalletLabel(
          inventoryCountLocationSelected.palletLabel
        );
        setSelectedLocationIndex(currentIndex + 1);
      }
    }
  };
  const getCounts = async (idLocation) => {
    try {
      setIsSkuTableLoading(true);
      const countsLocationUserResponse = await GetLocationCount(idLocation);
      const uniqueUserId = new Set();
      const userOptionsToCounts = await countsLocationUserResponse.flatMap(
        (count) =>
          count.counts
            .filter((user) => {
              if (uniqueUserId.has(user.userId)) {
                return false;
              } else {
                uniqueUserId.add(user.userId);
                return true;
              }
            })
            .map((user) => ({
              label: user.userName,
              value: user.userId,
            }))
      );
      setUsersOptions(userOptionsToCounts);
      var userSelectedToFilterReads =
        (await userSelected) === "" || userSelected === null
          ? userOptionsToCounts[0]?.value
          : userSelected;

      const isCountsLoaded = true;
      if (isCountsLoaded) {
        const countsFormatted = countsLocationUserResponse.flatMap((count) => {
          return count.counts
            .filter(
              (user) =>
                user.userId === userSelectedToFilterReads && user.hasCount
            )
            .map((item) => ({
              id: item.inventoryCountId,
              idLocationUser: item.inventoryCountId,
              quantity: item.count,
              sku: count.sku,
              hasCount: item.hasCount,
              systemAmount: count.result.systemAmount,
            }));
        });
        const inventoryLocationUserResultIds = countsFormatted?.map(
          (item) => item.id
        );
        await getUserAdditionalInfo(inventoryLocationUserResultIds);

        if (countsFormatted && countsFormatted.length > 0) {
          setCounts(countsFormatted);
          initialLocationCounts.push(countsFormatted);
        }
      }
      setInventoryCountId(countsLocationUserResponse[0].idLocationUser);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSkuTableLoading(false);
    }
  };

  function locationStatus(statusOption) {
    switch (statusOption) {
      case 1:
        return (
          <span className="location -audited">
            <Icon icon="edit-05" color="var(--blue-600)" size={20} />
            <p>
              <LanguageProvider id={"inventory.audited"} />
            </p>
          </span>
        );
        break;
      case 2:
        return (
          <span className="location -matches">
            <Icon icon="check-circle" color="var(--green-500)" size={20} />
            <p>
              <LanguageProvider id={"inventory.matches"} />
            </p>
          </span>
        );
        break;
      case 3:
        return (
          <span className="location -divergent">
            <Icon icon="x-circle" color="var(--red-500)" size={20} />
            <p>
              <LanguageProvider id={"inventory.divergent"} />
            </p>
          </span>
        );
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (inventoryCountLocationSelected) {
      getCounts(inventoryCountLocationSelected);
    }
    getUserLocationImage();
  }, [inventoryCountLocationSelected, refreshReadList, userSelected]);

  useEffect(() => {
    if (isOpen && locations && locations.length > 0) {
      if (locations && locations.length > 0) {
        console.log(selectedLocation);

        var location = selectedLocation ?? locations[0];

        setInventoryCreatedAt(location.inventory?.createdAt);
        setInventoryCode(location.inventory.code);
        setAreaSelected(area.id);
        setLocationSelected(
          selectedLocation ? selectedLocation.location.id : location.location.id
        );
        setLocationStatusSelected(location.locationStatus);
        setInventoryCountLocationSelected(location.id);
        setLocationSelectedLabel(location.location.name);
        setLocationSelectedPalletLabel(location.palletLabel);
        setBlockAuditActions(
          location.inventory.status === 4 || location.inventory.status === 5
            ? true
            : false
        );
      }
      getCounts(location.id);
      getUserLocationImage();
    }
  }, [isOpen, locations]);

  return (
    <>
      <Dialog
        visible={isOpen}
        onHide={CloseModal}
        className="dialog-audit-count-details"
        maximized={false}
      >
        <div className="barcode-read">
          <div className="barcode-read-content">
            <div className="barcode-details">
              <div className="return-option-wrapper">
                <div className="icon-return">
                  <Icon
                    icon="arrow-narrow-left"
                    color="#4146FF"
                    size={20}
                    onClick={CloseModal}
                  />
                </div>
                <span className="return-text-title">
                  <LanguageProvider id="gen.audit.count" />
                </span>
              </div>
              <div className="barcode-form-wrapper">
                <div className="dcp-form">
                  <div className="form-row">
                    <div className="input-container location-name">
                      <label className="identifier-location-label">
                        <LanguageProvider id={"product.drone.area"} />
                      </label>
                      <Dropdown
                        options={inventory?.areas || []}
                        value={areaSelected}
                        onChange={(e) => setAreaSelected(e.value)}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="form-row location-info">
                    <div className="input-container location-name">
                      <label className="identifier-location-label">
                        <LanguageProvider id="gen.location" />
                      </label>
                      <span className="content-location">
                        {locationSelectedLabel}
                      </span>
                    </div>
                    <div className="input-container location-status">
                      <label className="identifier-location-label">
                        <LanguageProvider id="gen.status" />
                      </label>
                      <span className="content-status">
                        {locationStatus(locationStatusSelected)}
                      </span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-container scan-date">
                      <label className="identifier-location-label">
                        <LanguageProvider id="gen.scanned" />
                      </label>
                      <span className="content-location">
                        {inventoryCreatedAt ? (
                          new Date(inventoryCreatedAt)
                            .toISOString()
                            .split("T")
                            .join(" ")
                            .split(".")[0]
                        ) : (
                          <LanguageProvider id="gen.no.registers" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-container location-name">
                      <label className="identifier-location-label">
                        <LanguageProvider id={"gen.inventory"} />
                      </label>
                      <span className="content-location">
                        {inventoryCode ?? (
                          <LanguageProvider id="gen.no.registers" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-container location-users-dropdown">
                      <label className="identifier-location-label">
                        <LanguageProvider id={"gen.user.name.form"} />
                      </label>
                      <Dropdown
                        options={usersOptions || []}
                        value={
                          userSelected === "" || userSelected === null
                            ? usersOptions[0]?.value
                            : userSelected
                        }
                        onChange={(e) => setUserSelected(e.value)}
                        optionLabel="label"
                        optionValue="value"
                        loading={isSkuTableLoading}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-container location-name">
                      <div className="header-title-section">
                        <span className="identifier-location-label">
                          <LanguageProvider id={"gen.reads"} />
                        </span>
                        <Icon
                          className={
                            !blockAuditActions
                              ? "icon-add-sku"
                              : "icon-add-sku-blocked"
                          }
                          color={currentTheme.primary}
                          size={20}
                          icon={"plus"}
                          onClick={() =>
                            !blockAuditActions &&
                            setAddAuditSkuDialogVisible(true)
                          }
                        />
                      </div>
                      <div className="content-body">
                        <div className="dcp-table">
                          <div className="itens-table">
                            <DataTable
                              value={(counts ?? initialLocationCounts) || []}
                              emptyMessage={LanguageProviderWithoutContext({
                                id: "gen.no.registers",
                                currentLanguage,
                              })}
                              loading={isSkuTableLoading || false}
                              loadingIcon={<LoadingIndicator />}
                            >
                              <Column
                                field="sku"
                                header={LanguageProviderWithoutContext({
                                  id: "gen.sku",
                                  currentLanguage,
                                })}
                                body={(rowData) => {
                                  return (
                                    <div className="sku-wrapper">
                                      <span>
                                        {rowData.sku === ""
                                          ? LanguageProvider({
                                              id: "gen.no.sku",
                                            })
                                          : rowData.sku}
                                      </span>
                                    </div>
                                  );
                                }}
                              />
                              <Column
                                field="systemAmount"
                                header={LanguageProviderWithoutContext({
                                  id: "inventory.system.qtd",
                                  currentLanguage,
                                })}
                                body={(rowData) => {
                                  return (
                                    <div className="sku-wrapper">
                                      <span>{rowData?.systemAmount}</span>
                                    </div>
                                  );
                                }}
                              />
                              <Column
                                field="result"
                                header={LanguageProviderWithoutContext({
                                  id: "gen.current.qtd.reduced",
                                  currentLanguage,
                                })}
                                body={(rowData) => {
                                  let count = "-";
                                  if (rowData.hasCount && rowData.quantity > 0)
                                    count = rowData.quantity;
                                  else if (
                                    rowData.hasCount &&
                                    rowData.quantity === 0
                                  )
                                    count = LanguageProvider({
                                      id: "gen.empty",
                                    });

                                  return (
                                    <div className="result-wrapper">
                                      <span>{count}</span>
                                    </div>
                                  );
                                }}
                              />
                              <Column
                                header=""
                                body={(rowData) => (
                                  <div className="icon-wrapper">
                                    <>
                                      <Icon
                                        icon="edit-02"
                                        size={20}
                                        color={currentTheme.tableIconColor}
                                        className={
                                          !blockAuditActions
                                            ? "icon-row-edit"
                                            : "icon-row-blocked"
                                        }
                                        onClick={() => {
                                          if (!blockAuditActions) {
                                            setIsEditRead(true);
                                            setAddAuditSkuDialogVisible(true);
                                            setSkuReadToEdit(rowData);
                                          }
                                        }}
                                      />
                                      <Icon
                                        icon="trash-02"
                                        size={20}
                                        color={currentTheme.tableIconColor}
                                        className={
                                          !blockAuditActions
                                            ? "icon-row-delete"
                                            : "icon-row-blocked"
                                        }
                                        onClick={() => {
                                          if (!blockAuditActions) {
                                            setOpenConfirmationDeleteDialog(
                                              true
                                            );
                                            setSkuReadIdDelete(rowData.id);
                                          }
                                        }}
                                      />
                                    </>
                                  </div>
                                )}
                              />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-container location-label">
                      <label className="identifier-location-label">
                        <LanguageProvider
                          id={"gen.audit.field.pallet.number"}
                        />
                      </label>
                      <InputText
                        disabled
                        className="content-location-label"
                        value={locationSelectedPalletLabel}
                      />
                    </div>
                  </div>
                  {clientAdditionalInfoProperties &&
                    clientAdditionalInfoProperties.length > 0 &&
                    (() => {
                      const seenProperties = new Set<string>();
                      return clientAdditionalInfoProperties
                        .filter((item) => {
                          const propertyName =
                            item.clientAdditionalInfoDefinition?.propertyName;
                          if (
                            propertyName &&
                            !seenProperties.has(propertyName)
                          ) {
                            seenProperties.add(propertyName);
                            return true;
                          }
                          return false;
                        })
                        .map((item, index) => (
                          <div className="form-row" key={index}>
                            <div className="input-container location-label">
                              <label className="identifier-location-label">
                                {
                                  item.clientAdditionalInfoDefinition
                                    ?.propertyName
                                }
                              </label>
                              <InputText
                                disabled
                                className="content-location-label"
                                value={
                                  item.value === ""
                                    ? item.clientAdditionalInfoDefinition
                                        ?.defaultValue
                                    : item.value
                                }
                              />
                            </div>
                          </div>
                        ));
                    })()}
                  <div className="form-row">
                    <div className="input-container location-text-area">
                      <label className="identifier-location-text-area">
                        <LanguageProvider id={"gen.audit.field.descriptive"} />
                      </label>
                      <InputTextarea
                        autoResize
                        disabled
                        value={imageLocationContentValue}
                        rows={5}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="barcode-image">
              <div className="location-image-header">
                <div className="location-image-header-buttons">
                  <Button
                    className="p-button-secondary"
                    onClick={() => zoomRef.current?.zoomIn()}
                  >
                    <Icon
                      icon={"zoom-in"}
                      color={currentTheme.tableIconColor}
                      size={20}
                    />
                  </Button>
                  <Button
                    className="p-button-secondary"
                    onClick={() => zoomRef.current?.zoomOut()}
                  >
                    <Icon
                      icon={"zoom-out"}
                      color={currentTheme.tableIconColor}
                      size={20}
                    />
                  </Button>
                  <Button
                    className="p-button-secondary"
                    onClick={() => zoomRef.current?.resetTransform()}
                  >
                    <Icon
                      icon={"expand-06"}
                      color={currentTheme.tableIconColor}
                      size={20}
                    />
                  </Button>
                </div>
                <div className="location-select-dropdown">
                  <div className="icon" onClick={handlePreviousLocation}>
                    {locationsFormatted.length > 0 && (
                      <Icon
                        color={
                          selectedLocationIndex <= 0
                            ? currentTheme.tableIconColorCountDoesNotExists
                            : currentTheme.tableIconColorCountExists
                        }
                        className={`right-setting${
                          selectedLocationIndex <= 0
                            ? "-does-not-exists"
                            : "-exists"
                        }`}
                        icon={"chevron-left"}
                        size={20}
                      />
                    )}
                  </div>
                  <Dropdown
                    className="location-name-dropdown"
                    options={locationsFormatted || []}
                    value={locationSelected}
                    filter
                    onChange={(e) => {
                      const selectedLocation = locationsFormatted.find(
                        (location) => location.value === e.value
                      );
                      const inventoryCountLocationSelected = locations.find(
                        (location) => location.location.id === e.value
                      );
                      setInventoryCountLocationSelected(
                        inventoryCountLocationSelected.id
                      );
                      setLocationSelected(selectedLocation.value);
                      setLocationStatusSelected(
                        inventoryCountLocationSelected.locationStatus
                      );
                      setLocationSelectedLabel(selectedLocation.label);
                      setLocationSelectedPalletLabel(
                        inventoryCountLocationSelected.palletLabel
                      );
                      const currentIndex = locationsFormatted.findIndex(
                        (location) => location.value === e.value
                      );
                      setSelectedLocationIndex(currentIndex);
                    }}
                    optionLabel="label"
                    optionValue="value"
                  />
                  <div className="icon" onClick={handleNextLocation}>
                    {locationsFormatted.length > 0 && (
                      <Icon
                        className={`right-setting${
                          selectedLocationIndex ===
                          locationsFormatted.length - 1
                            ? "-does-not-exists"
                            : "-exists"
                        }`}
                        color={
                          selectedLocationIndex ===
                          locationsFormatted.length - 1
                            ? currentTheme.tableIconColorCountDoesNotExists
                            : currentTheme.tableIconColorCountExists
                        }
                        icon={"chevron-right"}
                        size={20}
                      />
                    )}
                  </div>
                </div>
                <span className="location-image-name">{locationImageName}</span>
              </div>
              {!locationImageLoading ? (
                <>
                  {locationSelected === 0 ? (
                    <div className="location-image-empty">
                      <HomeProduct
                        title={<LanguageProvider id={"gen.audits"} />}
                        bodyMessage={
                          <LanguageProvider
                            id={
                              "inventory.audit.count.none.message.description"
                            }
                          />
                        }
                        isVisible={true}
                        showButton={false}
                        image={imgEmptyForm}
                      />
                    </div>
                  ) : (
                    <>
                      {imageLocationURL && imageLocationURL !== "" ? (
                        <>
                          <DcpInlineZoomImage
                            ref={zoomRef}
                            className="location-image"
                            src={imageLocationURL}
                            alt="Location Image"
                            imageClassName="location-img"
                          />
                          <div className="paginator"></div>
                        </>
                      ) : (
                        <div className="location-image-empty">
                          <LanguageProvider id="gen.no.image" />
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <LoadingIndicator />
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <AreaAuditCountAddSkuDialog
        isOpen={addAuditSkuDialogVisible}
        onClose={() => {
          setAddAuditSkuDialogVisible(false);
          setIsEditRead(false);
        }}
        refreshList={() => {
          setRefreshReadList(!refreshReadList);
        }}
        idLocationUser={InventoryCountId}
        isEdit={isEditRead}
        skuReadToEdit={skuReadToEdit}
      />
      <ModalConfirmation
        isDelete={true}
        modalTitle={
          <LanguageProvider
            id={"gen.message.inventory.sku.read.confirmation"}
          />
        }
        bodyMessage={
          <LanguageProvider
            id={"gen.message.inventory.sku.read.confirmation.description"}
          />
        }
        isOpen={openConfirmationDeleteDialog}
        onCancel={() => setOpenConfirmationDeleteDialog(false)}
        onConfirm={handleDeleteSkuRead}
      />
    </>
  );
}
