import React from "react";
import "../scss/order-monitor-timeline.scss";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";

export const OrderMonitorTimeline = ({
  orderStatusHistoryData,
  modulesAdminParamIsLabel,
}) => {
  let statusStepsOriginal = [
    {
      status: OrderMonitorStatus.createdOrder,
      label: (
        <LanguageProvider
          id={
            "dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.created.order"
          }
        />
      ),
    },
    {
      status: OrderMonitorStatus.carrierDefinition,
      label: (
        <LanguageProvider
          id={
            "dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.carrier.definition"
          }
        />
      ),
    },
    {
      status: OrderMonitorStatus.pendingPacking,
      label: (
        <LanguageProvider
          id={"dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.packing"}
        />
      ),
    },
    {
      status: OrderMonitorStatus.pendingIntegrationCarrier,
      label: (
        <LanguageProvider
          id={"dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.defined"}
        />
      ),
    },
    {
      status: OrderMonitorStatus.pendingReport,
      label: (
        <LanguageProvider
          id={"dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.label"}
        />
      ),
    },
    {
      status: OrderMonitorStatus.finished,
      label: (
        <LanguageProvider
          id={"dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.finished"}
        />
      ),
    },
  ];
  const statusSteps = statusStepsOriginal.filter((step) => {
    if (!modulesAdminParamIsLabel) {
      return ![
        OrderMonitorStatus.carrierDefinition,
        OrderMonitorStatus.pendingPacking,
        OrderMonitorStatus.pendingIntegrationCarrier,
      ].includes(step.status);
    }
    return true;
  });

  return (
    <>
      <h3 className="timeline-title">
        <LanguageProvider
          id={"dcp.b2b.dcp.b2b.monitor.order.details.dialog.timeline.title"}
        />
      </h3>
      <ul className="timeline">
        {statusSteps.map((step, index) => {
          const historyItem = orderStatusHistoryData.find(
            (item) => item.status === step.status
          ); // Show desc. always

          const hasError = orderStatusHistoryData.some(
            (item) => item.status === step.status + 1
          );

          return (
            <div key={step.status}>
              <li className="timeline-item">
                <div className="timeline-icon">
                  {historyItem ? (
                    hasError ? (
                      <div className="timeline-error-step">
                        <Icon icon="x-close" color="#fff" />
                      </div>
                    ) : (
                      <div
                        className="timeline-step-done"
                        style={{ backgroundColor: "#04AA77" }}
                      >
                        <Icon icon={"check"} color={"#ffff"} size={30} />
                      </div>
                    )
                  ) : (
                    <div className="timeline-blank-step">
                      <span className="timeline-step-number">{index + 1}</span>
                    </div>
                  )}
                </div>
                <div className="timeline-content">
                  <strong>{step.label}</strong>
                  <p>
                    {historyItem ? (
                      new Date(historyItem.date).toLocaleString()
                    ) : (
                      <LanguageProvider id={"gen.no.date"} />
                    )}
                  </p>
                  {historyItem && (
                    <span className="description">
                      {historyItem.description}
                    </span>
                  )}
                </div>
              </li>
              {index < statusSteps.length - 1 && (
                <div className="timeline-separator" />
              )}
            </div>
          );
        })}
      </ul>
    </>
  );
};
