import "./scss/area-map-picking-count.scss";
import React, { useContext, useEffect, useState } from "react";
import settings from "~/services/settings.json";
import {
  CheckAIHeatMapService,
  getRackLocationsPickingCount,
  listAreaGroups,
  listAreaMaps,
} from "~/services/api";
import { AreaListModel, HeatMapModel } from "~/shared/interfaces";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import {
  Item,
  WarehouseMap,
} from "../../../inventory/area-map/components/warehouse-map";
import { listMapItems } from "~/services/api/inventory/area";
import { generateUniqueKey } from "~/shared/helpers/generator";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { Tooltip } from "primereact/tooltip";
import { ThemeContext } from "~/app";
import { Dropdown } from "primereact/dropdown";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { Button } from "primereact/button";
import AiAnalytics from "~/shared/components/ai-analytics";
import { InputSwitch } from "primereact/inputswitch";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function AreaMapPickingCount() {
  const dcpAxiosService = useDcpAxiosService();
  const { currentTheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [displayAiAnalyticsDialog, setDisplayAiAnalyticsDialog] =
    useState(false);
  const [aiAnalyticsDialogOpenSource, setAiAnalyticsDialogOpenSource] =
    useState("dashboard");
  const [areas, setAreas] = useState<AreaListModel[]>([]);
  const [areaGroups, setAreaGroups] = useState([]);
  const [areaItems, setAreaItems] = useState([]);
  const [locationsLastUpdate, setLocationsLastUpdate] =
    useState<HeatMapModel>();
  const [selectedAreaIndex, setSelectedAreaIndex] = useState<number>();
  const [dateRange, setDateRange] = useState<Nullable<(Date | null)[]>>(null);
  const [aiHeatMapService, setAiHeatMapService] = useState<any>(false);
  const [showFilterColumn, setShowFilterColumn] = useState<any>(false);
  const [warningDaysValueInSettings, setWarningDaysValueInSettings] =
    useState<number>(0);
  const [criticalDaysValueInSettings, setCriticalDaysValueInSettings] =
    useState<number>(0);
  const [areasTypeCountValues, setAreasTypeCountValues] = useState({
    total: 0,
    reliable: 0,
    lessReliable: 0,
    unreliable: 0,
    percentage: {
      default: 0,
      warning: 0,
      critical: 0,
    },
  });
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  async function LoadAIHeatMapService() {
    try {
      const { status, data } = await CheckAIHeatMapService();
      if (status) {
        setAiHeatMapService(data);
      } else return null;
    } catch (error) {
      console.error(error);
    }
  }

  async function loadItems(id?: number, groups?: any[]) {
    try {
      const data = await listMapItems(id ?? areas[selectedAreaIndex].id);
      if (!data) return;

      const items: Item[] = [];
      setAreaItems(items);

      for (const item of data) {
        items.push({
          ...item,
          i: "",
          group: {},
        });
      }

      let _groups = groups && groups.length > 0 ? groups : areaGroups;

      for (const item of items) {
        item.i = generateUniqueKey(items, "i");
        const group = _groups.find(
          (g) => g.areaGroup.id === item.area_group_id
        );
        if (group) item.group = group;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const loadSettings = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/list-settings`,
        "Platform",
        {
          params: { applicationClientId },
        }
      );
      if (status === 200) {
        const settingsResponse = data.data[0];
        setWarningDaysValueInSettings(
          settingsResponse.subgroups[0].settings.find(
            (setting) =>
              setting.propertyName === "location_old_reading_warning_days"
          ).value
        );
        setCriticalDaysValueInSettings(
          settingsResponse.subgroups[0].settings.find(
            (setting) =>
              setting.propertyName === "location_old_reading_critical_days"
          ).value
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  function handleAiAnalyticsDialogOpen() {
    setAiAnalyticsDialogOpenSource("dashboard");
    setDisplayAiAnalyticsDialog(true);
  }

  useEffect(() => {
    async function load() {
      let areas = await listAreaMaps();
      if (areas) {
        setAreas(areas);
        setSelectedAreaIndex(0);
      }
    }
    LoadAIHeatMapService();
    load();
    loadSettings();
  }, []);

  useEffect(() => {
    async function load() {
      setLoading(true);
      let areaId = areas[selectedAreaIndex]?.id;
      let groups = await listAreaGroups(areaId);
      let startDate = new Date();
      let endDate = new Date();

      if (dateRange && dateRange[0] && dateRange[1]) {
        startDate = dateRange ? dateRange[0] : null;
        endDate = dateRange ? dateRange[1] : null;
      }

      setAreaGroups(groups);
      const areaHistory = await getRackLocationsPickingCount(
        areaId,
        startDate,
        endDate,
        showFilterColumn
      );
      // Count types of area based on ColorHex

      var counts = areaHistory?.counts ?? [];
      const total = counts.length;

      const reliable = counts.filter((item) => Number(item.color) === 0).length;
      const lessReliable = counts.filter(
        (item) => Number(item.color) === 1
      ).length;
      const unreliable = counts.filter(
        (item) => Number(item.color) === 2
      ).length;

      setAreasTypeCountValues({
        total,
        reliable,
        lessReliable,
        unreliable,
        percentage: {
          default: (reliable / total) * 100 || 0,
          warning: (lessReliable / total) * 100 || 0,
          critical: (unreliable / total) * 100 || 0,
        },
      });
      setLocationsLastUpdate(areaHistory);
      await loadItems(areaId, groups);
      setLoading(false);
    }
    load();
  }, [areas, selectedAreaIndex, dateRange, showFilterColumn]);

  return (
    <div className="area-map-picking-count">
      <div className="area-map-picking-count-wrapper">
        <div className="header">
          <div className="area-configuration">
            <div className="area-selection">
              <div className="selector">
                <div
                  className="icon"
                  onClick={() => {
                    if (
                      selectedAreaIndex != null &&
                      selectedAreaIndex > 0 &&
                      !loading
                    ) {
                      setSelectedAreaIndex(selectedAreaIndex - 1);
                    }
                  }}
                >
                  <Icon
                    icon="chevron-left"
                    size={24}
                    color={
                      selectedAreaIndex != null && selectedAreaIndex > 0
                        ? currentTheme.tableIconColorCountExists
                        : currentTheme.tableIconColorCountDoesNotExists
                    }
                    className={`right-setting${
                      selectedAreaIndex != null &&
                      selectedAreaIndex > 0 &&
                      !loading
                        ? "-exists"
                        : "-does-not-exists"
                    }`}
                  />
                </div>
                <Dropdown
                  value={selectedAreaIndex}
                  options={areas.map((area, index) => ({
                    label: area.name,
                    value: index,
                  }))}
                  loading={loading}
                  onChange={(e) => setSelectedAreaIndex(e.value)}
                  placeholder={LanguageProvider({
                    id: "inventory.map.area.dropdown.placeholder.select",
                  })}
                />
                <div
                  className="icon"
                  onClick={() => {
                    if (
                      selectedAreaIndex != null &&
                      selectedAreaIndex < areas.length - 1 &&
                      !loading
                    ) {
                      setSelectedAreaIndex(selectedAreaIndex + 1);
                    }
                  }}
                >
                  <Icon
                    icon="chevron-right"
                    size={24}
                    color={
                      selectedAreaIndex != null &&
                      selectedAreaIndex < areas.length - 1
                        ? currentTheme.tableIconColorCountExists
                        : currentTheme.tableIconColorCountDoesNotExists
                    }
                    className={`right-setting${
                      selectedAreaIndex != null &&
                      selectedAreaIndex < areas.length - 1 &&
                      !loading
                        ? "-exists"
                        : "-does-not-exists"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="area-settings">
              {aiHeatMapService && (
                <div className="ai-button-wrapper">
                  <Button
                    className="btn-ia-analytic"
                    onClick={handleAiAnalyticsDialogOpen}
                    label={LanguageProvider({ id: "gen.ai" })}
                  >
                    <Icon
                      icon={"lightbulb-02"}
                      className="btn-ia-icon"
                      color={currentTheme.white}
                      size={20}
                    />
                  </Button>
                </div>
              )}
              <div className="column-filter-wrapper">
                <p className="column-filter-label">
                  {showFilterColumn ? (
                    <LanguageProvider
                      id={"dcp.inventory.heat.map.filter.movement.qty"}
                    />
                  ) : (
                    <LanguageProvider
                      id={"dcp.inventory.heat.map.filter.picking.qty"}
                    />
                  )}
                </p>
                <InputSwitch
                  checked={showFilterColumn}
                  onChange={(e) => {
                    setShowFilterColumn(e.value);
                  }}
                  className="column-filter-switch"
                />
              </div>
              <div className="date-range-wrapper">
                <p className="date-range-label">
                  <LanguageProvider
                    id={"inventory.map.area.date.range.placeholder"}
                  />
                </p>
                <Calendar
                  onChange={(e) => setDateRange(e.value)}
                  value={dateRange}
                  selectionMode="range"
                  readOnlyInput
                  className="date-range-input"
                />
              </div>
            </div>
          </div>
          <div className="area-map-sub-header">
            <div className="square-container">
              <Tooltip
                className="picking-count-reliability-tooltip-wrapper"
                target=".rack-tooltip"
                position="bottom"
              >
                <div className="rack-tooltip-content">
                  <span className="rack-tooltip-title">
                    <LanguageProvider
                      id={"area.map.picking.count.tooltip.title"}
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={"area.map.picking.color.tooltip.description"}
                    />
                  </span>
                  {locationsLastUpdate && (
                    <div className="area-piking-map">
                      <div className="line-item">
                        <div className="item-color color-1"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["28"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["28"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-2"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["27"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["27"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-3"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["26"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["26"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-4"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["25"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["25"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-5"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["24"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["24"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-6"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["23"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["23"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-7"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["22"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["22"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-8"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["21"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["21"][1]}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="item-color color-9"></div>
                        <div className="item-description">
                          {locationsLastUpdate.colorRanges["20"][0]} -{" "}
                          {locationsLastUpdate.colorRanges["20"][1]}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Tooltip>

              <div className="rack-tooltip">
                <Icon
                  className="rack-icon-reliability-help"
                  color={"gray"}
                  icon={"annotation-question"}
                  size={20}
                />{" "}
                <span className="rack-reliability-content">
                  <LanguageProvider
                    id={"area.map.pciking.count.tooltip.title"}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="map-container">
          {loading ? (
            <div className="map-container-loading">
              <LoadingIndicator />
            </div>
          ) : (
            <WarehouseMap
              isStatic={true}
              items={areaItems}
              exceptions={[]}
              rackGroupLocationHistory={locationsLastUpdate?.counts}
              onExceptionClick={() => {}}
              onUpdate={() => {}}
              onFlip={() => {}}
              onDelete={() => {}}
              onEdit={() => {}}
            />
          )}
        </div>
      </div>
      <AiAnalytics
        visible={displayAiAnalyticsDialog}
        onClose={() => setDisplayAiAnalyticsDialog(false)}
        data={null}
        disableChat={true}
        fakeFirstInteraction={true}
      />
    </div>
  );
}
