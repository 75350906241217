import "../scss/inventory-details-dialog.scss";
import { Dialog, DialogProps } from "primereact/dialog";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import React, { useEffect, useState } from "react";
import {
  getUsers,
  loadInventoryDetails,
  updateInventoryDetails,
} from "~/services/api";

import { Button } from "~/shared/components/dcp-button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Icon from "~/shared/components/icons";
import { InputTextarea } from "primereact/inputtextarea";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import settings from "~/services/settings.json";
import showToast from "~/shared/components/toast-custom";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useLanguageContext } from "~/context/LanguageContext";

type InventoryDetailsDialogProps = DialogProps & {
  inventoryId: number;
};

const InventoryDetailsDialog: React.FC<InventoryDetailsDialogProps> = ({
  ...props
}) => {
  const { currentLanguage } = useLanguageContext();
  const axiosService = useDcpAxiosService();

  const [loadingInventoryData, setLoadingInventoryData] = useState(false);
  const [loadingSavingInventoryData, setLoadingSavingInventoryData] =
    useState(false);
  const [inventory, setInventory] = useState(null);

  async function getInventoryDetails(inventoryId: number) {
    setLoadingInventoryData(true);
    try {
      const response = await loadInventoryDetails(inventoryId);
      if (response.status) {
        return response.data;
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: response.message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInventoryData(false);
    }
    return null;
  }
  const [equipments, setEquipments] = useState([]);
  const [users, setUsers] = useState([]);

  async function loadDrones() {
    try {
      const { data, status } = await axiosService.post(
        settings.Urls.Rest.Equipment + "/list/drone",
        {
          first: 0,
          rows: 999999999,
          page: 0,
          sortField: null,
          sortOrder: 1, // 1 or -1
          filters: [],
        },
        "Inventory"
      );
      if (status === 200) {
        return data.data.equipments;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      drone: false,
      manual: false,
      estimatedLocations: 0,
      equipments: [],
      users: [],
      createdAt: new Date(),
      programmedTo: new Date(),
      description: "",
      codes: [],
    },
    onSubmit: onSubmit,
    validate: validate,
  });

  async function onSubmit(values: any) {
    setLoadingSavingInventoryData(true);
    const inventoryDetails = {
      id: values.id,
      code: values.code,
      name: values.name,
      description: values.description,
      status: values.status,
      idApplicationClient: values.idApplicationClient,
      accuracy: values.accuracy,
      drone: values.drone,
      manual: values.manual,
      estimatedLocations: values.estimatedLocations,
      createdAt: values.createdAt,
      programmedTo: values.programmedTo,
      idCreatedBy: values.idCreatedBy,
      readType: values.readType,
      restrictionType: values.restrictionType,
      doneLocations: values.doneLocations,
      codes: values.codes || [],
      startDate: values.startDate,
      users: values.users.map((user: any) =>
        typeof user === "string" ? user : user.userId
      ),
      equipments: values.equipments.map((equipment: any) =>
        typeof equipment === "number" ? equipment : equipment.id
      ),
    };
    try {
      const response = await updateInventoryDetails(inventoryDetails);
      if (response.status && response.data) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "inventory.details.updated.success",
            currentLanguage,
          }),
        });
        props.onHide();
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: response.message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSavingInventoryData(false);
    }
  }

  async function validate(values: any) {}

  useEffect(() => {
    if (props.visible) {
      async function initialize() {
        try {
          const [inventory, drones, users] = await Promise.all([
            getInventoryDetails(props.inventoryId),
            loadDrones(),
            getUsers(),
          ]);

          setInventory(inventory);
          setUsers(users.data);
          setEquipments(drones);
          formik.setValues(inventory);
        } catch (error) {
          console.error("Error loading data:", error);
        }
      }

      initialize();
    }
  }, [props.visible]);

  return (
    <Dialog
      {...props}
      className={classNames({
        "inventory-details-dialog": true,
        [props.className]: true,
      })}
      resizable={false}
      header={() => {
        return (
          <div className="inventory-details-dialog-header">
            <span>
              <LanguageProvider id="gen.inventory" />:
            </span>
            <span>
              {inventory?.code} - {inventory?.name}
            </span>
          </div>
        );
      }}
      footer={() => {
        return (
          <>
            <Button
              className="p-button-text p-button-plain p-button-no-underline"
              appearance="secondary"
              icon={"pi pi-times"}
              iconPos="right"
              label={LanguageProvider({ id: "gen.cancel" })}
              onClick={() => {
                formik.resetForm();
                props.onHide();
              }}
            />
            <Button
              appearance="primary"
              icon={"pi pi-save"}
              iconPos="right"
              onClick={formik.submitForm}
              disabled={loadingSavingInventoryData}
              label={LanguageProvider({ id: "gen.save" })}
            />
          </>
        );
      }}
    >
      {loadingInventoryData ? (
        <LoadingIndicator />
      ) : (
        <div className="form-inventory-details-container">
          <Form>
            <FormRow>
              <InputContainer
                label={<LanguageProvider id="gen.code" />}
                required
              >
                <InputText value={formik.values?.code} disabled />
              </InputContainer>
              <InputContainer
                label={<LanguageProvider id="gen.name" />}
                required
              >
                <InputText
                  value={formik.values?.name}
                  onChange={(e) => formik.setFieldValue("name", e.target.value)}
                />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer
                className="inventory-type-wrapper"
                label={<LanguageProvider id={"inventory.type"} />}
              >
                <FormRow>
                  <InputContainer label={"Drone"}></InputContainer>
                  <Checkbox
                    checked={formik.values?.drone}
                    onChange={(e) => formik.setFieldValue("drone", e.checked)}
                  />
                  <InputContainer label={"Manual"}></InputContainer>
                  <Checkbox
                    checked={formik.values?.manual}
                    onChange={(e) => formik.setFieldValue("manual", e.checked)}
                  />
                </FormRow>
              </InputContainer>
              <InputContainer
                label={<LanguageProvider id={"inventory.details.drones"} />}
              >
                <MultiSelect
                  options={equipments}
                  value={formik.values?.equipments}
                  onChange={(e) => formik.setFieldValue("equipments", e.value)}
                  maxSelectedLabels={2}
                  display="chip"
                  optionLabel="name"
                  optionValue="id"
                  filter
                  selectAll
                  disabled={!formik.values?.drone}
                />
              </InputContainer>
              <InputContainer label={<LanguageProvider id={"gen.users"} />}>
                <MultiSelect
                  value={formik.values?.users}
                  onChange={(e) => formik.setFieldValue("users", e.value)}
                  options={users}
                  disabled={!formik.values?.manual}
                  display="chip"
                  optionLabel="userName"
                  optionValue="userId"
                  filter
                  selectAll
                  maxSelectedLabels={3}
                />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer label={<LanguageProvider id="gen.created.at" />}>
                <Calendar value={new Date(formik.values?.createdAt)} disabled />
              </InputContainer>
              <InputContainer
                label={<LanguageProvider id="inventory.programmed.to.date" />}
              >
                <Calendar
                  value={new Date(formik.values?.programmedTo)}
                  onChange={(e) =>
                    formik.setFieldValue("programmedTo", e.target.value)
                  }
                />
              </InputContainer>
              <InputContainer
                label={
                  <LanguageProvider
                    id={"inventory.details.predicted.locations"}
                  />
                }
              >
                <InputText
                  value={formik.values?.estimatedLocations}
                  onChange={(e) =>
                    formik.setFieldValue("estimatedLocations", e.target.value)
                  }
                />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer label={<LanguageProvider id="gen.description" />}>
                <InputTextarea
                  value={formik.values?.description}
                  onChange={(e) =>
                    formik.setFieldValue("description", e.target.value)
                  }
                  rows={4}
                />
              </InputContainer>
            </FormRow>
            <DataTable
              className="dcp-table"
              value={formik.values?.codes}
              emptyMessage={<LanguageProvider id="gen.no.registers" />}
              rows={5}
              paginator
              stripedRows
              scrollHeight="flex"
              paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              filterDisplay="menu"
            >
              <Column
                field=""
                header={
                  inventory?.restrictionType === 2 ? (
                    <LanguageProvider id={"gen.sku"} />
                  ) : (
                    <LanguageProvider id={"gen.location"} />
                  )
                }
                filter
                sortable
                body={(rowData) => {
                  return rowData;
                }}
              />
              <Column
                field=""
                header={<LanguageProvider id={"gen.actions"} />}
                body={() => {
                  return (
                    <div className="actions">
                      <Button
                        className="p-button-plain p-button-text"
                        onClick={() => {}}
                      >
                        <Icon icon="trash-02" className="action-delete" />
                      </Button>
                    </div>
                  );
                }}
              />
            </DataTable>
          </Form>
        </div>
      )}
    </Dialog>
  );
};

export default InventoryDetailsDialog;
