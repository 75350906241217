import LanguageProvider from "../../../../shared/components/language-provider";
import { Link } from "react-router-dom";
import { memo, useContext, useEffect, useState } from "react";
import { ThemeContext } from "~/app";
import ProductIcon from "~/shared/components/icon-components/nav-product-icon";

function ProductCard({ model }) {
  const {
    idApplicationUserClient,
    idApplicationClient,
    code,
    productName,
    userName,
    productDescription,
    instanceCode,
    isFavorite,
    users,
  } = model;

  const currentTheme = useContext(ThemeContext);

  const colors = currentTheme.currentTheme;
  const dashboardInventoryCardBackground =
    colors.dashboardInventoryCardBackground;
  const dashboardWmsCardBackground = colors.dashboardWmsCardBackground;
  const dashboardMesCardBackground = colors.dashboardMesCardBackground;
  const dashboardSequenceCardBackground =
    colors.dashboardSequenceCardBackground;
  const dashboardWarehouseCardBackground =
    colors.dashboardWarehouseCardBackground;
  const dashboardAMCCardBackground = colors.dashboardAMCCardBackground;
  const dashboardWmsCoreCevaCardBackground =
    colors.dashboardWmsCoreCevaCardBackground;
  const dashboardPrintCardBackground = colors.dashboardPrintCardBackground;

  const ProductBackground = () => {
    switch (productName) {
      case "product.platform":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill="#FFF4C7"
            />
          </svg>
        );
      case "product.inventory":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardInventoryCardBackground}
            />
          </svg>
        );
      case "product.wms":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWmsCardBackground}
            />
          </svg>
        );
      case "product.sequence":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardSequenceCardBackground}
            />
          </svg>
        );
      case "product.mes":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardMesCardBackground}
            />
          </svg>
        );
      case "product.warehouse":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWarehouseCardBackground}
            />
          </svg>
        );
      case "product.area.movement.control":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardAMCCardBackground}
            />
          </svg>
        );
      case "product.print":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardPrintCardBackground}
            />
          </svg>
        );
      case "product.documents.print":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWarehouseCardBackground}
            />
          </svg>
        );
      case "product.stage.control":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWarehouseCardBackground}
            />
          </svg>
        );

      case "product.shipping":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWarehouseCardBackground}
            />
          </svg>
        );
      case "product.b2b":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWarehouseCardBackground}
            />
          </svg>
        );
      case "product.wms.core.ceva":
        return (
          <svg
            className="product-card-background"
            width="142"
            height="98"
            viewBox="0 0 142 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142 89.9906C142 94.4337 138.394 98 133.912 98C105.612 98 12.0923 98 0 98C15 61.5 49.5 82.5 69 43C88.5 3.49999 118.212 0 142 0C142 25.84 142 69.8456 142 89.9906Z"
              fill={dashboardWmsCoreCevaCardBackground}
            />
          </svg>
        );
    }
  };
  const RenderUserInitials = (name) => {
    let names = name.split(" ");
    let initials = "";
    for (let index = 0; index < names.length; index++) {
      if (initials.length <= 1) {
        initials += names[index].substring(0, 1).toUpperCase();
      } else {
        break;
      }
    }
    return initials;
  };

  return (
    <Link
      to={`${instanceCode}/home/${code}`}
      className={`card product-card product-${productName} fade-in`}
    >
      <div className="product-card-header">
        <div className="product-info">
          <div className="product-icon-container">
            <ProductIcon productCode={model.code} isHomeDashboardCard={true} />
          </div>
          <span className="product-name">{productDescription}</span>
          <span className="product-location">
            <LanguageProvider id={productName} />
          </span>
        </div>
      </div>
      <div className="product-card-content">
        <div className="product-users">
          <div className="users-profiles">
            {users && (
              <>
                {users.map((user, userIndex) => {
                  if (userIndex < 3)
                    return (
                      <div
                        key={`product-${idApplicationUserClient}-user-${user.idUser}`}
                        className="product-user"
                        title={user.userName}
                      >
                        <span className="user-identification">
                          {`${RenderUserInitials(user.userName)}`}
                        </span>
                      </div>
                    );
                })}
                {users.length > 3 && (
                  <div
                    className="product-user"
                    title={<LanguageProvider id={"gen.more"} />}
                  >
                    <span className="more-users">{`+${users.length - 3}`}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <span className="product-users-amount">{`${
            users ? users.length : 0
          } ${LanguageProvider({
            id: "gen.collaborators",
          })}`}</span>
        </div>
      </div>
      <ProductBackground />
    </Link>
  );
}

export default memo(ProductCard);
