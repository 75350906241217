import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { DataTableFilter } from "~/shared/interfaces";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function ListB2bOrderProcess(
  filter: DataTableFilter
): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.B2b + "/list-order-process",
      filter,
      "B2B"
    );
    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetOrderProcess(
  id: number,
  orderId?: string
): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.B2b + "/get-order-process",
      "B2B",
      { params: { id: id, orderId: orderId } }
    );

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function FinishOrderProcess(orderId: string): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      `${settings.Urls.Rest.B2b}/finish-order-status?orderId=${orderId}`,
      {},
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}
export async function GetCarriers(): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.B2b + "/carriers",
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
export async function EditOrderCarrier(carrierModel: any): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      `${settings.Urls.Rest.B2b}/edit-order-carrier`,
      carrierModel,
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ReprocessReport(orderId: number): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      `${settings.Urls.Rest.B2b}/reprocess-track-number?orderId=${orderId}`,
      {},
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ReintegrateOrderCarrier(orderId: number): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      `${settings.Urls.Rest.B2b}/reintegrate-order-carrier?orderId=${orderId}`,
      {},
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ListOrderProcessImages(orderId: number): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Rest.B2b}/list-pods?idOrderProcess=${orderId}`,
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function DeleteOrderProcessImages(orderId: number): Promise<any> {
  try {
    const { data, status } = await axiosService().delete(
      `${settings.Urls.Rest.B2b}/delete-pods?idOrderProcessImage=${orderId}`,
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ListOrderProcessStatusHistory(
  orderId: number
): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Rest.B2b}/list-order-process-status-history?id=${orderId}`,
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetOrderDocuments(orderId: number): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Rest.B2b}/list-order-documents?idOrderProcess=${orderId}`,
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function DownloadOrderDocument(id: number): Promise<any> {
  try {
    const { data, status, message } = await axiosService().get(
      `${settings.Urls.Rest.B2b}/download-order-document?id=${id}`,
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function UpdateFieldOrder(
  idOrder: number,
  codeField: string,
  value: string
): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      `${settings.Urls.Rest.B2b}/update-field-order?idOrder=${idOrder}&codeField=${codeField}&value=${value}`,
      {},
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
export async function UpdateOrderStatus(
  idOrder: number,
  newStatus: OrderMonitorStatus
): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      `${settings.Urls.Rest.B2b}/update-status-order?idOrder=${idOrder}&newStatus=${newStatus}&value=${newStatus}`,
      {},
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
