import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function loadSettings(applicationClientId: string): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Rest.Settings}/list-settings`,
      "Platform",
      {
        params: { applicationClientId },
      }
    );
    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function loadSettingsModulesAdmin(
  applicationClientId: string,
  requiredParams: string[]
): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Rest.Settings}/list-settings`,
      "Platform",
      {
        params: { applicationClientId },
      }
    );
    const settingsParameters = data.data
      .filter((setting) => setting.name === "modules_admin")
      .flatMap((setting) => setting.subgroups)
      .flatMap((subgroup) => subgroup.settings)
      .map(({ propertyName, value }) => ({ propertyName, value }));

    if (status === 200 && settingsParameters.length > 0) {
      return requiredParams.every((param) =>
        settingsParameters.some(
          (setting) =>
            setting.propertyName === param && setting.value === "true"
        )
      );
    }
  } catch (error) {
    console.error(error);
  }
}
