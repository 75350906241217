import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function GetPrinters(): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.B2b + "/printers",
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
