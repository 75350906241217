import "./scss/print-document.scss";

import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import {
  GetDocCount,
  GetPrinters,
} from "~/services/api/document-print/documento-print";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { PrintDocumentPrinterConnectionModel } from "~/shared/interfaces/document-print";
import SetupPrintInputFieldsDialog from "./dialog/setup-print-input-fields-dialog";
import axios from "axios";
import { classNames } from "primereact/utils";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useLanguageContext } from "~/context/LanguageContext";
import { useToastContext } from "~/context/ToastContext";
import Icon from "~/shared/components/icons";
import { ThemeContext } from "~/app";
import { CHART_COLORS } from "~/pages/shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";

const Print = () => {
  const dcpAxiosService = useDcpAxiosService();
  const currentTheme = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const { showToast } = useToastContext();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  const [printers, setPrinters] = useState<
    PrintDocumentPrinterConnectionModel[]
  >([]);
  const [loadingPrinters, setLoadingPrinters] = useState(true);
  const [loadingDocumentCount, setLoadingDocumentCount] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [displayClearDataDialog, setDisplayClearDataDialog] = useState(false);
  const [
    configureEntryRegistrationDialog,
    setConfigureEntryRegistrationDialog,
  ] = useState(false);
  const [printFieldsSettings, setPrintFieldsSettings] = useState([]);
  const [printFieldSettingsValuesCount, setPrintFieldSettingsValuesCount] = useState(0);
  const [donePercentage, setDonePercentage] = useState(0);
  const [printsDataTotal, setPrintsDataTotal] = useState(0);
  const [urlPrintValue, setUrlPrintValue] = useState("");

  const [isFormFilled, setIsFormFilled] = useState(true);
  const [loadingSavePrintForm, setLoadingSavePrintForm] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const inputRefs = useRef([]);
  const cancelRequestRef = useRef(false);
  const donePrints = useRef(0);

  interface FormValues {
    printer: PrintDocumentPrinterConnectionModel;
    printFields: {
      [key: string]: string;
    };
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const formikPrinter = useFormik({
    initialValues: {
      printer: {
        code: "",
        name: "",
        ip: "",
        port: "0",
      },
      printFields: printFieldsSettings.reduce((acc, field) => {
        if (field.enable) {
          acc[field.code] = "";
        }
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    // validate: validatePrintFieldsForm,
    onSubmit: async (values) => {
      try {
        setLoadingSavePrintForm(true);
        setIsPrinting(true);
        setDonePercentage(0);
        donePrints.current = 0;
        cancelRequestRef.current = false;

        if (
          formikPrinter.values.printer.name == "" &&
          formikPrinter.values.printer.ip == ""
        ) {
          showToast({
            severity: "error",
            message: (
              <LanguageProvider
                id={"gen.message.print.document.error.printer.not.selected"}
              />
            ),
          });
        } else {
          const printDocumentFields = {
            documentFields: printFieldsSettings
              .filter((filter) => filter.enable)
              .map((field) => ({
                code: field.code,
                value: formikPrinter.values.printFields[field.code],
              })),
            printer: formikPrinter.values.printer,
          };

          const { data, status } = await dcpAxiosService.post(
            settings.Urls.Rest.print + "/print-file",
            printDocumentFields,
            "PrintDocument"
          );

          if (status === 200) {
            if (data.data != null) {
              let resultado = false;
              setPrintsDataTotal(data.data.documents.length);

              for (const document of data.data.documents) {

                if (cancelRequestRef.current) {
                  showToast({
                    severity: "success",
                    message: "Impressão cancelada.",
                  });
                  cancelRequestRef.current = false;
                  break;
                }

                const documents = [document];
                const dataToPrint = {
                  printer: {
                    ...formikPrinter.values.printer,
                    port: formikPrinter.values.printer.port.toString(),
                  },
                  documents: documents,
                };

                setDonePercentage(
                  (prev) => (prev += 100 / data.data.documents.length)
                );
                donePrints.current = donePrints.current + 1;
                console.log("donePrints", donePrints);

                try {
                  let cleanAxios = axios.create();
                  const printerResponse = await cleanAxios.post(
                    urlPrintValue + "/api/printer/print",
                    dataToPrint
                  );
                  if (printerResponse.data.true === 200) {
                    resultado = true;
                  }
                } catch {
                  showToast({
                    severity: "error",
                    message: (
                      <LanguageProvider
                        id={"gen.message.print.document.error.print"}
                      />
                    ),
                  });
                  resultado = false;
                }
                // Aguarda 1 segundo antes de processar o próximo documento
                await sleep(1000);
              }

              setDonePercentage(0);
              donePrints.current = 0;

              if (resultado) {
                showToast({
                  severity: "success",
                  message:
                    LanguageProviderWithoutContext({
                      id: "gen.message.print.document.saved",
                      currentLanguage,
                    }) +
                    " " +
                    data.data.documents.length,
                });
              }
            } else {
              showToast({
                severity: "error",
                message: (
                  <LanguageProvider
                    id={"gen.message.print.document.not.document.find"}
                  />
                ),
              });
            }
          } else {
            showToast({
              severity: "error",
              message: (
                <LanguageProvider id={"gen.message.print.document.error"} />
              ),
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSavePrintForm(false);
        setIsPrinting(false);
        cancelRequestRef.current = false;
        clearForm();
      }
    },
  });

  const IsFormValid = (name) => {
    const fieldNameParts = name.split(".");
    const fieldError = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.errors
    );
    const fieldTouched = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.touched
    );
    return !!(fieldError && fieldTouched);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">
        {formikPrinter.errors.printFields?.[name.split(".")[1]]}
        {formikPrinter.errors[name]}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  function handleKeyDown(e: any, index: number) {
    if (
      (e.key === "Tab" || e.key === "Enter") &&
      !e.shiftKey &&
      index === printFieldSettingsValuesCount - 1
    ) {
      e.preventDefault();
    }
    if (e.key === "Tab" || e.key === "Enter") {
      if (validateKeyDownFields()) {
        formikPrinter.handleSubmit();
      }
    }
  }

  function validateKeyDownFields() {
    try {
      const fields: Record<string, any> = printFieldsSettings
        .filter((filter) => filter.enable)
        .reduce(
          (acc, field) => {
            acc[field.code] =
              formikPrinter.values.printFields[field.code] ?? null;
            return acc;
          },
          {} as Record<string, any>
        );
      if (
        Object.values(fields).every(
          (value) => value != null && value != undefined && value != ""
        )
      )
        return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  const loadInterfacePrintFields = async () => {
    try {

      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.fieldSettings + "/list-all",
        "PrintDocument"
      );
      if (status === 200) {
        const enableCount = data.data.filter(
          (value) => value.enable === true
        ).length;
        setPrintFieldSettingsValuesCount(enableCount);
        setPrintFieldsSettings(data.data);
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id={"gen.error"} />,
      });
    }
  };

  const clearForm = () => {
    formikPrinter.setValues({
      printer: formikPrinter.values.printer,
      printFields: printFieldsSettings.reduce(
        (acc, field) => {
          acc[field.code] = field.autoEmpty
            ? ""
            : formikPrinter.values.printFields[field.code] ?? "";
          return acc;
        },
        { ...formikPrinter.values.printFields }
      ),
    });

    inputRefs.current[0]?.focus();
  };

  const handleClearData = () => {
    if (formikPrinter.values) {
      clearForm();
    }
    setDisplayClearDataDialog(false);
    setIsFormFilled(false);
  };

  function getSettingValueByName(data, groupName, subgroupName, propertyName) {
    const value = data
      .find((group) => group.name === groupName)
      ?.subgroups.find((subgroup) => subgroup.name === subgroupName)
      ?.settings.find((setting) => setting.propertyName === propertyName).value;
    return value;
  }

  function findColumnKeyByName(data, targetName) {
    const columnKey = data.find(
      (column) => column.columnName === targetName
    ).columnKey;
    if (columnKey) {
      return columnKey;
    }
    return null;
  }

  async function loadPrinters() {
    try {
      setLoadingPrinters(true);
      setPrinters(await GetPrinters());
    } catch (error) {
      console.error(error);
    }
    setLoadingPrinters(false);
  }

  async function getDocumentPrintCount() {
    try {
      setLoadingDocumentCount(true);

      const printDocumentFields = {
        documentFields: printFieldsSettings
          .filter((filter) => filter.enable)
          .map((field) => ({
            code: field.code,
            value: formikPrinter.values.printFields[field.code],
          })),
        printer: formikPrinter.values.printer,
      };

      const count = await GetDocCount(printDocumentFields);
      setPrintsDataTotal(count);

      showToast({
        severity: "info",
        message:
          count +
          " " +
          LanguageProviderWithoutContext({
            id: "print.document.count.found",
            currentLanguage,
          }),
      });
    } catch (error) {
      console.error(error);
    }
    setLoadingDocumentCount(false);
  }

  useEffect(() => {
    async function loadSavePrintersDatabaseDropdownFields() {
      try {
        const { data: databaseProductProcess } = await dcpAxiosService.get(
          `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
          "Platform",
          {
            params: {
              databaseProductProcessType:
                settings.DatabaseProcessType.PrintDocument_CadastroImpressoras,
            },
          }
        );
        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.DatabaseItem + "/list-database-items",
          "Platform",
          {
            params: {
              idDatabase: databaseProductProcess.data.idDatabase,
              databaseName: databaseProductProcess.data.nameDatabase,
            },
          }
        );
        const settingsResponse = await dcpAxiosService.get(
          settings.Urls.Rest.Settings + "/list-settings",
          "Platform",
          {
            params: {
              applicationClientId: applicationClientId,
            },
          }
        );

        const columnNameValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print"
        );
        const columnCodeValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print-code"
        );
        const columnIpValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print-ip"
        );
        const columnPortValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print-port"
        );

        const driverPrintUrlValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-driver",
          "fields-print-driver"
        );
        setUrlPrintValue(driverPrintUrlValueInSettings);

        if (status === 200) {
          const codeColumnName = findColumnKeyByName(
            data.data?.headers,
            columnNameValueInSettings
          );
          const labelColumnName = findColumnKeyByName(
            data.data?.headers,
            columnCodeValueInSettings
          );
          const labelColumnIp = findColumnKeyByName(
            data.data?.headers,
            columnIpValueInSettings
          );
          const labelColumnPort = findColumnKeyByName(
            data.data?.headers,
            columnPortValueInSettings
          );

          const formattedValues = data.data.items.map((item) => ({
            name: item.columns[labelColumnName],
            value: item.columns[codeColumnName],
          }));

          const printersAllValues = data.data.items.map((item) => ({
            name: item.columns[labelColumnName],
            value: item.columns[codeColumnName],
            ip: item.columns[labelColumnIp],
            port: item.columns[labelColumnPort],
          }));
        }
      } catch (error) {
        console.error(error);
      }
    }
    loadSavePrintersDatabaseDropdownFields();
    loadInterfacePrintFields();

    loadPrinters();
  }, []);

  useEffect(() => {
    const { printFields, printer } = formikPrinter.values;
    if (Object.keys(printFields).length > 0 || printer.ip) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
  }, [formikPrinter.values]);

  return (
    <>
      <div
        className={`print-document-main-container ${fullScreen ? "fullscreen" : ""
          }`}
      >
        <div className="backdrop">
          <div className="print-header-wrapper">
            <div className="container">
              <div className="header">
                <div className="title-wrapper">
                  <span className="header-message">
                    <span className="title">
                      <LanguageProvider id={"product.print.menu.print"} />
                    </span>
                  </span>
                </div>
                <div
                  className="icon"
                  onClick={() => setFullScreen(!fullScreen)}
                >
                  <Icon
                    icon={fullScreen ? "minimize-01" : "maximize-01"}
                    size={18}
                    color={currentTheme.iconDefaultColor}
                  />
                </div>
              </div>
              <div className="container-tables">
                <div className="tableLeft">
                  <div className="entry-settings-side-fields">
                    <Form className="entry-settings-form">
                      <FormRow>
                        <InputContainer
                          className="print-registers-dropdown"
                          label="Impressoras"
                        >
                          <Dropdown
                            id="printer"
                            className={[
                              "print-document-dropdown",
                              classNames({
                                "p-invalid": IsFormValid("printer"),
                              }),
                            ].join(" ")}
                            placeholder="Selecione uma impressora"
                            options={printers}
                            value={formikPrinter.values.printer}
                            optionLabel="name"
                            onChange={(e) => {
                              formikPrinter.setFieldValue("printer", e.value);
                            }}
                            loading={loadingPrinters}
                          />
                          <GetFormError name={"printer"} />
                        </InputContainer>
                      </FormRow>
                      <div className="side-menu-title">
                        <div className="tableLeft-title">
                          <LanguageProvider
                            id={
                              "dcp.platform.print.document.dialog.print.register"
                            }
                          />
                        </div>
                      </div>
                      {printFieldsSettings
                        .filter((field) => field.enable)
                        .map((field, index) => {
                          return (
                            <PrintFieldInput
                              key={index}
                              field={field}
                              index={index}
                              value={
                                formikPrinter.values.printFields[field.code]
                              }
                              handleChange={(value) => {
                                let aux = {
                                  ...formikPrinter.values,
                                  printFields: {
                                    ...formikPrinter.values.printFields,
                                    [field.code]: value,
                                  },
                                };
                                formikPrinter.setValues(aux);
                              }}
                              inputRefs={inputRefs}
                              handleKeyDown={handleKeyDown}
                              IsFormValid={IsFormValid}
                            />
                          );
                        })}
                      <FormRow>
                        <InputContainer></InputContainer>
                      </FormRow>

                      {isPrinting && (
                        <>
                          <div className="progress-content">
                            <div className="bar-wrapper">
                              <div className="bar">
                                <span className="background-fill"></span>
                                <span
                                  className="value"
                                  style={{
                                    background: CHART_COLORS[1],
                                    width: `${donePercentage}%`,
                                    transition: "width 0.3s ease-in-out",
                                  }}
                                ></span>
                              </div>
                            </div>
                            <FormRow>
                              <InputContainer>
                                <Button
                                  className="p-button-danger stop-print-button"
                                  type="button"
                                  onClick={() => (cancelRequestRef.current = true)}
                                  icon={<Icon icon="stop" color="#ffffff" />}
                                >
                                  <LanguageProvider id={"gen.cancel"} />
                                </Button>
                              </InputContainer>

                              <span className="percentage-label">
                                {`${donePrints.current} / ${printsDataTotal}`}
                              </span>
                            </FormRow>
                          </div>

                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
              <div className="entry-footer">
                <Button
                  className="p-button p-button-text-plain setting-button"
                  onClick={() => setConfigureEntryRegistrationDialog(true)}
                >
                  <LanguageProvider
                    id={"dcp.platform.print.document.configure.search.form"}
                  />
                </Button>
                <div className="entry-manage-data-buttons">
                  <Button
                    id="clearData"
                    className="p-button p-button-text-plain clear-data"
                    onClick={() => setDisplayClearDataDialog(true)}
                    disabled={isFormFilled}
                  >
                    <LanguageProvider
                      id={"dcp.platform.warehouse.clean.data"}
                    />
                  </Button>
                  <Button
                    type="button"
                    onClick={() => getDocumentPrintCount()}
                    loading={loadingDocumentCount}
                  >
                    <LanguageProvider id="print.document.count" />
                  </Button>
                  <Button
                    type="submit"
                    appearance="primary"
                    loading={loadingSavePrintForm}
                    onClick={() => formikPrinter.handleSubmit()}
                  >
                    <LanguageProvider id={"dcp.printdocument.print.button"} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmation
        isOpen={displayClearDataDialog}
        isDelete={true}
        modalTitle={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
        }
        bodyMessage={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data.message"} />
        }
        onConfirm={handleClearData}
        onCancel={() => setDisplayClearDataDialog(false)}
      />
      <SetupPrintInputFieldsDialog
        visible={configureEntryRegistrationDialog}
        onHide={() => {
          setConfigureEntryRegistrationDialog(false);
          loadInterfacePrintFields();
        }}
        printFieldsSettings={printFieldsSettings}
      />
    </>
  );
};

export default Print;

interface PrintFieldInputProps {
  field: { code: string; name: string };
  index: number;
  value: any;
  handleChange: (value: any) => void;
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  handleKeyDown: (e: React.KeyboardEvent, index: number) => void;
  IsFormValid: (field: string) => boolean;
}

const PrintFieldInput: React.FC<PrintFieldInputProps> = ({
  field,
  index,
  value,
  handleChange,
  inputRefs,
  handleKeyDown,
  IsFormValid,
}) => {
  return (
    <FormRow key={index}>
      <InputContainer label={field.name}>
        <InputText
          id={field.code}
          name={`printFields.${field.code}`}
          placeholder={`Enter ${field.name}`}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          className={[
            classNames({
              "p-invalid": IsFormValid(`printFields.${field.code}`),
            }),
          ].join(" ")}
          ref={(el) => (inputRefs.current[index] = el)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      </InputContainer>
    </FormRow>
  );
};
