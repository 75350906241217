import "../scss/order-status-field.scss";
import React from "react";
import LanguageProvider from "~/shared/components/language-provider";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";

export function OrderStatusField({ orderStatus }) {
  switch (orderStatus) {
    case OrderMonitorStatus.inconsistentIntegration:
      return (
        <div className="processing-status-inconsistent-integration">
          <LanguageProvider
            id={"dcp.b2b.monitor.order.status.integration.inconsistency"}
          />
        </div>
      );
    case OrderMonitorStatus.createdOrder:
      return (
        <div className="processing-status-finished">
          <LanguageProvider id={"dcp.b2b.monitor.order.status.created"} />
        </div>
      );
    case OrderMonitorStatus.reprocessCarrierIntegration:
      return (
        <div className="processing-status-reprocess-carrier-integration">
          <LanguageProvider
            id={"dcp.b2b.monitor.order.status.reprocess-carrier-integration"}
          />
        </div>
      );
    case OrderMonitorStatus.reprocessingReport:
      return (
        <div className="processing-status-reprocessing-report">
          <LanguageProvider
            id={"dcp.b2b.monitor.order.status.reprocessing-report"}
          />
        </div>
      );
    case OrderMonitorStatus.finished:
      return (
        <div className="processing-status-finished">
          <LanguageProvider id={"dcp.b2b.monitor.order.status.finished"} />
        </div>
      );
    case OrderMonitorStatus.pendingShipping:
      return (
        <div className="processing-status-pending">
          <LanguageProvider id="dcp.b2b.monitor.order.status.pendent-shipping" />
        </div>
      );
    case OrderMonitorStatus.carrierDefinition:
      return (
        <div className="processing-status-carrier-definition">
          <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-definition" />
        </div>
      );
    case OrderMonitorStatus.pendingIntegrationCarrier:
      return (
        <div className="processing-status-loaded">
          <LanguageProvider id="dcp.b2b.monitor.order.status.pending-integration-carrier" />
        </div>
      );
    case OrderMonitorStatus.pendingPacking:
      return (
        <div className="processing-status-packing">
          <LanguageProvider id="dcp.b2b.monitor.order.status.pending-packing" />
        </div>
      );
    case OrderMonitorStatus.carrierError:
      return (
        <div className="processing-status-carrier-error">
          <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-error" />
        </div>
      );
    case OrderMonitorStatus.errorCarrierIntegration:
      return (
        <div className="processing-status-carrier-integration">
          <LanguageProvider id="dcp.b2b.monitor.order.status.error-carrier-integration" />
        </div>
      );
    case OrderMonitorStatus.pendingReport:
      return (
        <div className="processing-status-report-pending">
          <LanguageProvider id="dcp.b2b.monitor.order.status.pending-report" />
        </div>
      );
    case OrderMonitorStatus.errorReport:
      return (
        <div className="processing-status-error-report">
          <LanguageProvider id="dcp.b2b.monitor.order.status.error-report" />
        </div>
      );
    case OrderMonitorStatus.pendingPrint:
      return (
        <div className="processing-status-print-pending">
          <LanguageProvider id="dcp.b2b.monitor.order.status.pending-print" />
        </div>
      );
    default:
      return <div>{orderStatus}</div>;
  }
}
