import "./unprocessed-images-dialog.scss";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useState } from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { Dropdown } from "primereact/dropdown";
import { InventoryContext } from "~/pages/inventory/inventory/inventory-details/inventory-details.tsx";
import {
  DeleteUnprocessedImage,
  listGroupNames,
  listLocationNames,
  UploadLocationImage,
} from "~/services/api";
import { Button } from "~/shared/components/dcp-button.tsx";
import Icon from "~/shared/components/icons";
import { useToastContext } from "~/context/ToastContext";
import { AreaItemNameModel } from "~/shared/interfaces/area-group.ts";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import imgEmptyForm from "~/theme/media/assets/emptyInbox.svg";
import settings from "~/services/settings.json";
import { ThemeContext } from "~/app";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { Image } from "primereact/image";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { DcpCustomZoomImage } from "~/shared/components/dcp-zoom-image";

export interface ImageUploadProps {
  visible: boolean;
  onClose: () => void;
}

interface UnprocessedImageProps {
  id: number;
  url: string;
  originalName: string;
  createdAt?: Date;
}

export function UnprocessedImageAuditDialog(props: ImageUploadProps) {
  // Dropdown options
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const dcpAxiosService = useDcpAxiosService();
  const [groupOptions, setGroupOptions] = useState<AreaItemNameModel[]>();
  const [locationOptions, setLocationOptions] = useState<AreaItemNameModel[]>();

  // Loading indicators
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingUnprocessedImages, setLoadingUnprocessedImages] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modalConfirmationDeleteIsDelete, setModalConfirmationDeleteIsDelete] =
    useState<boolean>(false);

  // Data
  const [selectedFiles, setSelectedFiles] = useState<UnprocessedImageProps[]>(
    []
  );
  const [currentImageIndexSelected, setCurrentImageIndexSelected] =
    useState<any>();
  const [currentImageSelected, setCurrentImageSelected] =
    useState<UnprocessedImageProps>();
  const [imageCounter, setImageCounter] = useState<number>(1);
  const [selectedArea, setSelectedArea] = useState<number>();
  const [selectedAreaGroup, setSelectedAreaGroup] =
    useState<AreaItemNameModel>();
  const [selectedLocation, setSelectedLocation] = useState<AreaItemNameModel>();

  // Contexts
  const { inventory } = useContext(InventoryContext);
  const { showToast } = useToastContext();
  const [isMultipleSelected, setIsMultipleSelected] = useState(false);

  function onClose() {
    props.onClose();
    setCurrentImageIndexSelected(0);
    setCurrentImageSelected(selectedFiles[0] || null);
    setImageCounter(1);
  }

  function handleDeleteImageConfirmation() {
    setModalConfirmationDeleteIsDelete(false);
    onDeleteImage();
  }

  async function onDeleteImage() {
    const deleteUnprocessedImageResult = await DeleteUnprocessedImage(
      currentImageSelected.id
    );
    if (deleteUnprocessedImageResult) {
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "image.deleted",
          currentLanguage,
        }),
      });
      await getInventoryUnprocessedImages();
    } else {
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "unhandled.error",
          currentLanguage,
        }),
      });
    }
  }

  async function uploadImage() {
    try {
      const form = new FormData();
      form.append("inventoryId", inventory.id.toString());
      form.append("type", "single");
      form.append("areaId", selectedArea?.toString());
      form.append("locationId", selectedLocation?.id.toString());
      form.append("unprocessedImageId", currentImageSelected?.id.toString());
      form.append("locationType", selectedLocation?.locationType.toString());
      setSubmitting(true);

      await UploadLocationImage(form);
      setSubmitting(false);
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "inventory.image.upload.success",
          currentLanguage,
        }),
      });

      await getInventoryUnprocessedImages();
      if (selectedFiles.length === 0) {
        onClose();
      }
    } catch (e) {
      console.error(e);
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "unhandled.error",
          currentLanguage,
        }),
      });
    }
  }

  async function handleLeftArrowClick() {
    if (currentImageIndexSelected === selectedFiles[0]?.id) {
      return;
    }

    setLoadingUnprocessedImages(true);
    const currentIndex = selectedFiles?.findIndex(
      (file) => file.id === currentImageIndexSelected
    );
    if (currentIndex > 0) {
      const previousImage = selectedFiles[currentIndex - 1];
      const imageSelected = selectedFiles?.find(
        (file) => file.id === previousImage.id
      );
      setCurrentImageIndexSelected(previousImage.id);
      setCurrentImageSelected(imageSelected);
      setImageCounter(currentIndex);
      setLoadingUnprocessedImages(false);
    }
  }

  async function handleRightArrowClick() {
    if (
      currentImageIndexSelected === selectedFiles[selectedFiles?.length - 1].id
    ) {
      return;
    }

    setLoadingUnprocessedImages(true);
    const currentIndex = selectedFiles?.findIndex(
      (file) => file.id === currentImageIndexSelected
    );
    if (currentIndex < selectedFiles?.length - 1) {
      const newIndex =
        currentIndex === selectedFiles?.length - 1 ? 0 : currentIndex + 1;
      const imageSelected = selectedFiles?.find(
        (file) => file.id === selectedFiles[newIndex].id
      );
      setCurrentImageSelected(imageSelected);
      setCurrentImageIndexSelected(selectedFiles[newIndex].id);
      setImageCounter(newIndex + 1);
      setLoadingUnprocessedImages(false);
    }
  }

  // Load area groups
  useEffect(() => {
    async function load() {
      if (!selectedArea) return;

      setLoadingGroups(true);
      setSelectedAreaGroup(null);
      setSelectedLocation(null);
      const orderedGroups = await listGroupNames(selectedArea).then(
        (groups) => {
          return groups?.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        }
      );
      setGroupOptions(orderedGroups);
      setLoadingGroups(false);
    }
    load();
  }, [selectedArea]);

  // Load locations
  useEffect(() => {
    async function load() {
      if (!selectedAreaGroup) return;

      setLoadingLocations(true);
      setSelectedLocation(null);
      const locationsOrdered = await listLocationNames(
        selectedAreaGroup.id,
        selectedAreaGroup.locationType
      ).then((locations) => {
        return locations.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      });
      setLocationOptions(locationsOrdered);
      setLoadingLocations(false);
    }

    load();
  }, [selectedAreaGroup]);

  useEffect(() => {
    setIsMultipleSelected(selectedFiles && selectedFiles.length >= 1);
  }, [selectedFiles]);

  const getInventoryUnprocessedImages = async () => {
    try {
      setLoadingUnprocessedImages(true);
      const response = await dcpAxiosService.get(
        `${settings.Urls.Rest.Inventory}/location-unprocessed-images/`,
        "Inventory",
        {
          params: {
            idInventory: inventory?.id,
          },
        }
      );
      const data = response.data;
      if (data.status) {
        const images = data.data.map((image) => {
          return {
            id: image?.id,
            url: image?.imageUrl,
            originalName: image?.originalName,
            createdAt: image?.createdAt,
          };
        });
        setSelectedFiles(images);
        setCurrentImageIndexSelected(images[0]?.id);
        setCurrentImageSelected(images[0]);
        setLoadingUnprocessedImages(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUnprocessedImages(false);
    }
  };

  useEffect(() => {
    getInventoryUnprocessedImages();
  }, [props.visible]);

  function Footer() {
    return (
      <div className="footer-wrapper">
        <div className="footer-delete">
          <Button
            severity={"danger"}
            onClick={() => setModalConfirmationDeleteIsDelete(true)}
            disabled={!selectedFiles || selectedFiles.length === 0}
          >
            <LanguageProvider id={"gen.exclude.button"} />
          </Button>
        </div>
        <div className="footer-actions">
          <Button severity={"danger"} outlined onClick={onClose}>
            <LanguageProvider id={"gen.cancel"} />
          </Button>
          <Button
            loading={submitting}
            disabled={!selectedLocation}
            onClick={uploadImage}
          >
            <LanguageProvider id={"gen.save.button"} />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      appendTo={"self"}
      visible={props.visible}
      onHide={onClose}
      draggable={false}
      header={<LanguageProvider id={"inventory.image.unprocessed.header"} />}
      footer={Footer}
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "1060px",
      }}
    >
      <div className="unprocessed-image-content">
        {isMultipleSelected &&
          inventory &&
          inventory?.areas &&
          inventory?.areas?.length > 0 && (
            <div className="location-selection">
              <Dropdown
                placeholder={LanguageProviderWithoutContext({
                  id: "gen.area",
                  currentLanguage,
                })}
                value={selectedArea}
                options={inventory?.areas}
                optionValue="id"
                optionLabel="name"
                filter
                disabled={!isMultipleSelected}
                onChange={(e) => setSelectedArea(e.value)}
              ></Dropdown>
              <Dropdown
                options={groupOptions}
                value={selectedAreaGroup}
                optionLabel={"name"}
                onChange={(e) => setSelectedAreaGroup(e.value)}
                disabled={!selectedArea || !isMultipleSelected}
                filter
                placeholder={LanguageProvider({ id: "gen.street" })}
                loading={loadingGroups}
              ></Dropdown>
              <Dropdown
                options={locationOptions}
                value={selectedLocation}
                optionLabel={"name"}
                onChange={(e) => setSelectedLocation(e.value)}
                disabled={!selectedAreaGroup || !isMultipleSelected}
                filter
                placeholder={LanguageProvider({ id: "gen.location" })}
                loading={loadingLocations}
              ></Dropdown>
            </div>
          )}
        <div className={"file-drop-area"}>
          {selectedFiles?.length > 0 && (
            <>
              <div className={"selected-image-wrapper"}>
                <div className="left-arrow-wrapper">
                  {!loadingUnprocessedImages && (
                    <Icon
                      className={`left-arrow${
                        currentImageIndexSelected === selectedFiles[0]?.id
                          ? "-does-not-exists"
                          : "-exists"
                      }`}
                      color={
                        currentImageIndexSelected === selectedFiles[0]?.id
                          ? currentTheme.tableIconColorCountDoesNotExists
                          : currentTheme.tableIconColorCountExists
                      }
                      size={25}
                      icon={"chevron-left"}
                      onClick={
                        !loadingUnprocessedImages && handleLeftArrowClick
                      }
                    />
                  )}
                </div>
                {!loadingUnprocessedImages ? (
                  <div className="image-preview-container">
                    <TransformWrapper
                      initialScale={1}
                      minScale={1}
                      maxScale={5}
                    >
                      {({ zoomIn, zoomOut, resetTransform }) => (
                        <>
                          <div className="transform-weapper-content">
                            <Button
                              className="p-button-secondary"
                              onClick={() => zoomIn()}
                            >
                              <Icon
                                icon={"zoom-in"}
                                color={currentTheme.tableIconColor}
                                size={20}
                              />
                            </Button>
                            <Button
                              className="p-button-secondary"
                              onClick={() => zoomOut()}
                            >
                              <Icon
                                icon={"zoom-out"}
                                color={currentTheme.tableIconColor}
                                size={20}
                              />
                            </Button>
                            <Button
                              className="p-button-secondary"
                              onClick={() => resetTransform()}
                            >
                              <Icon
                                icon={"expand-06"}
                                color={currentTheme.tableIconColor}
                                size={20}
                              />
                            </Button>
                          </div>
                          <TransformComponent>
                            <img
                              src={currentImageSelected?.url}
                              alt="location-image"
                              className="image-preview"
                              style={{
                                maxHeight: "50vw%",
                                height: "50vw",
                              }}
                            />
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>
                  </div>
                ) : (
                  <div className="image-preview-loading-wrapper">
                    <LoadingIndicator />
                  </div>
                )}
                <div className="right-arrow-wrapper">
                  {!loadingUnprocessedImages && (
                    <Icon
                      className={`right-arrow${
                        currentImageIndexSelected ===
                        selectedFiles[selectedFiles.length - 1]?.id
                          ? "-does-not-exists"
                          : "-exists"
                      }`}
                      color={
                        currentImageIndexSelected ===
                        selectedFiles[selectedFiles.length - 1]?.id
                          ? currentTheme.tableIconColorCountDoesNotExists
                          : currentTheme.tableIconColorCountExists
                      }
                      size={25}
                      icon={"chevron-right"}
                      onClick={handleRightArrowClick}
                    />
                  )}
                </div>
              </div>
              <span className="image-counter">
                {currentImageSelected?.originalName}
              </span>
              <div className="image-counter">
                <span>
                  <LanguageProvider id={"gen.image"} /> {imageCounter}{" "}
                  <LanguageProvider id={"gen.from"} /> {selectedFiles.length}
                </span>
              </div>
            </>
          )}

          {(!selectedFiles || selectedFiles?.length === 0) && (
            <div className="no-image-warning">
              <div className="no-image-warning">
                <img src={imgEmptyForm} alt="" />
                <span className="no-image-text">
                  <LanguageProvider id={"upload.images.no.image"} />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalConfirmation
        isOpen={modalConfirmationDeleteIsDelete}
        modalTitle={LanguageProviderWithoutContext({
          id: "image.delete.title",
          currentLanguage,
        })}
        bodyMessage={LanguageProviderWithoutContext({
          id: "image.delete.message",
          currentLanguage,
        })}
        isDelete={true}
        onConfirm={handleDeleteImageConfirmation}
        onCancel={() => setModalConfirmationDeleteIsDelete(false)}
      />
    </Dialog>
  );
}
