import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import settings from "~/services/settings.json";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Password } from "primereact/password";
import { useToastContext } from "~/context/ToastContext";
import { MultiSelect } from "primereact/multiselect";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import "./scss/settings-create-client.scss";

const AuthSettingsCreateClient = () => {
  // Utils
  const headerHeight = useHeaderHeight();
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const { currentLanguage } = useLanguageContext();
  // const [applicationsValue, setApplicationsValue] = useState([]);
  const [applicationList, setApplicationsList] = useState([]);

  // Loading indicators
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    initialValues: {
      clientDomain: "",
      clientName: "",
      username: "",
      password: "",
      applications: []
    },
    validate: (values) => {
      let errors = {};
      if (!values.clientDomain || values.clientDomain.length < 1) {
        errors.clientDomain = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }
      if (!values.clientName || values.clientName.length < 1) {
        errors.clientName = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }
      if (!values.username || values.username.length < 1) {
        errors.username = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }
      if (!values.password || values.password.length < 1) {
        errors.password = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }
      if (!values.applications || values.applications.length < 1) {
        errors.applications = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }

      return errors;
    },
    onSubmit: onClientCreate,
  });

  const loadApplications = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Admin.Application}/list`,
        "Auth"
      );

      if (status === 200) {
        setApplicationsList(data.data);
      }
      else {
        showToast({
          severity: "error",
          message: data.message,
        });
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id={"gen.error"} />,
      });
    }
  };

  async function onClientCreate(values) {
    setSaving(true);
    try {
      const { data, status } = await dcpAxiosService.post(
        `${settings.Urls.Admin.Authorization}/create-user-admin`,
        {
          clientDomain: values.clientDomain,
          clientName: values.clientName,
          username: values.username,
          password: values.password,
          applications: values.applications
        },
        "Login"
      );
      if (status === 200 && data.data === true) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="client.created" />,
        });
      }
      else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      resetForm();
    }
    setSaving(false);
  }

  function resetForm() {
    formik.resetForm({
      values: { ...formik.initialValues},
    });
  }
  
  const applicationOptions = applicationList
    .filter(product => product.productCode !== "platform")
    .map(product => ({
      key: product.productCode,
      value: product.id,
    }));

  useEffect(() => {
    loadApplications();
  }, []);

  return (
    <div className="page-body-settings-create-client">
      <div
        className="create-client-settings"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="container">
          <div className="header">
            <h3>
              <LanguageProvider id="settings.create.client" />
            </h3>
          </div>
          <div className="settings-content">
            <div className="settings-container">
              <div className="container">
                <div className="group-container">
                  <h3 className="page-header">
                    {<LanguageProvider id={`setting.group.information`} />}
                  </h3>
                  <div className="subgroup-container-create-client">
                    <div className="settings">
                      <Form>
                        <FormRow className="setting">
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={`setting.subgroup.create.client.domain`}
                              />
                            }
                          >
                            <InputText
                              value={formik.values.clientDomain}
                              onChange={(e) => {
                                formik.setFieldValue("clientDomain", e.target.value);
                              }}
                              autoFocus
                            />
                          </InputContainer>
                        </FormRow>
                        <FormRow className="setting">
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={`setting.subgroup.create.client.client.name`}
                              />
                            }
                          >
                            <InputText
                              value={formik.values.clientName}
                              onChange={(e) => {
                                formik.setFieldValue("clientName", e.target.value);
                              }}
                              autoFocus
                            />
                          </InputContainer>
                        </FormRow>
                        <FormRow className="setting">
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={`setting.subgroup.create.client.username`}
                              />
                            }
                          >
                            <InputText
                              value={formik.values.username}
                              onChange={(e) => {
                                formik.setFieldValue("username", e.target.value);
                              }}
                              autoFocus
                            />
                          </InputContainer>
                        </FormRow>
                        <FormRow className="setting">
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={`setting.subgroup.create.client.password`}
                              />
                            }
                          >
                            <Password
                              invalid
                              toggleMask
                              className="wms-password"
                              feedback={false}
                              value={formik.values.password}
                              onChange={(e) => {
                                formik.setFieldValue("password", e.target.value);
                              }}
                              autoFocus
                            />
                          </InputContainer>
                        </FormRow>
                        <FormRow className="setting">
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={`setting.subgroup.create.client.products`}
                              />
                            }
                          >
                            <MultiSelect
                              id="applications"
                              name="applications"
                              className="applications-multi-select"
                              options={applicationOptions}
                              value={formik?.values.applications}
                              onChange={(e) => {
                                formik.setFieldValue("applications", e.target.value);
                              }}
                              optionLabel="key"
                              optionValue="value"
                              placeholder={LanguageProviderWithoutContext({
                                id: "gen.datalakes.product.selection",
                                currentLanguage,
                              })}
                              maxSelectedLabels={5}
                              display="chip"
                              type="checkbox"
                              autoFocus
                            />
                          </InputContainer>
                        </FormRow>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <Button loading={saving} onClick={() => formik.submitForm()}>
              <LanguageProvider id="gen.save.button" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthSettingsCreateClient;
