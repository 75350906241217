/* eslint-disable react-hooks/exhaustive-deps */
import "./scss/picking-cartao.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import {
  GetLastPickingItems,
  GetPickingItems,
  HideItems,
  InsertPicking,
  ListPickingWorktationsAll,
  ReprocessAll,
  UpdatePickingItemStatus,
  ListPickingSku as listPickingSkus,
} from "~/services/api";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import PageHeader from "~/shared/components/page-header/page-header";
import { Password } from "primereact/password";
import { Tag } from "primereact/tag";
import { ThemeContext } from "~/app";
import {
  CevaCorePickingData,
  CevaCorePickingStatus,
  TelnetResponse,
  TelnetUser,
  WorkstationConnectionModel,
} from "~/shared/interfaces";
import axios, { AxiosResponse } from "axios";
import { compareNormalized } from "~/shared/helpers/utils";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";

export default function WMSCoreCevaPickingCartao() {
  const { currentTheme } = useContext(ThemeContext);
  const dcpAxiosService = useDcpAxiosService();
  const focusRef = useRef(null);
  const { showToast } = useToastContext();

  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationIds.wms-core-ceva"
  );

  // Refs
  const isTelnetRunning = useRef(false);
  const selectedWorkstationRef = useRef<WorkstationConnectionModel>(null);
  const wmsUsernameRef = useRef("");
  const wmsPasswordRef = useRef("");
  const authenticatedOnWmsRef = useRef(false);

  // Data
  const [workStations, setWorkStations] =
    useState<WorkstationConnectionModel[]>();
  const [skus, setSkus] = useState<any[]>();
  const [selectedSku, setSelectedSku] = useState("CARTAO");
  const [selectedWorkstation, setSelectedWorkstation] =
    useState<WorkstationConnectionModel>();
  const [items, setItems] = useState<CevaCorePickingData[]>([]);
  const [nf, setNf] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [itemListFilterQuery, setItemListFilterQuery] = useState("");
  const [wmsUsername, setWmsUsername] = useState("");
  const [wmsPassword, setWmsPassword] = useState("");
  const [telnetTime, setTelnetTime] = useState(1500);
  const [batchName, setBatchName] = useState("");

  // View Controll
  const [fullScreen, setFullScreen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [hideItemsConfirmationVisible, setHideItemsConfirmationVisible] =
    useState(false);
  const [reprocessAllConfirmationOpen, setReprocessAllConfirmationOpen] =
    useState(false);

  // Loading Indicators
  const [loadinOptions, setLoadinOptions] = useState(true);
  const [hideItemsLoading, setHideItemsLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  // Input block
  const [authenticatedOnWms, setAuthenticatedOnWms] = useState(false);

  const [displayLogoutConfirmDialog, setDisplayLogoutConfirmDialog] =
    useState(false);

  const logoutSuccessMessage = LanguageProvider({
    id: "wms.message.logout.success",
  });

  const logoutErrorMessage = LanguageProvider({
    id: "wms.message.logout.error",
  });

  function headerActions() {
    return (
      <div
        className="icon"
        onClick={() => {
          setFullScreen(!fullScreen);
          if (fullScreen) {
            document.exitFullscreen();
          } else {
            document.documentElement.requestFullscreen();
          }
        }}
      >
        <Icon
          icon={fullScreen ? "minimize-01" : "maximize-01"}
          size={18}
          color={currentTheme.iconDefaultColor}
        />
      </div>
    );
  }

  async function loadSkus() {
    try {
      var response = await listPickingSkus();
      setSkus(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadWorkStations() {
    try {
      var response = await ListPickingWorktationsAll();
      setWorkStations(response);
      if (response.length > 0 && !selectedWorkstation)
        setSelectedWorkstation(response[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function insert(e: React.FocusEvent<HTMLInputElement>) {
    try {
      if (nf.length < 1 || serialNumber.length < 1) return;
      e.stopPropagation();
      submitPicking();
      resetInput();
    } catch (error) {
      console.error(error);
    }
  }

  async function submitPicking() {
    try {
      if (!selectedWorkstationRef?.current) return;

      let payload = {
        id: 0,
        serialNumber: serialNumber,
        nf: nf,
        sku: selectedSku,
        userName: selectedWorkstationRef.current?.user,
        eventDate: new Date(),
        codigoEstacao: selectedWorkstationRef.current?.name,
        log: "",
        status: 0,
      };

      const insertResponse = await InsertPicking(payload);

      if (insertResponse.status) {
        showToast({
          severity: "success",
          message: `Serial adicionado: ${serialNumber}`,
        });
      } else {
        showToast({
          severity: "error",
          message: insertResponse.responseCode,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function resetInput() {
    try {
      setSerialNumber("");
      setNf("");
      focusRef.current.focus();
    } catch (error) {
      console.error(error);
    }
  }

  async function loadItems() {
    try {
      if (!authenticatedOnWmsRef.current) return;

      let _items = await GetPickingItems(selectedWorkstationRef.current.name);

      const errorItems = _items
        .filter((item) => item.status === CevaCorePickingStatus.Error)
        .sort(
          (a, b) =>
            new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime()
        );

      const otherItems = _items.filter(
        (item) => item.status !== CevaCorePickingStatus.Error
      );

      setItems([...errorItems, ...otherItems]);
    } catch (error) {
      console.error(error);
    }
  }

  async function sendToWms() {
    try {
      if (isTelnetRunning.current) return;

      console.log("Stating telnet while loop");

      while (true) {
        if (!authenticatedOnWmsRef.current) break;
        console.log("Inserting...");

        isTelnetRunning.current = true;

        const item = await GetLastPickingItems(
          selectedWorkstationRef.current.name
        );

        if (!item) {
          isTelnetRunning.current = false;
          console.warn("No pending items to process. Breaking...");
          break;
        }

        const { status, data } = await telnet("/sample/direct", {
          nf: item.nf,
          serial: item.serialNumber,
          sku: item.sku,
        });

        console.log("DATA:", data);

        if (data) {
          let nfStatus = data.status ? 1 : 2;
          if (data.reprocessa) nfStatus = 3;

          await UpdatePickingItemStatus({
            id: item.id,
            status: nfStatus,
            log: data.log,
          });
        }

        if (status === 200 && !data) {
          isTelnetRunning.current = false;
          break;
        } else if (status === 200 && data.status === false && data.log !== "") {
          isTelnetRunning.current = false;
          showToast({
            severity: "error",
            message: data.log,
          });
          break;
        }

        isTelnetRunning.current = false;
        console.log("Finished");
      }
      console.log("Telnet while loop break.");
    } catch (error) {
      isTelnetRunning.current = false;
      console.error(error);
    }
    isTelnetRunning.current = false;
  }

  async function login() {
    try {
      setLoginLoading(true);
      wmsPasswordRef.current = wmsPassword;
      wmsUsernameRef.current = wmsUsername;

      if (wmsPassword === "demo" && wmsUsername === "demo") {
        showToast({
          severity: "success",
          message: "LOGIN DEMO",
        });
        setAuthenticatedOnWms(true);
        authenticatedOnWmsRef.current = true;
      } else {
        const loginResponse = await telnet("/auth/login");

        if (loginResponse.status === 200 && loginResponse.data.status) {
          showToast({
            severity: "success",
            message: loginResponse.data.log,
          });
          setAuthenticatedOnWms(true);
          authenticatedOnWmsRef.current = true;
        } else {
          showToast({
            severity: "error",
            message: loginResponse.data.log,
          });
        }
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message:
          "Erro ao comunicar com WMS. Vefique as configurações da estação.",
      });
    } finally {
      setLoginDialogOpen(false);
      setLoginLoading(false);
    }
  }

  async function logout() {
    try {
      if (wmsPassword === "demo" && wmsUsername === "demo") {
        showToast({
          severity: "success",
          message: "LOGOUT DEMO",
        });
        setWmsPassword("");
        setWmsUsername("");
        setAuthenticatedOnWms(false);
        setItems([]);
        authenticatedOnWmsRef.current = false;
      } else {
        const { status, data } = await telnet("/auth/disconnect");

        if (status === 200 && data) {
          showToast({
            severity: "success",
            message: logoutSuccessMessage,
          });
          setAuthenticatedOnWms(false);
          setWmsPassword("");
          setWmsUsername("");
          setItems([]);
        } else {
          showToast({
            severity: "error",
            message: logoutErrorMessage,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisplayLogoutConfirmDialog(false);
    }
  }

  async function telnet(
    endpoint: string,
    payloadOverride: object = {}
  ): Promise<AxiosResponse<TelnetResponse, any>> {
    console.log("tentando enviar telnet");
    if (!wmsUsernameRef.current || !wmsPasswordRef.current) return;

    let payload: TelnetUser = {
      speed: telnetTime,
      userName: wmsUsernameRef.current,
      password: wmsPasswordRef.current,
      wmsHostName: selectedWorkstationRef.current.wmsHostname,
      wmsPort: parseInt(selectedWorkstationRef.current.wmsPort),
      nf: "",
      serial: "",
      sku: "",
      menuCode: "2313",
    };

    payload = { ...payload, ...payloadOverride };

    const protocol = selectedWorkstationRef.current.protocol;
    const address = selectedWorkstationRef.current.address;
    const port = selectedWorkstationRef.current.port;

    const api = axios.create({
      headers: { "content-type": "application/json" },
      baseURL: `${protocol}://${address}:${port}/api/telnet/wms/core`,
      timeout: 60000,
    });

    const response = await api.post<TelnetResponse>(endpoint, payload);
    return response;
  }

  function accordionHeaderTemplate(item: any) {
    const statusColor = ["#FFF2CC", "#D5E8D4", "#F8CECC"];

    return (
      <div className="accordionHeaderTemplate">
        <span className="serial">
          <LanguageProvider id="picking.serial" />: {item.serialNumber}
        </span>
        <span className="nf">
          <LanguageProvider id="picking.nf" />: {item.nf}
        </span>
        <span className="date">
          {new Date(item.eventDate).toLocaleTimeString()}
        </span>
        <div
          className="status"
          style={{ background: statusColor[item.status] }}
        />
      </div>
    );
  }

  async function reprocessAll() {
    try {
      var response = await ReprocessAll(selectedWorkstation.name);

      if (response.status === 200 && response.data.data === true) {
        showToast({
          severity: "success",
          message: "Reprocessando todos os erros.",
        });
      } else {
        showToast({
          severity: "error",
          message: response.data.message,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setReprocessAllConfirmationOpen(false);
  }

  async function hideCurrentItems() {
    try {
      setHideItemsLoading(true);
      const model = {
        itemIds: items.map((x) => x.id),
        batchName: batchName,
      };

      await HideItems(model);
      setBatchName("");
    } catch (error) {
      console.error(error);
    }
    setHideItemsLoading(false);
    setHideItemsConfirmationVisible(false);
  }

  async function reprocessNf(id) {
    try {
      await UpdatePickingItemStatus({
        id: id,
        status: 0,
        log: "NF sendo reprocessada",
      });
      loadItems();
    } catch (error) {
      console.error(error);
    }
  }

  async function loadTelnetTime() {
    //Timer WMS
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/get-spesific-setting`,
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
            setting: "telnet-process-time-ms",
          },
        }
      );
      if (status === 200) {
        setTelnetTime(parseInt(data.data.value));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadRefreshTime(settingCode) {
    //Timer WMS
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/get-spesific-setting`,
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
            setting: settingCode,
          },
        }
      );
      if (status === 200) {
        return parseInt(data.data.value);
      }
    } catch (error) {
      console.error(error);
    }
    return 1000;
  }

  useEffect(() => {
    selectedWorkstationRef.current = selectedWorkstation;
  }, [selectedWorkstation]);

  useEffect(() => {
    authenticatedOnWmsRef.current = authenticatedOnWms;
  }, [authenticatedOnWms]);

  const intervalIdRef = useRef<NodeJS.Timeout>();

  // Items refresh load
  useEffect(() => {
    async function initializeTimers() {
      const refreshTime = await loadRefreshTime("list-refresh-time-ms");
      intervalIdRef.current = setInterval(loadItems, refreshTime);
    }
    if (authenticatedOnWmsRef.current) {
      initializeTimers();
    } else {
      if (intervalIdRef?.current) clearInterval(intervalIdRef.current);
    }

    return () => clearInterval(intervalIdRef?.current);
  }, [authenticatedOnWmsRef.current]);

  // Telnet processing
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    async function load() {
      const refreshTime = await loadRefreshTime("wms-upload-time-ms");
      intervalId = setInterval(sendToWms, refreshTime);
    }

    load();

    return () => clearInterval(intervalId);
  }, []);

  // Initial values load
  useEffect(() => {
    async function load() {
      await loadTelnetTime();
      setLoadinOptions(true);
      await loadSkus();
      await loadWorkStations();
      await loadItems();
      setLoadinOptions(false);
    }

    load();
  }, []);

  return (
    <div
      className={`picking-cartao-main-container ${fullScreen ? "fullscreen" : ""
        }`}
    >
      <div className="backdrop">
        <PageHeader
          title={
            <LanguageProvider id="dcp.side.menu.picking.cartao.wms.core" />
          }
          titleTemplate={undefined}
          recordsCount={undefined}
          onReturn={undefined}
          actions={headerActions}
        />
        <div className="content-container">
          <div className="input-section">
            <Form className="form-picking-cartao">
              <div className="form-intern-wrapper">
                <FormRow>
                  <InputContainer
                    label={
                      <LanguageProvider id="dcp.side.menu.estacao.wms.core" />
                    }
                  >
                    <Dropdown
                      options={workStations}
                      loading={loadinOptions}
                      value={selectedWorkstation}
                      disabled={authenticatedOnWms}
                      optionLabel="name"
                      onChange={(e) => setSelectedWorkstation(e.value)}
                      filter
                    />
                  </InputContainer>
                </FormRow>
                <FormRow>
                  <InputContainer label={<LanguageProvider id="gen.sku" />}>
                    <Dropdown
                      options={skus}
                      loading={loadinOptions}
                      value={selectedSku}
                      disabled={true}
                      onChange={(e) => setSelectedSku(e.value)}
                      filter
                    />
                  </InputContainer>
                </FormRow>
                <p>Processo Leitura</p>
                <FormRow>
                  <InputContainer
                    label={<LanguageProvider id="datasource.column.nf" />}
                  >
                    <InputText
                      value={nf}
                      onChange={(e) => setNf(e.target.value)}
                      ref={focusRef}
                      disabled={
                        !selectedSku ||
                        !selectedWorkstation ||
                        !authenticatedOnWms
                      }
                      autoFocus
                    />
                  </InputContainer>
                </FormRow>
                <FormRow>
                  <InputContainer
                    label={<LanguageProvider id="devices.drones.serial" />}
                  >
                    <InputText
                      value={serialNumber}
                      onChange={(e) => setSerialNumber(e.target.value)}
                      disabled={
                        !selectedSku ||
                        !selectedWorkstation ||
                        !authenticatedOnWms
                      }
                      onBlur={insert}
                    />
                    {/* Fake input to prevent focus from moving out of the page */}
                    <input
                      style={{
                        width: "0",
                        height: "0",
                        border: "none",
                        background: "transparent",
                      }}
                    />
                  </InputContainer>
                </FormRow>
              </div>
            </Form>
          </div>
          <div className="view-section">
            <div className="search-bar">
              <Form>
                <FormRow>
                  <InputContainer
                    className="bigger-input"
                    label={<LanguageProvider id="gen.research" />}
                  >
                    <InputText
                      value={itemListFilterQuery}
                      onChange={(e) => setItemListFilterQuery(e.target.value)}
                    />
                  </InputContainer>
                  {/* <InputContainer label={<LanguageProvider id="Horas" />}>
                    <Dropdown
                      options={[1, 2, 6, 12, 24]}
                      value={lastHours}
                      onChange={(e) => {
                        setLastHours(e.value);
                        loadItems(e.value);
                      }}
                    />Dropdown>
                  </InputContainer> */}
                </FormRow>
                <FormRow>
                  <span className="list-label">
                    <LanguageProvider id="picking.nfs" />: {items.length}
                  </span>
                  <span className="list-label">
                    <LanguageProvider id="picking.wms.user" />: {wmsUsername}
                  </span>
                  <span className="tags">
                    <Tag
                      style={{
                        background: "#FFF2CC",
                        color: "var(--gray-900)",
                      }}
                      value={
                        "Pendente: " +
                        items.filter((x) => x.status === 0).length
                      }
                    />
                    <Tag
                      style={{
                        background: "#D5E8D4",
                        color: "var(--gray-900)",
                      }}
                      value={
                        "Sucesso: " + items.filter((x) => x.status === 1).length
                      }
                    />
                    <Tag
                      style={{
                        background: "#F8CECC",
                        color: "var(--gray-900)",
                      }}
                      value={
                        "Erro: " + items.filter((x) => x.status === 2).length
                      }
                    />
                  </span>
                </FormRow>
              </Form>
            </div>
            <div className="item-list">
              <div className="items">
                <Accordion>
                  {items.map((item) => {
                    // Apply search filter
                    if (itemListFilterQuery.length > 0) {
                      const searchConcatValue = `${item.serialNumber} ${item.nf} ${item.sku} ${item.log}`;
                      if (
                        !compareNormalized(
                          searchConcatValue,
                          itemListFilterQuery
                        )
                      )
                        return null;
                    }

                    return (
                      <AccordionTab
                        headerTemplate={() => accordionHeaderTemplate(item)}
                      >
                        <Form>
                          <FormRow>
                            <InputContainer
                              label={
                                <LanguageProvider id="devices.drones.serial" />
                              }
                            >
                              <InputText value={item.serialNumber} disabled />
                            </InputContainer>

                            <InputContainer
                              label={
                                <LanguageProvider id="datasource.column.nf" />
                              }
                            >
                              <InputText value={item.nf} disabled />
                            </InputContainer>
                          </FormRow>
                          <FormRow>
                            <InputContainer
                              label={<LanguageProvider id="gen.log" />}
                            >
                              <InputTextarea
                                disabled={true}
                                value={item.log}
                                rows={10}
                              />
                            </InputContainer>
                          </FormRow>
                          {item.status === 2 && (
                            <FormRow>
                              <Button
                                type="button"
                                children={
                                  <LanguageProvider id="picking.cartao.reprocess" />
                                }
                                onClick={() => reprocessNf(item.id)}
                              />
                            </FormRow>
                          )}
                        </Form>
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          {authenticatedOnWms ? (
            <Button
              appearance="secondary"
              onClick={() => {
                setDisplayLogoutConfirmDialog(true);
              }}
            >
              <LanguageProvider id={"picking.wms.logout"} />
            </Button>
          ) : (
            <Button
              appearance="secondary"
              onClick={() => {
                setLoginDialogOpen(true);
              }}
            >
              <LanguageProvider id={"picking.wms.login"} />
            </Button>
          )}
          <Button
            appearance="secondary"
            disabled={!authenticatedOnWms}
            onClick={() => setHideItemsConfirmationVisible(true)}
          >
            <LanguageProvider id="picking.clean.data.batch" />
          </Button>
          <Button
            appearance="secondary"
            disabled={!authenticatedOnWms}
            onClick={() => setReprocessAllConfirmationOpen(true)}
          >
            <LanguageProvider id="picking.reprocess.all" />
          </Button>
          <Button onClick={() => loadItems()}>
            <LanguageProvider id="dashboard.update" />
          </Button>
        </div>
      </div>
      <Dialog
        visible={loginDialogOpen}
        onHide={() => setLoginDialogOpen(false)}
        className="wms-login-dialog"
        header={
          <span className="dcp.platform.wmscore.header">
            <LanguageProvider id="dcp.platform.wmscore.header.wms.login" />
          </span>
        }
      >
        <Form onSubmit={() => { }}>
          <FormRow>
            <InputContainer label={<LanguageProvider id="login.user" />}>
              <InputText
                value={wmsUsername}
                onChange={(e) => setWmsUsername(e.target.value)}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.password" />}>
              <Password
                className="wms-password"
                value={wmsPassword}
                onChange={(e) => setWmsPassword(e.target.value)}
                toggleMask
                feedback={false}
              />
            </InputContainer>
          </FormRow>
          <FormRow className="buttom-container">
            <Button
              type="button"
              className="p-button p-button-danger p-button-text"
              label={LanguageProvider({ id: "gen.cancel.button" })}
              onClick={(e) => setLoginDialogOpen(false)}
            />
            <Button
              type="button"
              loading={loginLoading}
              label={LanguageProvider({ id: "login" })}
              onClick={() => login()}
            />
          </FormRow>
        </Form>
      </Dialog>
      <Dialog
        visible={hideItemsConfirmationVisible}
        onHide={() => setHideItemsConfirmationVisible(false)}
        header={
          <span className="dcp.platform.wmscore.header">
            <LanguageProvider id="dcp.platform.wmscore.header.hide.items" />
          </span>
        }
        className="hide-confirm-dialog"
      >
        <Form>
          <FormRow>
            <p>
              <LanguageProvider id="dcp.platform.wmscore.confirmation.hide.items" />
            </p>
          </FormRow>
          <FormRow>
            <InputContainer
              label={
                <LanguageProvider id="datasource.column.inventory.batch" />
              }
            >
              <InputText
                value={batchName}
                onChange={(e) => setBatchName(e.target.value)}
              ></InputText>
            </InputContainer>
          </FormRow>
          <FormRow className="buttons-row">
            <Button
              type="button"
              className="p-button-danger p-button-text"
              onClick={() => setHideItemsConfirmationVisible(false)}
            >
              <LanguageProvider id="gen.cancel" />
            </Button>
            <Button
              type="button"
              onClick={hideCurrentItems}
              loading={hideItemsLoading}
            >
              <LanguageProvider id="gen.confirm" />
            </Button>
          </FormRow>
        </Form>
      </Dialog>
      <ModalConfirmation
        isOpen={reprocessAllConfirmationOpen}
        modalTitle={<LanguageProvider id="picking.reprocess.all" />}
        bodyMessage={<LanguageProvider id="picking.reprocess.all.text" />}
        onConfirm={reprocessAll}
        onCancel={() => setReprocessAllConfirmationOpen(false)}
      ></ModalConfirmation>
      <ModalConfirmation
        isOpen={displayLogoutConfirmDialog}
        modalTitle={"Desconectar"}
        bodyMessage={"Deseja se desconectar do WMS?"}
        onConfirm={logout}
        onCancel={() => setDisplayLogoutConfirmDialog(false)}
      />
    </div>
  );
}
