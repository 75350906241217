import "./scss/b2b-orders-monitor-list.scss";

import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import Icon from "~/shared/components/icons";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { ListB2bOrderProcess } from "~/services/api/B2b/boticario/monitor-process";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import ModalOrderMonitorDetails from "./components/modal-order-monitor-details";
import { MultiSelect } from "primereact/multiselect";
import { Nullable } from "primereact/ts-helpers";
import OrderMonitorStatus from "~/shared/enums/b2b/OrderMonitorStatus";
import { OverlayPanel } from "primereact/overlaypanel";
import { ThemeContext } from "~/app";
import { Tooltip } from "primereact/tooltip";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { loadSettingsModulesAdmin } from "~/services/api/settings/settings";
import SettingsParameters from "~/shared/enums/b2b/SettingsParameters";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { OrderStatusField } from "./components/order-status-field";

export function B2BOrdersMonitorList() {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useContext(ThemeContext);
  const axiosService = useDcpAxiosService();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  const downloadOptionsOpRef = useRef(null);
  const [globalFilters, setGlobalFilters] = useState("");
  const [dateRange, setDateRange] = useState<Nullable<(Date | null)[]>>([
    new Date(new Date().setDate(new Date().getDate() - 60)),
    new Date(),
  ]);
  const [orderId, setOrderId] = useState(0);
  const [orderProcessData, setOrderProcessData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetailsDialogVisible, setOrderDetailsDialogVisible] =
    useState(false);
  const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState([
    OrderMonitorStatus.inconsistentIntegration,
    OrderMonitorStatus.createdOrder,
    OrderMonitorStatus.reprocessCarrierIntegration,
    OrderMonitorStatus.pendingPacking,
    OrderMonitorStatus.carrierDefinition,
    OrderMonitorStatus.carrierError,
    OrderMonitorStatus.pendingIntegrationCarrier,
    OrderMonitorStatus.errorCarrierIntegration,
    OrderMonitorStatus.pendingReport,
    OrderMonitorStatus.errorReport,
    OrderMonitorStatus.reprocessingReport,
    OrderMonitorStatus.pendingPrint,
    OrderMonitorStatus.pendingShipping,
    OrderMonitorStatus.finished,
  ]);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    first: 0,
    page: 0,
    rows: settings.RowsPerPageOptions.Default,
    sortField: null,
    sortOrder: 1,
    globalFilter: "",
    monitorOrdersSelectedStatus: selectedStatus,
    startDateUpdateRange: null,
    endDateUpdateRange: null,
    filters: {
      status: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.CUSTOM,
          },
        ],
      },
    },
  });

  async function loadSettingsParametersFluxLabel() {
    setIsLoading(true);
    try {
      const settingsModulesAdminResponse = await loadSettingsModulesAdmin(
        applicationClientId,
        [SettingsParameters.FLUX_DELIVERY_MANAGEMENT]
      );
      filterOrderStatusOptions(settingsModulesAdminResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function filterOrderStatusOptions(modulesAdminDeliveryFlux) {
    const statusOrderOptions = [
      {
        id: 1,
        label: "dcp.b2b.monitor.order.status.integration.inconsistency",
        value: OrderMonitorStatus.inconsistentIntegration,
      },
      {
        id: 2,
        label: "dcp.b2b.monitor.order.status.created",
        value: OrderMonitorStatus.createdOrder,
      },
      {
        id: 3,
        label: "dcp.b2b.monitor.order.status.reprocess-carrier-integration",
        value: OrderMonitorStatus.reprocessCarrierIntegration,
      },
      {
        id: 4,
        label: "dcp.b2b.monitor.order.status.reprocessing-report",
        value: OrderMonitorStatus.reprocessingReport,
      },
      {
        id: 5,
        label: "dcp.b2b.monitor.order.status.pending-packing",
        value: OrderMonitorStatus.pendingPacking,
      },
      {
        id: 6,
        label: "dcp.b2b.monitor.order.status.carrier-definition",
        value: OrderMonitorStatus.carrierDefinition,
      },
      {
        id: 7,
        label: "dcp.b2b.monitor.order.status.carrier-error",
        value: OrderMonitorStatus.carrierError,
      },
      {
        id: 8,
        label: "dcp.b2b.monitor.order.status.pending-integration-carrier",
        value: OrderMonitorStatus.pendingIntegrationCarrier,
      },
      {
        id: 9,
        label: "dcp.b2b.monitor.order.status.error-carrier-integration",
        value: OrderMonitorStatus.errorCarrierIntegration,
      },
      {
        id: 10,
        label: "dcp.b2b.monitor.order.status.pending-report",
        value: OrderMonitorStatus.pendingReport,
      },
      {
        id: 11,
        label: "dcp.b2b.monitor.order.status.error-report",
        value: OrderMonitorStatus.errorReport,
      },
      {
        id: 12,
        label: "dcp.b2b.monitor.order.status.pending-print",
        value: OrderMonitorStatus.pendingPrint,
      },
      {
        id: 13,
        label: "dcp.b2b.monitor.order.status.pendent-shipping",
        value: OrderMonitorStatus.pendingShipping,
      },
      {
        id: 14,
        label: "dcp.b2b.monitor.order.status.finished",
        value: OrderMonitorStatus.finished,
      },
    ];
    if (modulesAdminDeliveryFlux) {
      setStatusDropdownOptions(statusOrderOptions);
    } else {
      var statusOrderOptionsInLabelFlux = [
        {
          id: 2,
          label: "dcp.b2b.monitor.order.status.created",
          value: OrderMonitorStatus.createdOrder,
        },
        {
          id: 4,
          label: "dcp.b2b.monitor.order.status.reprocessing-report",
          value: OrderMonitorStatus.reprocessingReport,
        },
        {
          id: 14,
          label: "dcp.b2b.monitor.order.status.finished",
          value: OrderMonitorStatus.finished,
        },
      ];
      setStatusDropdownOptions(statusOrderOptionsInLabelFlux);
    }
  }

  const fetchOrderProcess = async (filter) => {
    setIsLoading(true);
    try {
      const { data, status } = await ListB2bOrderProcess(
        formatFilterRequest(filter)
      );
      if (status) {
        setTotalItems(data?.totalRecords);
        setOrderProcessData(data?.orderProcesses);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados");
    } finally {
      setIsLoading(false);
    }
  };

  const CloseOrderProcessModal = () => {
    setOrderId(0);
    setOrderDetailsDialogVisible(false);
    fetchOrderProcess(filter);
  };

  const onMonitorStatusFilter = (monitorStatus = selectedStatus) => {
    setSelectedStatus(monitorStatus);
  };

  function formatFilterRequest(filter) {
    try {
      let startUpdateDate = dateRange[0];
      let endUpdateDate = dateRange[1];

      let payload = {
        ...filter,
        monitorOrdersSelectedStatus: filter.monitorOrdersSelectedStatus,
        startDateUpdateRange: startUpdateDate,
        endDateUpdateRange: endUpdateDate,
      };
      payload.sortOrder = payload.sortOrder ?? 0;

      let _filters = [];

      for (const columnName in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, columnName)) {
          const _filter = payload.filters[columnName];

          if (_filter.constraints[0].value)
            _filters.push({
              columnName,
              value: _filter.constraints[0].value,
              matchMode: _filter.constraints[0].matchMode,
            });
        }
      }

      payload.filters = _filters;
      return {
        ...payload,
        monitorOrdersSelectedStatus: payload.monitorOrdersSelectedStatus,
        startDateUpdateRange: payload.startDateUpdateRange,
        endDateUpdateRange: payload.endDateUpdateRange,
      };
    } catch (error) {
      console.error(error);
    }
  }

  const onFilter = (e) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...e,
      monitorOrdersSelectedStatus: selectedStatus,
    }));
    let _event = { ...e, first: 0 };

    for (const filter in _event.filters) {
      if (Object.hasOwnProperty.call(_event.filters, filter)) {
        let element = _event.filters[filter];
        if (Object.hasOwnProperty.call(element, "value")) {
          delete _event.filters[filter].value;
          delete _event.filters[filter].matchMode;
          _event.filters[filter].constraints[0].value = null;
        }
      }
    }

    let filtersWithMonitorStatus = null;
    filtersWithMonitorStatus = {
      ...filter,
      monitorOrdersSelectedStatus: selectedStatus,
    };
    setFilter(filtersWithMonitorStatus);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter({
      ...filter,
      globalFilter: value,
    });

    setGlobalFilters(value);
  };

  // Todo: Limit register qty
  const exportExcel = async () => {
    var fieldsQtyFileExport = 200;
    const { data, status } = await axiosService.get(
      `${settings.Urls.Rest.Settings}/list-settings-group?specificAppId=1&groupId=16`,
      "Platform"
    );
    if (status === 200) {
      if (data.data[0].subgroups[0].name === "fields-qty-file-export") {
        fieldsQtyFileExport = data.data[0].subgroups[0].settings[0].value * 1;
      }
    }

    let requestData = {
      ...filter,
      filters: [],
      rows: fieldsQtyFileExport,
    };

    const monitorOrderListDataForExport = await ListB2bOrderProcess(
      formatFilterRequest(requestData)
    );

    if (
      monitorOrderListDataForExport &&
      monitorOrderListDataForExport?.data.orderProcesses
    ) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(
          monitorOrderListDataForExport?.data.orderProcesses
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const fileName = LanguageProviderWithoutContext({
          id: "dcp.b2b.monitor.order.list.title",
        });

        saveFile(excelBuffer, fileName, ".xlsx");
      });
    }
  };

  const exportCsv = async () => {
    var fieldsQtyFileExport = 200;
    const { data, status } = await axiosService.get(
      `${settings.Urls.Rest.Settings}/list-settings-group?specificAppId=1&groupId=16`,
      "Platform"
    );
    if (status === 200) {
      if (data.data[0].subgroups[0].name === "fields-qty-file-export") {
        fieldsQtyFileExport = data.data[0].subgroups[0].settings[0].value * 1;
      }
    }

    let requestData = {
      ...filter,
      filters: [],
      rows: fieldsQtyFileExport,
    };

    const monitorOrderListDataForExport = await ListB2bOrderProcess(
      formatFilterRequest(requestData)
    );
    const fileName = LanguageProviderWithoutContext({
      id: "dcp.b2b.monitor.order.list.title",
    });

    if (
      monitorOrderListDataForExport &&
      monitorOrderListDataForExport?.data.orderProcesses
    ) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(
          monitorOrderListDataForExport?.data.orderProcesses
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const csvBuffer = xlsx.write(workbook, {
          bookType: "csv",
          type: "array",
        });

        saveFile(csvBuffer, fileName, ".csv");
      });
    }
  };

  const saveFile = (buffer, fileName, ext) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let FILE_EXTENTION = ext;
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().toLocaleDateString() + FILE_EXTENTION
        );
      }
    });
  };

  const exportPdf = async () => {
    var fieldsQtyFileExport = 200;
    const { data, status } = await axiosService.get(
      `${settings.Urls.Rest.Settings}/list-settings-group?specificAppId=1&groupId=16`,
      "Platform"
    );
    if (status === 200) {
      if (data.data[0].subgroups[0].name === "fields-qty-file-export") {
        fieldsQtyFileExport = data.data[0].subgroups[0].settings[0].value * 1;
      }
    }

    const selectedColumns = [
      { title: "ID", dataKey: "id" },
      { title: "Pedido", dataKey: "orderId" },
      { title: "Status", dataKey: "status" },
      { title: "Transportadora", dataKey: "carrier" },
      { title: "Cidade", dataKey: "city" },
      { title: "Custo Total", dataKey: "totalCost" },
      { title: "Última Atualização", dataKey: "lastUpdated" },
    ];

    let requestData = {
      ...filter,
      filters: [],
      rows: fieldsQtyFileExport,
    };

    const monitorOrderListDataForExport = await ListB2bOrderProcess(
      formatFilterRequest(requestData)
    );

    const filteredData = monitorOrderListDataForExport?.data.orderProcesses.map(
      (order) =>
        selectedColumns.reduce((obj, col) => {
          obj[col.dataKey] = order[col.dataKey];
          return obj;
        }, {})
    );

    const fileName = LanguageProviderWithoutContext({
      id: "dcp.b2b.monitor.order.list.title",
    });

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc: any = new jsPDF.default("p", "cm");
        doc.autoTable({
          columns: selectedColumns,
          body: filteredData,
        });
        doc.save(fileName + "_" + new Date().toLocaleDateString() + ".pdf");
      });
    });
  };

  useEffect(() => {
    let startDate = new Date();
    let endDate = new Date();

    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange ? dateRange[0] : null;
      endDate = dateRange ? dateRange[1] : null;
    }

    filter.monitorOrdersSelectedStatus = selectedStatus;
    if (dateRange.length <= 0) {
      startDate.setMonth(startDate.getMonth() - 1);
      filter.startDateUpdateRange = startDate;
      filter.endDateUpdateRange = endDate;
    }
    fetchOrderProcess(filter);
  }, [filter, dateRange, selectedStatus]);

  useEffect(() => {
    loadSettingsParametersFluxLabel();
  }, []);

  return (
    <div className="orders-monitor-dashboard">
      <div className="orders-monitor-container">
        <div className="orders-monitor-content">
          <div className="content-header">
            <div className="total-items-wrapper">
              <span className="header-message">
                <div className="page-identifier-wrapper">
                  <span className="page-name">
                    <LanguageProvider id="dcp.b2b.monitor.order.list.title" />
                  </span>
                </div>
                <div className="total-table-itens">
                  <span className="total-register-message">
                    {`${totalItems} ${LanguageProvider({
                      id: "gen.registers",
                    })}`}
                  </span>
                </div>
              </span>
            </div>
            <div className="options-wrapper">
              <div className="filter-buttons-wrapper">
                <span className="search-input">
                  <Icon icon={"search-md"} size={20} color={"#667085"} />
                  <InputText
                    className="p-inputtext-md"
                    placeholder={LanguageProviderWithoutContext({
                      id: "search.field.placeholder",
                      currentLanguage,
                    })}
                    value={globalFilters}
                    onChange={(e) => setGlobalFilters(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        onGlobalFilterChange(e);
                      }
                    }}
                  />
                </span>
                <div className="field-calendar">
                  <Icon icon="calendar" />
                  <Calendar
                    className="field-calendar-input"
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    value={dateRange}
                    onChange={(e) => setDateRange(e.value)}
                    readOnlyInput
                  />
                </div>
                <div className="field-dropdown">
                  <MultiSelect
                    className="field-dropdown-input"
                    options={statusDropdownOptions}
                    loading={isLoading}
                    value={selectedStatus}
                    optionLabel="label"
                    optionValue="value"
                    dataKey="id"
                    display="chip"
                    maxSelectedLabels={100}
                    onChange={(e) => {
                      const monitorStatusSelected: OrderMonitorStatus[] =
                        [] as OrderMonitorStatus[];
                      monitorStatusSelected.push(...e.value);
                      onMonitorStatusFilter(monitorStatusSelected);
                    }}
                    placeholder="Status"
                    itemTemplate={(option) => (
                      <LanguageProvider id={option.label} />
                    )}
                    selectedItemTemplate={(option) => {
                      const selectedOption = statusDropdownOptions.find(
                        (item) => item.value === option
                      );
                      return selectedOption ? (
                        <span className="item-wrapper">
                          <LanguageProvider id={selectedOption.label} />
                        </span>
                      ) : (
                        <span></span>
                      );
                    }}
                  />
                </div>
              </div>
              <Button
                className="p-button p-button-secondary btn-download-database"
                onClick={(e) => downloadOptionsOpRef?.current.toggle(e)}
                icon={
                  <Icon size={20} icon="download-01" color={"var(--primary)"} />
                }
              />
              <Tooltip target=".btn-download-database" position="top">
                <span>
                  <LanguageProvider id="gen.download" />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="content-body">
            {/*@ts-ignore*/}
            <DataTable
              lazy
              loading={isLoading}
              loadingIcon={<LoadingIndicator />}
              value={orderProcessData}
              paginator
              rows={filter.rows}
              first={filter.first}
              onPage={setFilter}
              onSort={setFilter}
              onFilter={(e) => onFilter(e)}
              totalRecords={totalItems}
              sortField={filter.sortField}
              sortOrder={filter.sortOrder}
              filters={filter.filters}
              rowsPerPageOptions={settings.RowsPerPageOptions.Default}
            >
              <Column
                sortable
                field="orderId"
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.order"}
                  />
                }
              />
              <Column
                sortable
                field="status"
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.status"}
                  />
                }
                body={(rowData) => (
                  <div className="order-status">
                    <OrderStatusField orderStatus={rowData.status} />
                  </div>
                )}
              />
              <Column
                sortable
                field="volume"
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.volume"}
                  />
                }
              />
              <Column
                sortable
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.cost"}
                  />
                }
                field="totalCost"
                body={(rowData) => (
                  <span>
                    {rowData.totalCost ? "$ " + rowData.totalCost : ""}
                  </span>
                )}
              />
              <Column
                sortable
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.city"}
                  />
                }
                field="city"
              />
              <Column
                sortable
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.update.date"}
                  />
                }
                field="lastUpdated"
                body={(rowData) => (
                  <span>
                    {rowData.lastUpdated
                      ? new Date(rowData.lastUpdated).toLocaleString()
                      : ""}
                  </span>
                )}
              />
              <Column
                sortable
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.days.last.update"}
                  />
                }
                field="daysFromLastUpdate"
              />
              <Column
                body={(rowData) => (
                  <div className="actions">
                    <Icon
                      icon={"eye"}
                      size={20}
                      color={currentTheme.tableActionIcon}
                      onClick={() => {
                        setOrderId(rowData?.id);
                        setOrderDetailsDialogVisible(true);
                      }}
                      className="action-icon"
                    />
                  </div>
                )}
              />
            </DataTable>
          </div>
        </div>
      </div>
      <ModalOrderMonitorDetails
        orderId={orderId}
        isOpen={orderDetailsDialogVisible}
        onClose={CloseOrderProcessModal}
      />
      <OverlayPanel
        ref={downloadOptionsOpRef}
        className="download-list-columns-database-view"
      >
        <div className="popup-wrapper">
          <div className="popup-header">
            <span className="popup-title">
              <LanguageProvider id="gen.download" />
            </span>
          </div>
          <div className="popup-column-download-wrapper">
            <div
              className="popup-excel-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportExcel();
              }}
            >
              <Icon color={"#98A2B3"} size={undefined} icon={"excel-icon"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.excel"} />
              </label>
            </div>
            <div
              className="popup-pdf-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportPdf();
              }}
            >
              <Icon color={"#98A2B3"} size={undefined} icon={"pdf-icon"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.pdf"} />
              </label>
            </div>
            <div
              className="popup-csv-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportCsv();
              }}
            >
              <Icon color={"#98A2B3"} size={undefined} icon={"csv-icon"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.csv"} />
              </label>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}

export default B2BOrdersMonitorList;
