import "../../scss/dashboard-main-initial-setup.scss";
import settings from "~/services/settings.json";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "~/app";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { getLoggedUserAllowances } from "~/services/api";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Button } from "~/shared/components/dcp-button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { propertyFrommStorage } from "~/services/storage/storage-access";

const InitialSetup = () => {
  const dcpAxiosService = useDcpAxiosService();
  const { user, handleVerifyDashboardRolePermission } = useContext(UserContext);
  const { dashboards, loadMenuItems } = useMenuItemsContext();

  const [isSuperUser, setIsSuperUser] = useState(false);
  const [dashboardMainDialogVisible, setDashboardMainDialogVisible] =
    useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(0);

  const handleSetHomeDashboard = async () => {
    const applicationId = propertyFrommStorage(
      "authentication",
      "applicationId"
    );
    try {
      const response = await dcpAxiosService.post(
        settings.Urls.Rest.Dashboard +
          "/set-main-dashboard?dashboardId=" +
          selectedDashboard +
          "&applicationId=" +
          applicationId,
        {},
        "Platform"
      );

      if (response.data) {
        setDashboardMainDialogVisible(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserData = async () => {
    try {
      const getUserResponse = await getLoggedUserAllowances();
      if (getUserResponse.status) {
        setIsSuperUser(getUserResponse.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadMenuItems();
    getUserData();
    const productRoles = user.userClienteRoles.filter(
      (role) => role.applicationName === "product.inventory"
    );
    handleVerifyDashboardRolePermission(productRoles);
  }, []);

  return (
    <div className="main-dashboard-initial-setup-container">
      {isSuperUser && (
        <>
          <div className="configure-dashboard">
            <h3>Configurar Dashboard inicial</h3>
            <Button
              label="Configurar Dashboard"
              onClick={() => setDashboardMainDialogVisible(true)}
            />
          </div>
        </>
      )}

      <Dialog
        visible={dashboardMainDialogVisible}
        onHide={() => setDashboardMainDialogVisible(false)}
        header="Configurar Dashboard inicial"
        content={
          <div className="content-dashboard-main-container">
            <h3>Configurar Dashboard</h3>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={selectedDashboard}
              onChange={(e) => setSelectedDashboard(e.value)}
              options={dashboards}
              placeholder="Selecione o dashboard"
            />
            <div className="buttons-container">
              <Button
                label="Cancelar"
                appearance="secondary"
                onClick={() => setDashboardMainDialogVisible(false)}
              />
              <Button label="Salvar" onClick={handleSetHomeDashboard} />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default InitialSetup;
