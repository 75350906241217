import {
  UserListModel,
  UserModel,
  UserPreferences,
} from "~/shared/interfaces/user";

import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { para } from "@maxgraph/core/dist/types/util/domUtils";
import settings from "~/services/settings.json";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

/**
 * Retrieves the list of users for the current client.
 * @returns A Promise that resolves to the list of users.
 */
export async function getUsers(): Promise<any> {
  try {
    const { status, data } = await axiosService().get("user/get-users", "Auth");
    if (status === 200) {
      return data;
    } else return null;
  } catch (error) {
    console.error(error);
  }
  return [];
}

/**
 * Retrieves the user allowances data for the current logged user.
 * @returns A Promise that resolves to the logged client allowances data.
 */
export async function getLoggedUserAllowances(): Promise<any> {
  try {
    const data = await axiosService().get(
      "user/get-signed-in-user-allowances",
      "Auth"
    );

    if (data.status) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function checkDomain(domain: string): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Admin.Authorization}/check-domain`,
      "Login",
      {
        params: {
          domain: domain,
        },
      }
    );

    if (status === 200) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function checkAIService(): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Admin.Client}/check-ia-service`,
      "Auth"
    );

    if (status === 200) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function CheckAIHeatMapService(): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      `${settings.Urls.Admin.Client}/check-ia-heat-map-service`,
      "Auth"
    );

    if (status === 200) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function login(userModel: any): Promise<any> {
  try {
    const { status, data } = await axiosService().post(
      `${settings.Urls.Admin.Authorization}/login`,
      userModel,
      "Login"
    );
    if (status === 200) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserRoles(userName: string): Promise<any> {
  try {
    const { status, data } = await axiosService().get(
      `${settings.Urls.Rest.Users}/get-user-roles`,
      "Auth",
      {
        params: {
          user: userName,
        },
      }
    );
    if (status === 200) {
      return data;
    } else return null;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves a list of user products.
 * @returns A Promise that resolves to the list of user products.
 */
export async function listProductsFromUser(): Promise<any> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Admin.ApplicationClient + "/list-products-from-user",
      "Auth"
    );
    if (data) return data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function createUser(userModel: UserListModel): Promise<boolean> {
  try {
    const { data } = await axiosService().post(
      `${settings.Urls.Rest.Users}/create-user-client`,
      userModel,
      "Auth"
    );

    return data.status;
  } catch (error) {
    console.error(error);
  }
}

export async function updateUserPreferences(
  userPreferencesModel: UserPreferences
): Promise<boolean> {
  try {
    const { data } = await axiosService().post(
      `${settings.Urls.Rest.Users}/update-user-preferences`,
      userPreferencesModel,
      "Auth"
    );

    return data.status;
  } catch (error) {
    console.error(error);
  }
}

export async function loadUserPreferences(): Promise<UserPreferences> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Users}/get-user-preferences`,
      "Auth"
    );
    return data;
  } catch (error) {
    console.error(error);
  }
}
