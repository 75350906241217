import "../../scss/template-pattern-dialog.scss";
import { Dialog, DialogProps } from "primereact/dialog";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "~/shared/components/dcp-button";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { Mention } from "primereact/mention";
import { MultiSelect } from "primereact/multiselect";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { FormikProps } from "formik";
import showToast from "~/shared/components/toast-custom";

interface TemplatePatterDialogProps extends DialogProps {
  onSave: (pattern: string, applications: any[]) => void;
  onChange: (value: string) => void;
  fields: any[];
  suggestions: Suggestion[];
  templatePattern: string;
  applications: any[];
  formik: FormikProps<any>;
}

interface Suggestion {
  viewName: string;
  keyName: string;
  fieldId: number;
}

export function TemplatePatterDialog(props: TemplatePatterDialogProps) {
  const dcpAxiosService = useDcpAxiosService();
  const { currentLanguage } = useLanguageContext();
  const [patternValue, setPatternValue] = useState(props.templatePattern);
  const [applicationsValue, setApplicationsValue] = useState(props.applications || []);
  const [suggestions, setSuggestions] = useState(props.suggestions);
  const productListObject = propertyFrommStorage(
    "authentication",
    "applicationIds"
  );

  const productListArray = Object.entries(productListObject)
    .filter(([key]) => key !== "platform")
    .map(([key, value]) => ({
      key: LanguageProviderWithoutContext({ id: key, currentLanguage }),
      domain: key,
      value,
    }));

  const loadDatalakeProductsList = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Datalake + "/get-selected-datalake-products-list",
        "Platform",
        {
          params: {
            idDataSource: props.formik.values.id,
          },
        }
      );

      if (status === 200) {
        const selectedProducts = productListArray.filter(product =>
          data.data.includes(product.value)
        );

        setApplicationsValue(selectedProducts.map(app => app.value));
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSearch = (e) => {
    if (!Array.isArray(props.suggestions)) return;

    const query: string = e.query?.trim();
    let suggestions: Suggestion[];

    if (query.length < 1) {
      suggestions = getAllSugestions();
    } else {
      suggestions = getFilteredSuggestions(query);
    }

    setSuggestions(suggestions);
  };

  function getAllSugestions(): Suggestion[] {
    return props.suggestions.map((suggestion: Suggestion) => {
      return {
        ...suggestion,
        patternField: `{${suggestion.keyName}}`,
      };
    });
  }

  function getFilteredSuggestions(query: string): Suggestion[] {
    return props.suggestions
      .filter((sug) => {
        return sug.viewName.toLowerCase().startsWith(query.toLowerCase());
      })
      .map((suggestion: Suggestion) => {
        return {
          ...suggestion,
          patternField: `{${suggestion.keyName}}`,
        };
      });
  }

  const suggestionItemTemplate = (suggestion: Suggestion) => {
    return (
      <div className="mention-suggestion-container">
        <span className="suggestion-names-wrapper">{suggestion.viewName}</span>
      </div>
    );
  };

  useEffect(() => {
    loadDatalakeProductsList();
  }, []);

  return (
    <Dialog
      header={
        <p className="template-pattern-title">
          <LanguageProvider id={"database.setting.option.dialog.title"} />
        </p>
      }
      onHide={props.onHide}
      visible={props.visible}
    >
      <div className="template-pattern-dialog">
        <div className="template-form-container">
          <Form>
            <FormRow>
              <div className="pattern-field-container">
                <span className="pattern-field-title">
                  <LanguageProvider id="connection.display.pattern" />
                </span>
                <InputContainer>
                  <Mention
                    aria-label="patternField"
                    autoResize
                    value={patternValue}
                    onChange={(e) => {
                      setPatternValue(e.value);
                    }}
                    field={"patternField"}
                    suggestions={suggestions}
                    itemTemplate={suggestionItemTemplate}
                    trigger={["@"]}
                    onSearch={onSearch}
                    className="pattern-field"
                    rows={1}
                    onSelect={(e) => setPatternValue(e.value)}
                  />
                </InputContainer>
              </div>
            </FormRow>
            <FormRow>
              <div className="visualize-in-field-container">
                <span className="visualize-in-field-title">
                  <LanguageProvider id={"gen.visualize.in"} />
                </span>
                <InputContainer>
                  <MultiSelect
                    id="applications"
                    name="applications"
                    className="applications-multi-select"
                    options={productListArray}
                    value={applicationsValue}
                    onChange={(e) => {
                      setApplicationsValue(e.value);
                    }}
                    optionLabel="key"
                    optionValue="value"
                    placeholder={LanguageProviderWithoutContext({
                      id: "gen.datalakes.product.selection",                 
                      currentLanguage,
                    })}
                    maxSelectedLabels={5}
                    display="chip"
                    type="checkbox"
                  />
                  {props.formik.touched.applications && props.formik.errors.applications ? (
                    Array.isArray(props.formik.errors.applications) ? (
                      props.formik.errors.applications.map((error, index) => (
                        <small key={index} className="p-error">{error}</small>
                      ))
                    ) : (
                      <small className="p-error">{String(props.formik.errors.applications)}</small>
                    )
                  ) : (
                    <small className="p-error">&nbsp;</small>
                  )}
                </InputContainer>
              </div>
            </FormRow>
          </Form>
          <div className="btn-container">
            <Button
              className="save-pattern-btn"
              type="button"
              onClick={() => props.onSave(patternValue, applicationsValue)}
            >
              <LanguageProvider id="gen.save.button" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}